import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { CityPayload } from '../types'
import { CITIES } from '../constants'

const uploadCity = async ({ file }: CityPayload) => {
  const formData = new FormData()

  formData.append('file', file)

  await fetchAPI({
    method: 'post',
    path: '/cities',
    data: formData,
  })
}

export const useUploadCity = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(uploadCity, {
    onSuccess: async () => {
      await queryClient.refetchQueries([CITIES])

      toast({
        description: t('views.admin.cities.services.upload_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
