import { t } from '@/lib/helpers'

export const USERS = 'users'
export const OCCUPATIONS = [
  {
    label: t('views.admin.users.occupations.not_set'),
    value: 'not_set',
  },
  {
    label: t('views.admin.users.occupations.employee'),
    value: 'employee',
  },
  {
    label: t('views.admin.users.occupations.self_employed'),
    value: 'self_employed',
  },
  {
    label: t('views.admin.users.occupations.civil_servant'),
    value: 'civil_servant',
  },
  {
    label: t('views.admin.users.occupations.housewife'),
    value: 'housewife',
  },
  {
    label: t('views.admin.users.occupations.part_time_job'),
    value: 'part_time_job',
  },
  {
    label: t('views.admin.users.occupations.student'),
    value: 'student',
  },
  {
    label: t('views.admin.users.occupations.other'),
    value: 'other',
  },
]
