import React from 'react'
import { get } from 'lodash'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { BonusPointForm } from './BonusPointForm'

import { useUpdateBonusPoint } from '../mutations'
import { transformBonusPointIntoForm } from '../utils'
import { BonusPoint } from '../types'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
  defaultValues?: BonusPoint
}

export const EditBonusPointFormModal = (props: Props) => {
  const { disclosure, defaultValues } = props

  const { isOpen, onClose } = disclosure

  const { mutate: updateBonusPoint, isLoading } = useUpdateBonusPoint()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="946px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" sx={{ mb: '20px' }} isTruncated>
          {t('views.admin.challenges.edit.title', {
            title: get(defaultValues, 'title'),
          })}
        </Text>

        <BonusPointForm
          isLoading={isLoading}
          defaultValues={transformBonusPointIntoForm(defaultValues)}
          onSubmitForm={(data) => {
            updateBonusPoint(
              { form: data, id: get(defaultValues, 'id') },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
