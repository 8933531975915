import React from 'react'
import { get } from 'lodash'

import { Modal } from '@/components/shared/Modal'
import { MessageForm } from './MessageForm'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Message } from '../types'
import { CityCollection } from '@/features/types'

import { t } from '@/lib/helpers'
import { transformMessageIntoForm } from '../utils'
import { useUpdateMessage } from '../mutations'

type Props = {
  disclosure: UseDisclosureProps
  cities: CityCollection
  initialData: Message
}

export const EditMessageModal = (props: Props) => {
  const { disclosure, cities, initialData } = props
  const { isOpen, onClose } = disclosure

  const { mutate: createMessage, isLoading } = useUpdateMessage()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="946px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.messages.form.edit')}
        </Text>
        <MessageForm
          isLoading={isLoading}
          defaultValues={transformMessageIntoForm(initialData)}
          onSubmitForm={(data) => {
            createMessage(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
          cities={cities}
        />
      </Box>
    </Modal>
  )
}
