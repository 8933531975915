import React from 'react'
import { Select } from '@chakra-ui/react'

type Props = {
  isMin?: boolean
  innerRef: any
  name: string
  tabIndex?: number
}

export const TimeSelect = (props: Props) => {
  const { isMin = false, innerRef, name, tabIndex = 0 } = props

  return (
    <Select ref={innerRef} name={name} tabIndex={tabIndex} size="lg">
      {(isMin ? minOptions : hrOptions).map((opt) => (
        <option value={opt} key={opt}>
          {opt}
        </option>
      ))}
    </Select>
  )
}

const hrOptions = Array.from(
  new Array(24),
  (_, idx) => `${idx < 10 ? `0${idx}` : idx}`,
)
const minOptions = Array.from(
  new Array(60),
  (_, idx) => `${idx < 10 ? `0${idx}` : idx}`,
)
