import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import {
  Box,
  Button,
  Center,
  Grid,
  Icon,
  IconButton,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react'
import { get } from 'lodash'
import { useDropzone } from 'react-dropzone'
import { FiTrash2 } from 'react-icons/fi'

import { Modal } from '@/components/shared/Modal'
import { useImportLocations } from '../mutations'

import { getFileSize, t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
}

export const ImportLocationsModal = (props: Props) => {
  const { disclosure } = props

  const [file, setFile] = useState<File | null>(null)
  const { mutate: importLocations, isLoading } = useImportLocations()

  const { isOpen, onClose } = disclosure

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Grid sx={{ gap: '24px', p: '32px' }}>
        <Text textStyle="modalHeader">
          {t('views.admin.shared.misc.upload')}
        </Text>

        <Box>
          {file ? (
            <FilePreview file={file} setFile={setFile} />
          ) : (
            <UploadComponent setFile={setFile} />
          )}
        </Box>

        <Button
          size="lg"
          sx={{ w: '141px', h: '54px', justifySelf: 'center' }}
          onClick={(e) => {
            e.preventDefault()
            importLocations(file, {
              onSuccess: () => {
                onClose()
                setFile(null)
              },
            })
          }}
          isDisabled={!file}
          isLoading={isLoading}
        >
          {t('views.admin.shared.misc.upload')}
        </Button>
      </Grid>
    </Modal>
  )
}

type UploadComponentProps = {
  setFile: Dispatch<SetStateAction<File>>
}

const UploadComponent = (props: UploadComponentProps) => {
  const { setFile } = props

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['.csv'],
    multiple: false,
  })

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      <Center
        sx={{
          flexDir: 'column',
          h: '164px',
          cursor: 'pointer',
          backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%238BC34AFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        }}
      >
        <Text sx={{ mb: '12px', color: 'gray.200', fontStyle: 'italic' }}>
          {'No File Chosen'}
        </Text>
        <Button variant="outline" colorScheme="brand" sx={{ fontSize: '14px' }}>
          {'Choose File (support .csv)'}
        </Button>
      </Center>
    </Box>
  )
}

const FilePreview = ({
  file,
  setFile,
}: {
  file: File
  setFile: Dispatch<SetStateAction<File>>
}) => {
  return (
    <Grid
      sx={{
        border: '1px solid',
        borderColor: 'green.500',
        alignItems: 'center',
        px: '24px',
        gridTemplateColumns: '40px 1fr 40px',
        gap: '20px',
        h: '96px',
      }}
    >
      <svg
        width="40"
        height="40"
        fill="none"
        stroke="#8bc34a"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        ></path>
      </svg>
      <Box>
        <Text>{get(file, 'name')}</Text>
        <Text sx={{ color: 'gray.200' }}>{getFileSize(get(file, 'size'))}</Text>
      </Box>

      <IconButton
        icon={<Icon as={FiTrash2} sx={{ fontSize: '20px' }} />}
        aria-label="remove selected file"
        variant="ghost"
        colorScheme="red"
        onClick={() => setFile(undefined)}
        title={'remove selected file'}
      />
    </Grid>
  )
}
