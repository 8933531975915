import React from 'react'
import { Modal } from '@/components/shared/Modal'

import { t } from '@/lib/helpers'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { PostRankingForm } from './PostRankingForm'
import { useCreatePostRanking } from '../mutations/createPostRanking'

type Props = {
  disclosure: UseDisclosureProps
}

export const CreateRankingModal = (props: Props) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure
  const { mutate: createPostRanking } = useCreatePostRanking()
  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="480px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.forms.form.create')}
        </Text>
        <PostRankingForm
          defaultValues={{
            point_no1: 100,
            point_no2: 70,
            point_no3: 50,
            point_no4_10: 30,
          }}
          onSubmitForm={(data) => {
            createPostRanking(data, { onSuccess: onClose })
          }}
        />
      </Box>
    </Modal>
  )
}
