import React, { ReactNode } from 'react'
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react'

export type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  maxW?: string
  withCloseButton?: boolean
} & ChakraModalProps

export const Modal = (props: ModalProps) => {
  const {
    isOpen,
    onClose,
    children,
    maxW = '574px',
    withCloseButton = false,
    ...rest
  } = props

  return (
    <ChakraModal
      onClose={onClose}
      isOpen={isOpen}
      isCentered={false}
      motionPreset="slideInBottom"
      closeOnOverlayClick={false}
      {...rest}
    >
      <ModalOverlay sx={{ bg: '#8BC34AAD' }}>
        <ModalContent maxW={maxW}>
          {withCloseButton && <ModalCloseButton />}
          {children}
        </ModalContent>
      </ModalOverlay>
    </ChakraModal>
  )
}
