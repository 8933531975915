import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { EditCollectionSiteModal } from '@/features/cities/components'
import { useDeleteCityCollectionSite } from '@/features/cities/mutations'
import { CollectionSite } from '@/features/cities/types'
import { t } from '@/lib/helpers'
import { Button, HStack, useDisclosure } from '@chakra-ui/react'
import { get } from 'lodash'
import React, { Fragment, useMemo, useState } from 'react'

type Props = {
  data: CollectionSite[]
  cityId: number
}

export const CityCollectionSiteTable = ({ data, cityId }: Props) => {
  const [actionData, setActionData] = useState<CollectionSite>()

  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const { mutate: deleteSite } = useDeleteCityCollectionSite()

  const columns = useMemo(() => {
    return [
      {
        Header: t('views.admin.cities.collection_sites.index.order_number'),
        accessor: 'order_number',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.cities.collection_sites.index.site_name'),
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id } = row

          return (
            <HStack spacing={2}>
              <Button
                as="a"
                size="sm"
                colorScheme="blue"
                variant="outline"
                href={`collection_sites/${id}/calendars`}
              >
                {t('views.admin.cities.collection_sites.index.badge_calendar')}
              </Button>

              <TableEditButton
                ariaLabel={'edit'}
                onClick={() => {
                  setActionData(row)
                  onOpen()
                }}
              />

              <TableTrashButton
                ariaLabel={'delete'}
                onClick={() => {
                  if (
                    confirm(
                      t(
                        'views.admin.cities.collection_sites.index.confirm_delete',
                      ),
                    )
                  ) {
                    deleteSite({ cityId, siteId: id })
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      <EditCollectionSiteModal
        disclosure={disclosure}
        initialData={actionData}
      />
    </Fragment>
  )
}
