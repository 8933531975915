import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { TAGS, API_PATH } from '../constants'
import { TagFormInput } from '../types'
import { transformTagInput } from '../utils'

const updateTag = async ({ form, id }: { form: TagFormInput; id: number }) => {
  const data = transformTagInput(form)

  await fetchAPI({
    method: 'put',
    path: `${API_PATH}/${id}`,
    data,
  })
}

export const useUpdateTag = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateTag, {
    onSuccess: async () => {
      await queryClient.refetchQueries([TAGS])

      toast({
        description: t('views.admin.campaigns.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
