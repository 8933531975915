import { Pagination } from '@/components/shared/Pagination'
import { useMeta } from '@/features/filters'
import { Meta } from '@/features/filters/types'
import { CreateHolidayModal } from '@/features/holidays/components'
import { useHolidays } from '@/features/holidays/queries'
import { Holiday } from '@/features/holidays/types'
import { DashboardHeader, withApp } from '@/features/Layout'
import { CityCollection, CityCollectionSiteCollection } from '@/features/types'
import { t } from '@/lib/helpers'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { HolidayTable } from './HolidayTable'

type Props = {
  holidays: Holiday[]
  cities: CityCollection
  collection_sites: CityCollectionSiteCollection
  meta: Meta
}

function HolidaysPage(props: Props) {
  const {
    holidays: initialHolidays,
    cities,
    collection_sites,
    meta: initialMeta,
  } = props

  const disclosure = useDisclosure()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: holidays } = useHolidays({
    initialData: { data: initialHolidays, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.holidays.index.title')}
          addons={
            <Button
              onClick={disclosure.onOpen}
              sx={{ h: '48px', minW: '100px' }}
            >
              {t('views.admin.shared.form.create')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <HolidayTable
            data={holidays.data}
            cities={cities}
            sites={collection_sites}
          />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <CreateHolidayModal
        disclosure={disclosure}
        cities={cities}
        sites={collection_sites}
      />
    </Fragment>
  )
}

export default withApp(HolidaysPage)
