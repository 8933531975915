import React from 'react'
import { get } from 'lodash'

import { Modal } from '@/components/shared/Modal'
import { UserForm } from './UserForm'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { User } from '../types'
import { CityCollection } from '@/features/types'

import { useUpdateUser } from '../mutations'
import { transformUserIntoForm } from '../utils'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
  initialData: User
  cities: CityCollection
}

export const EditUserModal = (props: Props) => {
  const { disclosure, initialData, cities } = props
  const { isOpen, onClose } = disclosure

  const { mutate: updateUser, isLoading } = useUpdateUser()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.users.edit.title')}&nbsp;{get(initialData, 'email')}
        </Text>
        <UserForm
          isLoading={isLoading}
          defaultValues={transformUserIntoForm(initialData)}
          cities={cities}
          onSubmitForm={(data) => {
            updateUser(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
