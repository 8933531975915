import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { Location } from '../types'

import { LOCATIONS, API_PATH } from '../constants'

type Response = {
  data: Location[]
}

const getLocationsByQRCodeCategory = async (
  qrcodeCategoryId: number,
): Promise<Response> => {
  const response = await fetchAPI({
    path: API_PATH,
    params: { qrcode_category_id: qrcodeCategoryId },
  })

  return {
    data: get(response, 'data.locations'),
  }
}

export const useLocationByQRCodeCategory = (qrcodeCategoryId = 0) => {
  return useQuery<Response, AxiosError>({
    queryKey: [LOCATIONS, qrcodeCategoryId],
    queryFn: () => getLocationsByQRCodeCategory(qrcodeCategoryId),
    keepPreviousData: true,
    enabled: Boolean(qrcodeCategoryId),
  })
}
