import React, { Fragment } from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Meta } from '@/features/filters/types'
import { AppConfig } from '@/features/appconfigs/types'
import { useAppConfigs } from '@/features/appconfigs/queries'

import { Pagination } from '@/components/shared/Pagination'
import { AppConfigTable } from './AppConfigTable'

import { useMeta } from '@/features/filters'
import { t } from '@/lib/helpers'

type Props = {
  appConfigs: AppConfig[]
  meta: Meta
}

function AppConfigsPage(props: Props) {
  const { appConfigs: initialAppConfigs, meta: initialMeta } = props

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: appConfigs } = useAppConfigs({
    initialData: { data: initialAppConfigs, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader title={t('views.admin.app_configs.index.title')} />

        <Box sx={{ mb: '24px' }}>
          <AppConfigTable data={appConfigs.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>
    </Fragment>
  )
}

export default withApp(AppConfigsPage)
