import React from 'react'

import { useMarkerHues } from '@/features/qrcode_categories/queries'

type Props = {
  value?: string
}

export const MarkerColorLabel = ({ value }: Props) => {
  const { data: markerHueOptions, isLoading } = useMarkerHues()

  if (typeof value == null || isLoading) {
    return null
  }

  return (
    <span>{markerHueOptions.find((i) => i.value == value)?.label ?? ''}</span>
  )
}
