import React, { Fragment, useState } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'
import { useQueryClient } from 'react-query'

import { DashboardHeader, withApp } from '@/features/Layout'

import { User } from '@/features/users/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { UserTable } from './UserTable'
import { CityCollection } from '@/features/types'
import { EditUserModal } from '@/features/users/components'

import { useMeta } from '@/features/filters'
import { useUsers } from '@/features/users/queries'

import { USERS } from '@/features/users/constants'
import { t } from '@/lib/helpers'

type Props = {
  users: User[]
  cities: CityCollection
  meta: Meta
}

function UsersPage(props: Props) {
  const { users: initialUsers, meta: initialMeta, cities } = props
  const [actionData, setActionData] = useState<User>()

  const disclosure = useDisclosure()
  const queryClient = useQueryClient()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: users, isLoading } = useUsers({
    initialData: { data: initialUsers, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.users.index.title')}
          addons={
            <Button
              onClick={() => {
                queryClient.refetchQueries([USERS])
              }}
              sx={{ h: '48px', minW: '100px' }}
              isLoading={isLoading}
            >
              {t('views.admin.users.index.refresh')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <UserTable
            data={users.data}
            setActionData={setActionData}
            disclosure={disclosure}
          />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <EditUserModal
        initialData={actionData}
        cities={cities}
        disclosure={disclosure}
      />
    </Fragment>
  )
}

export default withApp(UsersPage)
