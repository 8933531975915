import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { BonusPoint } from '../types'
import { Meta } from '@/features/filters/types'

import { BONUS_POINTS } from '../constants'

type Response = {
  data: BonusPoint[]
  meta: Meta
}

const getBonusPoints = async (params: Meta): Promise<Response> => {
  const response = await fetchAPI({
    path: `/bonus_points`,
    params,
  })

  const data = get(response, 'data.bonus_points', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useBonusPoints = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: Response
}) => {
  return useQuery<Response, AxiosError>({
    queryKey: [BONUS_POINTS, params],
    queryFn: () => getBonusPoints(params),
    keepPreviousData: true,
    initialData,
  })
}
