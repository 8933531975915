import React, { Fragment } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'
import { Meta } from '@/features/filters/types'
import { QRCodeCategory } from '@/features/qrcode_categories/types'
import { useQRCodeCategories } from '@/features/qrcode_categories/queries'
import { Pagination } from '@/components/shared/Pagination'
import { QRCodeCategoryTable } from './QRCodeCategoryTable'
import { CreateQRCodeCategoryFormModal } from '@/features/qrcode_categories/components'

import { useMeta } from '@/features/filters'
import { t } from '@/lib/helpers'

type Props = {
  qrcode_categories: QRCodeCategory[]
  meta: Meta
}

function QRCodeCategoriesPages(props: Props) {
  const { qrcode_categories: initialQRCodeCategories, meta: initialMeta } =
    props

  const disclosure = useDisclosure()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: qrCodeCategories } = useQRCodeCategories({
    initialData: { data: initialQRCodeCategories, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.qrcode_categories.index.title')}
          addons={
            <Button onClick={disclosure.onOpen} sx={{ h: '48px', w: '148px' }}>
              {t('views.admin.shared.form.new')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <QRCodeCategoryTable data={qrCodeCategories.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <CreateQRCodeCategoryFormModal disclosure={disclosure} />
    </Fragment>
  )
}

export default withApp(QRCodeCategoriesPages)
