import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { POST_RANKINGS } from '../constants'
import { PostRankingsFormInput } from '../types'
import { transformDataIntoParam } from '../utils'

const updatePostRanking = async ({
  form,
  id,
}: {
  form: PostRankingsFormInput
  id: number
}) => {
  await fetchAPI({
    method: 'put',
    path: `/post_rankings/${id}`,
    data: transformDataIntoParam(form),
  })
}

export const useUpdatePostRanking = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updatePostRanking, {
    onSuccess: async () => {
      await queryClient.refetchQueries([POST_RANKINGS])

      toast({
        description: t('views.admin.messages.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
