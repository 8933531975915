import React, { ReactNode } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'

import theme from './theme'

type Props = {
  children?: ReactNode
}

export function ThemeProvider({ children }: Props) {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <Global styles={css``} />
      {children}
    </ChakraProvider>
  )
}
