import React from 'react'

import { Modal } from '@/components/shared/Modal'
import { TagForm } from './TagForm'
import { Box, Text } from '@chakra-ui/react'

import { useCreateTag } from '../mutations'
import { t } from '@/lib/helpers'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const CreateTagFormModal = (props: Props) => {
  const { isOpen, onClose } = props

  const { mutate: createCampaign, isLoading } = useCreateTag()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.tags.new.title')}
        </Text>
        <TagForm
          isLoading={isLoading}
          onSubmitForm={(data) => {
            createCampaign(data, { onSuccess: onClose })
          }}
        />
      </Box>
    </Modal>
  )
}
