import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { BONUS_POINTS } from '../constants'
import { transformBonusPointInput } from '../utils'

const updateBonusPoint = async ({ form, id }: { form: any; id: number }) => {
  const formData = transformBonusPointInput(form)
  await fetchAPI({
    method: 'put',
    path: `/bonus_points/${id}`,
    data: formData,
  })
}

const useUpdateBonusPoint = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateBonusPoint, {
    onSuccess: async () => {
      await queryClient.refetchQueries([BONUS_POINTS])

      toast({
        description: t('views.admin.messages.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}

export { useUpdateBonusPoint }
