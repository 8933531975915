import React, { Fragment, useMemo, useState } from 'react'
import { get } from 'lodash'
import { Image, useDisclosure } from '@chakra-ui/react'

import { BonusPoint } from '@/features/bonus_points/types'

import { formatDateTime, t } from '@/lib/helpers'

import { DataTable, TableEditButton } from '@/components/shared/Table'
import { EditBonusPointFormModal } from '@/features/bonus_points/components'

type Props = {
  data: BonusPoint[]
}

export const BonusPointsTable = ({ data }: Props) => {
  const [actionData, setActionData] = useState<BonusPoint | null>(null)

  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.bonus_points.form.title'),
        accessor: 'title',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.bonus_points.form.header_text'),
        accessor: 'header_text',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.bonus_points.form.description'),
        accessor: 'description',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.bonus_points.form.banner_image'),
        accessor: 'image_url',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return value ? (
            <a href={value} target="_blank" rel="noopener noreferrer">
              <Image src={value} width="80px" height="auto" />
            </a>
          ) : (
            '-'
          )
        },
      },
      {
        Header: t('views.admin.bonus_points.form.point'),
        accessor: 'point',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.bonus_points.form.bonus_type'),
        accessor: 'bonus_type',
        disableSortBy: true,
        Cell: (cellProps) => {
          const bonusType = get(cellProps, 'value')

          return t(`views.admin.bonus_points.form.${bonusType}`)
        },
      },
      {
        Header: t('views.admin.bonus_points.form.created_at'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.bonus_points.form.starts_at'),
        accessor: 'starts_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.bonus_points.form.ended_at'),
        accessor: 'ends_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.bonus_points.form.edit'),
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')

          return (
            <TableEditButton
              ariaLabel={'edit message'}
              onClick={() => {
                setActionData(row)
                onOpen()
              }}
            />
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      <EditBonusPointFormModal
        disclosure={disclosure}
        defaultValues={actionData}
      />
    </Fragment>
  )
}
