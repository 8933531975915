import { t } from '@/lib/helpers'
import { ChallengeType } from './types'

export const CHALLENGE_TYPES = [
  {
    label: 'Daily',
    value: ChallengeType.Daily,
  },
  {
    label: 'Post',
    value: ChallengeType.Post,
  },
  {
    label: 'QR Code',
    value: ChallengeType.QRCode,
  },
  {
    label: 'Link',
    value: ChallengeType.Link,
  },
]

export const COMMENT_COLORS = [
  {
    label: t('views.admin.shared.form.color.black'),
    value: 'black',
  },
  {
    label: t('views.admin.shared.form.color.gray'),
    value: 'gray',
  },
  {
    label: t('views.admin.shared.form.color.red'),
    value: 'red',
  },
  {
    label: t('views.admin.shared.form.color.yellow'),
    value: 'yellow',
  },
  {
    label: t('views.admin.shared.form.color.green'),
    value: 'green',
  },
]

export const CHALLENGES = 'challenges'
