import { Modal } from '@/components/shared/Modal'
import { t } from '@/lib/helpers'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'
import get from 'lodash/get'
import React from 'react'
import { useUpdateLinkForm } from '../mutations/updateLinkForm'
import { LinkForm } from '../types'
import { transformLinkFormIntoForm } from '../utils'
import { LinkFormForm } from './LinkFormForm'

type Props = {
  disclosure: UseDisclosureProps
  initialData: LinkForm
}

export const EditLinkFormModal = (props: Props) => {
  const { disclosure, initialData } = props
  const { isOpen, onClose } = disclosure

  const { mutate: updateLinkForm, isLoading } = useUpdateLinkForm()

  const defaultValue = React.useMemo(
    () => transformLinkFormIntoForm(initialData),
    [initialData],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="946px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.forms.form.edit')}
        </Text>
        <LinkFormForm
          isLoading={isLoading}
          defaultValues={defaultValue}
          onSubmitForm={(data) => {
            updateLinkForm(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
