import React, { Fragment, useMemo, useState } from 'react'
import { get, startCase } from 'lodash'
import { HStack, useDisclosure } from '@chakra-ui/react'

import { Article, ArticleStatus } from '@/features/articles/types'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableEditButton,
  TablePublishButton,
  TableTrashButton,
} from '@/components/shared/Table'
import {
  useDeleteArticle,
  useUpdateArticleStatus,
} from '@/features/articles/mutations'
import { EditArticleModal } from '@/features/articles/components'

type Props = {
  data: Article[]
}

export const ArticleTable = ({ data }: Props) => {
  const [actionData, setActionData] = useState<Article>()

  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const { mutate: deleteArticle } = useDeleteArticle()
  const { mutate: updateArticleStatus } = useUpdateArticleStatus()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.articles.table.title'),
        accessor: 'title',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.articles.table.detail'),
        accessor: 'detail',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.articles.table.provider'),
        accessor: 'provider',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.articles.table.status'),
        accessor: 'status',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return startCase(value)
        },
      },
      {
        Header: t('views.admin.articles.table.published_at'),
        accessor: 'published_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id, status } = row
          const isPublished = status === 'published'
          const text = isPublished ? 'hide' : 'publish'

          return (
            <HStack spacing="2px">
              <TableEditButton
                ariaLabel={'edit article'}
                onClick={() => {
                  setActionData(row)
                  onOpen()
                }}
              />
              <TablePublishButton
                ariaLabel={`${text} article`}
                isPublished={isPublished}
                onClick={() => {
                  if (
                    confirm(t(`views.admin.articles.table.confirm_${text}`))
                  ) {
                    const status = isPublished
                      ? ArticleStatus.Hide
                      : ArticleStatus.Published

                    updateArticleStatus({
                      id,
                      status,
                    })
                  }
                }}
              />
              <TableTrashButton
                ariaLabel={'delete article'}
                onClick={() => {
                  if (confirm(t('views.admin.articles.table.confirm_delete'))) {
                    deleteArticle(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      <EditArticleModal disclosure={disclosure} initialData={actionData} />
    </Fragment>
  )
}
