import React, { Fragment } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Article } from '@/features/articles/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { ArticleTable } from './ArticleTable'
import { CreateArticleModal } from '@/features/articles/components'

import { useMeta } from '@/features/filters'
import { useArticles } from '@/features/articles/queries'
import { t } from '@/lib/helpers'

type Props = {
  articles: Article[]
  meta: Meta
}

function ArticlesPage(props: Props) {
  const { articles: initialArticles, meta: initialMeta } = props

  const disclosure = useDisclosure()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: articles } = useArticles({
    initialData: { data: initialArticles, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.articles.index.title')}
          addons={
            <Button
              onClick={disclosure.onOpen}
              sx={{ h: '48px', minW: '100px' }}
            >
              {t('views.admin.articles.form.new_title')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <ArticleTable data={articles.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <CreateArticleModal disclosure={disclosure} />
    </Fragment>
  )
}

export default withApp(ArticlesPage)
