import React, { ReactNode } from 'react'
import { Grid, SystemStyleObject } from '@chakra-ui/react'

type TextWithIconProps = {
  sx?: SystemStyleObject
  icon: ReactNode
  text: ReactNode
}

export const TextWithIcon = (props: TextWithIconProps) => {
  const { sx, icon, text } = props

  return (
    <Grid
      sx={{
        gridTemplateColumns: '16px 1fr',
        gap: '8px',
        alignItems: 'center',
        ...sx,
      }}
    >
      {icon}
      {text}
    </Grid>
  )
}
