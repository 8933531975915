import React, { Fragment } from 'react'
import {
  Box,
  Button,
  Flex,
  Grid,
  Select,
  useDisclosure,
} from '@chakra-ui/react'
import { get } from 'lodash'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Calendar } from '@/features/calendars/types'
import { Meta } from '@/features/filters/types'

import { UploadXlsxModal } from '@/components/shared/UploadXlsxModal'
import { Pagination } from '@/components/shared/Pagination'
import { CalendarTable } from './CalendarTable'

import { useUploadCalendar } from '@/features/calendars/mutations'
import { useCalendars } from '@/features/calendars/queries'
import { useMeta } from '@/features/filters'
import { t } from '@/lib/helpers'

type Props = {
  calendars: Calendar[]
  meta: Meta
  cities: {
    id: number
    name: string
  }[]
}

function CalendarsPage(props: Props) {
  const { calendars: initialCalendars, meta: initialMeta, cities } = props

  const { meta, setMeta } = useMeta(initialMeta)
  const { onOpen, isOpen, onClose } = useDisclosure()

  const { data: calendars, isFetched } = useCalendars({
    initialData: { data: initialCalendars, meta: initialMeta },
    params: meta,
  })
  const { mutate: uploadCalendar, isLoading } = useUploadCalendar()

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.calendars.index.title')}
          addons={
            <Grid
              sx={{
                gridTemplateColumns: 'repeat(2, max-content)',
                gap: '16px',
              }}
            >
              <Select
                size="lg"
                tabIndex={1}
                placeholder={t('views.admin.cities.form.select_city')}
                onChange={(e) => {
                  setMeta('city_id', e.target.value)
                }}
              >
                {cities.map((c) => (
                  <option key={c.name} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </Select>

              <Button onClick={onOpen} sx={{ h: '48px', w: '100px' }}>
                {t('views.admin.calendars.index.upload')}
              </Button>
            </Grid>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <CalendarTable data={calendars.data} isLoading={!isFetched} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={Number(get(calendars, 'meta.total'))}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <UploadXlsxModal
        isOpen={isOpen}
        onClose={onClose}
        cities={cities}
        isLoading={isLoading}
        onSubmit={({ file, city_id }) =>
          uploadCalendar({ file, city_id }, { onSuccess: onClose })
        }
      />
    </Fragment>
  )
}

export default withApp(CalendarsPage)
