import React from 'react'

import { Modal } from '@/components/shared/Modal'
import { BonusPointForm } from './BonusPointForm'
import { Box, Text } from '@chakra-ui/react'

import { useCreateBonusPoint } from '../mutations'
import { t } from '@/lib/helpers'

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const CreateBonusPointFormModal = (props: Props) => {
  const { isOpen, onClose } = props

  const { mutate: createBonusPoint, isLoading } = useCreateBonusPoint()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="946px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.bonus_points.index.new')}
        </Text>
        <BonusPointForm
          isLoading={isLoading}
          onSubmitForm={(data) => {
            createBonusPoint(data, { onSuccess: onClose })
          }}
        />
      </Box>
    </Modal>
  )
}
