import React from 'react'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { LocationForm } from './LocationForm'
import { useCreateLocation } from '../mutations'

import { t } from '@/lib/helpers'
import { QRCodeCategory } from '@/features/qrcode_categories/types'

type Props = {
  disclosure: UseDisclosureProps
  qrCategories: QRCodeCategory[]
}

export const CreateLocationFormModal = (props: Props) => {
  const { disclosure, qrCategories } = props

  const { isOpen, onClose } = disclosure

  const { mutate: createQRCategory, isLoading } = useCreateLocation()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" sx={{ mb: '20px' }} isTruncated>
          {t('views.admin.locations.new.title')}
        </Text>

        <LocationForm
          isLoading={isLoading}
          qrCategories={qrCategories}
          onSubmitForm={(data) => {
            createQRCategory(data, { onSuccess: onClose })
          }}
        />
      </Box>
    </Modal>
  )
}
