import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { CalendarPayload } from '../types'
import { CALENDARS, COLLECTION_SITE_CALENDARS } from '../constants'

const uploadCalendar = async ({ file, city_id }: CalendarPayload) => {
  const formData = new FormData()

  formData.append('file', file)
  formData.append('city_id', String(city_id))

  await fetchAPI({
    method: 'post',
    path: '/calendars',
    data: formData,
  })
}

export const useUploadCalendar = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(uploadCalendar, {
    onSuccess: async () => {
      await queryClient.refetchQueries([CALENDARS])
      await queryClient.refetchQueries([COLLECTION_SITE_CALENDARS])

      toast({
        description: t('views.admin.garbages.services.upload_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
