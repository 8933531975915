import { get } from 'lodash'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import { Article, ArticleFormInput } from './types'

export const uploadImageCallback = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = function (fileLoadedEvent) {
      const base64Data = fileLoadedEvent.target.result

      resolve({
        data: {
          link: base64Data,
        },
      })
    }

    fileReader.onerror = function () {
      reject()
    }

    fileReader.readAsDataURL(file)
  })
}

export const prepareArticlePayload = (form: ArticleFormInput) => {
  const formData = new FormData()

  formData.append('title', form.title)
  formData.append('detail', form.detail)
  formData.append('provider', form.provider)
  if (typeof form.cover_image !== 'string') {
    formData.append('cover_image', form.cover_image || '')
  }
  if (form.content) {
    formData.append(
      'content',
      draftToHtml(convertToRaw(form.content.getCurrentContent())),
    )
  }

  return formData
}

export const transformArticleIntoForm = (data: Article): ArticleFormInput => {
  let content = get(data, 'content', undefined)

  if (content) {
    const contentBlock = htmlToDraft(content)

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
        contentBlock.entityMap,
      )
      const editorState = EditorState.createWithContent(contentState)
      content = editorState
    }
  }

  return {
    title: get(data, 'title'),
    detail: get(data, 'detail'),
    provider: get(data, 'provider'),
    cover_image: get(data, 'cover_image_url'),
    content,
  }
}
