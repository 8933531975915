import React, { useMemo } from 'react'

import { t } from '@/lib/helpers'

import { DataTable } from '@/components/shared/Table'

import { Faq } from '@/features/faqs/types'

type Props = {
  data: Faq[]
}

export const FaqTable = ({ data }: Props) => {
  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.faqs.table.title'),
        accessor: 'title',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.faqs.table.category'),
        accessor: 'category',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.faqs.table.description'),
        accessor: 'description',
        disableSortBy: true,
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return <DataTable data={memoizedData} columns={columns} />
}
