import { Modal } from '@/components/shared/Modal'
import { CityCollection, CityCollectionSiteCollection } from '@/features/types'
import { t } from '@/lib/helpers'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'
import { get } from 'lodash'
import React from 'react'
import { useUpdateHoliday } from '../mutations'
import { Holiday } from '../types'
import { transformHolidayIntoForm } from '../utils'
import { HolidayForm } from './HolidayForm'

type Props = {
  disclosure: UseDisclosureProps
  cities: CityCollection
  sites: CityCollectionSiteCollection
  initialData: Holiday
}

export const EditHolidayModal = (props: Props) => {
  const { disclosure, cities, sites, initialData } = props
  const { isOpen, onClose } = disclosure

  const { mutate: updateHoliday, isLoading } = useUpdateHoliday()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="480px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.holidays.form.edit')}
        </Text>
        <HolidayForm
          isLoading={isLoading}
          defaultValues={transformHolidayIntoForm(initialData)}
          onSubmitForm={(data) => {
            updateHoliday(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
          cities={cities}
          sites={sites}
        />
      </Box>
    </Modal>
  )
}
