import { TableTrashButton } from '@/components/shared/Table'
import { FormControl } from '@/features/forms/components'
import { t } from '@/lib/helpers'
import { Button, Flex, Input } from '@chakra-ui/react'
import React from 'react'
import {
  Control,
  DeepMap,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { MAX } from '../constants'
import { LinkFormInput } from '../types'

type Props = {
  questionIndex: number
  control: Control<LinkFormInput>
  register: ReturnType<typeof useForm>['register']
  errors: DeepMap<LinkFormInput, FieldError>
}

export const OptionFields = ({
  questionIndex,
  control,
  register,
  errors,
}: Props) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `questions[${questionIndex}].options`,
  })

  const addOptions = (focus = true) => {
    append({ text: '' }, focus)
  }

  React.useEffect(() => {
    if (!fields.length) {
      addOptions(false)
      addOptions(false)
    }
  }, [fields])

  return (
    <Flex sx={{ flexDir: 'column', gap: '8px' }}>
      {fields.map((field, index) => (
        <Flex key={field.id} sx={{ gap: '4px', alignItems: 'flex-end' }}>
          <FormControl
            id={`questions[${questionIndex}].options[${index}]`}
            label={t('views.admin.forms.form.option_number', {
              number: index + 1,
            })}
            isRequired
            errorMsg={
              errors?.questions?.[questionIndex]?.options?.[index]?.text
                ?.message
            }
          >
            <Input
              size="lg"
              placeholder={t('views.admin.forms.form.option_number', {
                number: index + 1,
              })}
              ref={register()}
              name={`questions[${questionIndex}].options[${index}].text`}
              defaultValue={field.text}
            />
          </FormControl>
          {fields.length > 2 && (
            <TableTrashButton
              ariaLabel={'delete option'}
              onClick={() => remove(index)}
              sx={{ mb: '9px' }}
            />
          )}
        </Flex>
      ))}
      {fields.length < MAX.OPTION && (
        <Button onClick={() => addOptions()} w="fit-content">
          {t('views.admin.forms.form.add_option')}
        </Button>
      )}
    </Flex>
  )
}
