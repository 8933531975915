import { Modal } from '@/components/shared/Modal'
import { t } from '@/lib/helpers'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'
import React from 'react'
import { useCreateLinkForm } from '../mutations'
import { LinkFormForm } from './LinkFormForm'

type Props = {
  disclosure: UseDisclosureProps
}

export const CreateLinkFormModal = ({ disclosure }: Props) => {
  const { isOpen, onClose } = disclosure

  const { mutate: createLinkForm, isLoading } = useCreateLinkForm()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="946px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.forms.form.create')}
        </Text>
        <LinkFormForm
          isLoading={isLoading}
          onSubmitForm={(data) => {
            createLinkForm(data, { onSuccess: onClose })
          }}
        />
      </Box>
    </Modal>
  )
}
