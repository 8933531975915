import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { CHALLENGES } from '../constants'
import { ChallengeFormInput } from '../types'
import { transformChallengeInput } from '../utils'

const createChallenge = async (form: ChallengeFormInput) => {
  const data = transformChallengeInput(form)

  await fetchAPI({
    method: 'post',
    path: '/challenges',
    data,
  })
}

export const useCreateChallenge = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createChallenge, {
    onSuccess: async () => {
      await queryClient.refetchQueries([CHALLENGES], { active: true })

      toast({
        description: t('views.admin.challenges.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
