export const Select = {
  parts: ['field', 'icon'],
  sizes: {
    lg: {
      field: {
        fontSize: '16px',
      },
    },
  },
}
