import { SelectOptions } from '@/components/shared/ReactSelect/types'
import { optionsToValues, valuesToOptions } from '@/features/filters/utils'
import { FormControl } from '@/features/forms/components'
import { CityCollection, CityCollectionSiteCollection } from '@/features/types'
import { formatDateTime, t } from '@/lib/helpers'
import { useCityOptions } from '@/lib/hooks/useCityOptions'
import { Button, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import MultiSelect from 'react-multi-select-component'
import * as yup from 'yup'
import { HolidayFormInput } from '../types'

type Props = {
  defaultValues?: Partial<HolidayFormInput>
  onSubmitForm: (data: HolidayFormInput) => void
  cities: CityCollection
  sites: CityCollectionSiteCollection
  isLoading: boolean
}

export const HolidayForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, cities, sites, isLoading } = props
  const [siteOptions, setSiteOptions] = React.useState<SelectOptions<number>>(
    [],
  )

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<HolidayFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = ({
    city_ids,
    collection_site_ids,
    ...data
  }: HolidayFormInput) => {
    if (isSubmitting) return

    const selectedSiteValue = collection_site_ids
      .map((id) => sites.find((s) => s.id === id))
      .filter((s) => city_ids.includes(s?.city_id))
      .map((s) => s.id)

    onSubmitForm({ ...data, city_ids, collection_site_ids: selectedSiteValue })
  }

  const cityOptions = useCityOptions(cities)

  const onCityChange = (idList: number[]) => {
    if (!sites?.length) return []

    const siteList = idList.length
      ? sites.filter((s) => idList.includes(s.city_id))
      : sites
    const newSiteOptions = siteList.map((s) => ({ label: s.name, value: s.id }))
    setSiteOptions(newSiteOptions)
  }

  React.useEffect(() => {
    onCityChange(defaultValues.city_ids ?? [])
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gridTemplateColumns: 'repeat(1, 1fr)', gap: '32px' }}>
        <Grid sx={{ gridTemplateColumns: 'repeat(1, 1fr)', gap: '24px' }}>
          <FormControl
            id="title"
            label={t('views.admin.holidays.form.title')}
            isRequired
            errorMsg={errors.title?.message}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.holidays.form.title')}
              ref={register}
              name="title"
              tabIndex={1}
            />
          </FormControl>

          <Controller
            name="city_ids"
            defaultValue={[]}
            control={control}
            render={({ onChange, value }) => {
              return (
                <FormControl
                  id="city_ids"
                  label={t('views.admin.holidays.form.city')}
                  isRequired
                  sx={{
                    fontSize: '16px',
                  }}
                >
                  <MultiSelect
                    options={cityOptions}
                    labelledBy={'Select'}
                    value={valuesToOptions<number>(value, cityOptions)}
                    onChange={(options) => {
                      onChange(optionsToValues(options))
                      onCityChange(options.map((c) => c.value))
                    }}
                  />
                </FormControl>
              )
            }}
          />

          <Controller
            name="collection_site_ids"
            defaultValue={[]}
            control={control}
            render={({ onChange, value }) => {
              return (
                <FormControl
                  id="collection_site_ids"
                  label={t('views.admin.holidays.form.collection_site')}
                  sx={{
                    fontSize: '16px',
                  }}
                >
                  <MultiSelect
                    options={siteOptions}
                    labelledBy={'Select'}
                    value={valuesToOptions<number>(value, siteOptions)}
                    onChange={(options) => {
                      onChange(optionsToValues(options))
                    }}
                  />
                </FormControl>
              )
            }}
          />

          <FormControl
            id="starts_at"
            label={t('views.admin.holidays.form.released_at')}
            isRequired
            errorMsg={errors.starts_at?.message}
          >
            <Input
              type="date"
              name="starts_at"
              ref={register}
              defaultValue={formatDateTime(new Date().toString(), 'YYYY-MM-DD')}
              tabIndex={4}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="ends_at"
            label={t('views.admin.holidays.form.ended_at')}
            isRequired
            errorMsg={errors.ends_at?.message}
          >
            <Input
              type="date"
              name="ends_at"
              ref={register}
              defaultValue={formatDateTime(
                dayjs().add(1, 'day').toString(),
                'YYYY-MM-DD',
              )}
              tabIndex={5}
              size="lg"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        isLoading={isSubmitting || isLoading}
        tabIndex={6}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.holidays.form.create')
          : t('views.admin.holidays.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(t('views.shared.form.required')),
  city_ids: yup
    .array()
    .of(yup.number())
    .min(1, t('views.shared.form.required')),
  collection_site_ids: yup.array().of(yup.number()),
  starts_at: yup.date().required(t('views.shared.form.required')),
  ends_at: yup.date().required(t('views.shared.form.required')),
})
