import { get } from 'lodash'

import { formatDateTime } from '@/lib/helpers'
import { Campaign, CampaignFormInput, CampaignType } from './types'

export const transformCampaignInput = (form: CampaignFormInput) => {
  const {
    title,
    description,
    campaign_code,
    campaign_type,
    point,
    releasedAt,
    releasedAtHr,
    releasedAtMin,
    endedAt,
    endedAtHr,
    endedAtMin,
    tag_id,
  } = form

  return {
    title,
    description,
    campaign_code,
    campaign_type,
    point,
    released_at: `${formatDateTime(
      releasedAt,
      'YYYY-MM-DD',
    )} ${releasedAtHr}:${releasedAtMin}`,
    ended_at: `${formatDateTime(
      endedAt,
      'YYYY-MM-DD',
    )} ${endedAtHr}:${endedAtMin}`,
    tag_id,
  }
}

export const transformCampaignIntoForm = (
  data: Campaign,
): CampaignFormInput => {
  const releasedAt = get(data, 'released_at')
  const endedAt = get(data, 'ended_at')

  return {
    title: get(data, 'title'),
    description: get(data, 'description'),
    campaign_type: get(data, 'campaign_type') as CampaignType,
    campaign_code: get(data, 'campaign_code'),
    point: String(get(data, 'point')),
    releasedAt: formatDateTime(releasedAt, 'YYYY-MM-DD'),
    releasedAtHr: formatDateTime(releasedAt, 'HH') || '0',
    releasedAtMin: formatDateTime(releasedAt, 'mm') || '0',
    endedAt: formatDateTime(endedAt, 'YYYY-MM-DD'),
    endedAtHr: formatDateTime(endedAt, 'HH') || '0',
    endedAtMin: formatDateTime(endedAt, 'mm') || '0',
    tag_id: get(data, 'tag.id'),
  }
}
