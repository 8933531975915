import React, { Fragment, useEffect } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { Pagination } from '@/components/shared/Pagination'
import { useMeta } from '@/features/filters'
import { useGetPostRanking } from '@/features/post_rankings/queries'
import { RankingResponse } from '@/features/post_rankings/types'
import { Meta } from '@/features/filters/types'
import { DashboardHeader, withApp } from '@/features/Layout'
import { t } from '@/lib/helpers'
import { CreateRankingModal } from '@/features/post_rankings/components'

import { PostRankingsTable } from './PostRankingsTable'

type Props = {
  post_rankings: RankingResponse[]
  meta: Meta
}
function PostRankingsPage({
  meta: initialMeta,
  post_rankings: initailData,
}: Props) {
  const disclosure = useDisclosure()
  const { meta, setMeta, setMetas } = useMeta(initialMeta)
  const { data: postRankingsData } = useGetPostRanking({
    initialData: { data: initailData, meta: initialMeta },
    params: meta,
  })

  useEffect(() => {
    setMetas(postRankingsData.meta)
  }, [postRankingsData])

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.post_ranking.index.title')}
          addons={
            <Button
              onClick={disclosure.onOpen}
              sx={{ h: '48px', minW: '100px' }}
            >
              {t('views.admin.shared.form.create')}
            </Button>
          }
        />
        <Box sx={{ mb: '24px' }}>
          <PostRankingsTable
            data={postRankingsData.data ? postRankingsData.data : []}
          />
        </Box>
        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>
      <CreateRankingModal disclosure={disclosure} />
    </Fragment>
  )
}

export default withApp(PostRankingsPage)
