import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { Tag } from '../types'
import { Meta } from '@/features/filters/types'

import { TAGS, API_PATH } from '../constants'

type TagsResponse = {
  data: Tag[]
  meta: Meta
}

const getTags = async (params: Meta = {}): Promise<TagsResponse> => {
  const response = await fetchAPI({
    path: API_PATH,
    params,
  })

  const data = get(response, 'data.tags', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

type Params = {
  params?: Meta
  initialData?: TagsResponse
}

export const useTags = ({ params = {}, initialData }: Params = {}) => {
  return useQuery<TagsResponse, AxiosError>({
    queryKey: [TAGS, params],
    queryFn: () => getTags(params),
    keepPreviousData: true,
    initialData,
  })
}
