import React from 'react'
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react'

export type BreadcrumbItem = {
  title: string
  link?: string
}

type Props = {
  data: BreadcrumbItem[]
}

export const Breadcrumb = (props: Props) => {
  const { data } = props

  return (
    <ChakraBreadcrumb separator="/" spacing="4px">
      {data.map(({ title, link }) => {
        const isCurrentPage = !Boolean(link)

        return (
          <BreadcrumbItem key={`${title}${link}`} isCurrentPage={isCurrentPage}>
            <BreadcrumbLink href={link} sx={{ color: 'gray.600' }}>
              {title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )
      })}
    </ChakraBreadcrumb>
  )
}
