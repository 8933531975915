import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { SHIPPINGS } from '../constants'
import { ShippingPayload } from '../types'

const updateShipping = async ({ id, status }: ShippingPayload) => {
  await fetchAPI({
    path: `/shippings/${id}`,
    method: 'put',
    data: {
      status,
    },
  })
}

export const useUpdateShipping = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateShipping, {
    async onSuccess() {
      await queryClient.refetchQueries([SHIPPINGS])

      toast({
        description: t('views.admin.shipping.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
