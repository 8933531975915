import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

import { uploadImageCallback } from '@/features/articles/utils'

type Props = {
  initialState?: EditorState
  value: EditorState
  handleChange: (state: any[]) => void
}

export const EditorImage = (props: Props) => {
  const { initialState, handleChange, value } = props

  return (
    <Editor
      initialEditorState={initialState}
      editorState={value}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={handleChange}
      toolbar={{
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        image: {
          uploadCallback: uploadImageCallback,
          alt: { present: true, mandatory: true },
        },
        fontFamily: {
          options: [
            'Sawarabi Gothic',
            'Noto Sans JP',
            'Arial',
            'Georgia',
            'Impact',
            'Tahoma',
            'Times New Roman',
            'Verdana',
          ],
        },
      }}
    />
  )
}
