import { get } from 'lodash'

import { Tag, TagFormInput } from './types'

export const transformTagInput = (form: TagFormInput) => {
  const { name } = form

  return { name }
}

export const transformTagIntoForm = (data: Tag): TagFormInput => {
  return {
    name: get(data, 'name'),
  }
}
