import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { QRCodeCategory } from '../types'
import { Meta } from '@/features/filters/types'

import { QR_CATEGORIES, API_PATH } from '../constants'

type Response = {
  data: QRCodeCategory[]
  meta: Meta
}

const getQRCodeCategories = async (params: Meta): Promise<Response> => {
  const response = await fetchAPI({
    path: API_PATH,
    params,
  })

  const data = get(response, 'data.qrcode_categories', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

type Params = {
  params?: Meta
  initialData?: Response
}

export const useQRCodeCategories = ({
  params = {},
  initialData,
}: Params = {}) => {
  return useQuery<Response, AxiosError>({
    queryKey: [QR_CATEGORIES, params],
    queryFn: () => getQRCodeCategories(params),
    keepPreviousData: true,
    initialData,
  })
}
