import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { TAGS, API_PATH } from '../constants'
import { TagFormInput } from '../types'
import { transformTagInput } from '../utils'

const createTag = async (form: TagFormInput) => {
  const data = transformTagInput(form)

  await fetchAPI({
    method: 'post',
    path: API_PATH,
    data,
  })
}

export const useCreateTag = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createTag, {
    onSuccess: async () => {
      await queryClient.refetchQueries([TAGS])

      toast({
        description: t('views.admin.campaigns.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
