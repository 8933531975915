import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { BONUS_POINTS } from '../constants'
import { transformBonusPointInput } from '../utils'

const createBonusPoint = async (form: any) => {
  const formData = transformBonusPointInput(form)

  await fetchAPI({
    method: 'post',
    path: '/bonus_points',
    data: formData,
  })
}

export const useCreateBonusPoint = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createBonusPoint, {
    onSuccess: async () => {
      await queryClient.refetchQueries([BONUS_POINTS], { active: true })

      toast({
        description: t('views.admin.challenges.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
