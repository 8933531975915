import { LinkForm, LinkFormInput, LinkFormRequest } from './types'

export const transformLinkFormInput = (
  data: LinkFormInput,
): LinkFormRequest => ({
  id: data.id,
  form: {
    title: data.title,
    description: data.description,
  },
  questions: data.questions.map((q, index) => ({
    ...q,
    id: q.id && +q.id,
    order_number: index + 1,
    options: q.options?.map((o) => o.text),
  })),
})

export const transformLinkFormIntoForm = (data: LinkForm): LinkFormInput => ({
  ...data,
  questions: data.questions.map((q) => ({
    ...q,
    options: q.options?.map((o) => ({ text: o })),
  })),
})
