import { get } from 'lodash'
import isHtml from 'is-html'

export function getErrorMsg(error) {
  if (get(error, 'response')) {
    if (typeof error.response.data === 'string') {
      if (isHtml(error.response.data)) {
        return 'Something went wrong'
      }

      return error.response.data
    }

    let errors = get(error, 'response.data.errors', [])
    if (errors.length == 0) {
      errors = get(error, 'response.data', [])
    }

    if (errors.length > 0) {
      return errors[0]
    }

    if (errors.full_messages) {
      return get(errors, 'full_messages[0]', '')
    }

    const errorMsg = get(error, 'response.data.error', '')

    if (errorMsg !== '') {
      return errorMsg
    }

    return 'Something went wrong'
  } else if (get(error, 'request')) {
    return 'Network Error'
  }

  return 'Something went wrong'
}
