import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { t } from '@/lib/helpers'
import { POST_RANKINGS } from '../constants'
import { PostRankingsFormInput } from '../types'
import { getErrorMessages, transformDataIntoParam } from '../utils'

const createPostRanking = async (form: PostRankingsFormInput) => {
  await fetchAPI({
    method: 'post',
    path: `/post_rankings`,
    data: transformDataIntoParam(form),
  })
}

export const useCreatePostRanking = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createPostRanking, {
    onSuccess: async () => {
      await queryClient.refetchQueries([POST_RANKINGS])

      toast({
        description: t('views.admin.messages.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMessages(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
