import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { MarkerHue, MarkerHueOption } from '../types'

import { MARKER_HUES } from '../constants'

const getMarkerHues = async (): Promise<MarkerHue[]> => {
  const response = await fetchAPI({
    path: '/marker_hues',
  })

  return response.data?.data ?? []
}

export const useMarkerHues = () => {
  return useQuery<MarkerHue[], AxiosError, MarkerHueOption[]>({
    queryKey: MARKER_HUES,
    queryFn: getMarkerHues,
    keepPreviousData: true,
    staleTime: Infinity,
    select: (data) => {
      return data.map((t) => {
        return {
          value: t.value,
          label: t.ja_name,
        }
      })
    },
  })
}
