import dayjs from 'dayjs'
import { get, entries, nth } from 'lodash'

export const formatDateTime = (date: string, pattern = 'YYYY-MM-DD HH:mm') => {
  if (!date) return null

  return dayjs(date).format(pattern)
}

export const getFileSize = (sizeInByte: number): string => {
  if (!sizeInByte) return ''

  const inKB = sizeInByte / 1024
  if (inKB < 1) return `${sizeInByte} Bytes`
  const inMB = inKB / 1024
  if (inMB < 1) return `${inKB.toFixed(2)} KB`
  const inGB = inMB / 1024
  if (inGB < 1) return `${inMB.toFixed(2)} MB`
}

export const t = (key, args = {}) => {
  try {
    let text = get(gon.locales, `${gon.currentLocale}.${key}`, key)
    const argsEntries = entries(args)

    if (argsEntries.length) {
      text = argsEntries.reduce((result, current) => {
        const pattern = new RegExp(`%{${nth(current, 0)}}`, 'g')

        return result.replace(pattern, nth(current, 1) || '')
      }, text)
    }

    return text
  } catch (e) {}
}
