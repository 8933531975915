import { FormControl, TimeSelect } from '@/features/forms/components'
import { LinkFormItem } from '@/features/link_forms/types'
import { useTags } from '@/features/tags/queries'
import { formatDateTime, t } from '@/lib/helpers'
import {
  Button,
  Grid,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
  Checkbox,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { CHALLENGE_TYPES, COMMENT_COLORS } from '../constants'
import { ChallengeFormInput, ChallengeType } from '../types'

type Props = {
  defaultValues?: ChallengeFormInput
  onSubmitForm: (data: ChallengeFormInput) => void
  isLoading: boolean
  forms: LinkFormItem[]
}

export const ChallengeForm = ({
  defaultValues,
  onSubmitForm,
  isLoading,
  forms,
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    formState: { isSubmitting },
  } = useForm<ChallengeFormInput>({
    defaultValues: defaultValues || { only_once: false },
    resolver: yupResolver(schema),
  })
  console.log('defaultValues :', defaultValues)

  const onSubmit = (data: ChallengeFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  const { data: tagsData, isSuccess: isLoadTagsSuccess } = useTags({
    params: { per: -1 },
  })
  const tags = tagsData?.data || []

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gridTemplateColumns: 'repeat(5, 1fr)', gap: '24px 16px' }}>
        <FormControl
          id="title"
          label={t('views.admin.challenges.form.title')}
          isRequired
          sx={{ gridColumn: '1/-1' }}
          errorMsg={errors.title?.message}
        >
          <Input
            size="lg"
            placeholder={t('views.admin.challenges.form.title')}
            ref={register}
            name="title"
            tabIndex={1}
          />
        </FormControl>

        {watch('challengeType') !== ChallengeType.Link && (
          <>
            <FormControl
              id="description"
              label={t('views.admin.challenges.form.description')}
              sx={{ gridColumn: '1/-1' }}
            >
              <Textarea
                size="lg"
                name="description"
                placeholder={t(
                  'views.admin.challenges.form.description_placeholder',
                )}
                resize="none"
                sx={{ h: '96px' }}
                ref={register}
                tabIndex={2}
              />
            </FormControl>
          </>
        )}

        <FormControl
          id="challengeType"
          label={t('views.admin.challenges.form.challenge_type')}
          isRequired
          sx={{ gridColumn: '1/-1' }}
          errorMsg={errors.challengeType?.message}
        >
          <Select size="lg" ref={register} name="challengeType" tabIndex={3}>
            {CHALLENGE_TYPES.map(({ label, value }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </Select>
        </FormControl>

        {(watch('challengeType') === ChallengeType.Daily ||
          watch('challengeType') === ChallengeType.Post) && (
          <>
            <FormControl
              id="only_once"
              label={t('views.admin.challenges.form.only_once')}
              sx={{ gridColumn: '1/-1' }}
            >
              <Checkbox
                name="only_once"
                ref={register}
                colorScheme="green"
                defaultIsChecked={register}
              ></Checkbox>
            </FormControl>
          </>
        )}

        {(watch('challengeType') === ChallengeType.Daily ||
          watch('challengeType') === ChallengeType.Post ||
          watch('challengeType') === ChallengeType.Link) && (
          <>
            <FormControl
              id="point"
              label={t('views.admin.challenges.form.point')}
              isRequired
              sx={{ gridColumn: '1/-1' }}
            >
              <NumberInput
                size="lg"
                defaultValue={0}
                name="point"
                tabIndex={4}
                ref={register}
              >
                <NumberInputField ref={register} />
              </NumberInput>
            </FormControl>
          </>
        )}

        {(watch('challengeType') === ChallengeType.Daily ||
          watch('challengeType') === ChallengeType.Post) && (
          <>
            {isLoadTagsSuccess && (
              <FormControl
                id="tagId"
                label={t('views.admin.challenges.form.tag')}
                sx={{ gridColumn: '1/-1' }}
              >
                <Select size="lg" ref={register} name="tagId" tabIndex={5}>
                  {tags.map(({ id, name }) => (
                    <option value={id} key={id}>
                      {name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        )}

        {watch('challengeType') === ChallengeType.Link && (
          <>
            <FormControl
              id="form_id"
              label={t('views.admin.challenges.form.form')}
              sx={{ gridColumn: '1/-1' }}
              errorMsg={errors.form_id?.message}
            >
              <Select size="lg" ref={register} name="form_id" tabIndex={4}>
                {[{ title: '', id: '' }, ...forms].map(({ id, title }) => (
                  <option value={id} key={id}>
                    {title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              id="rewarding_message"
              label={t('views.admin.challenges.form.rewarding_message')}
              isRequired
              sx={{ gridColumn: '1/-1' }}
              errorMsg={errors.rewarding_message?.message}
            >
              <Input
                size="lg"
                placeholder={t('views.admin.challenges.form.rewarding_message')}
                ref={register}
                name="rewarding_message"
                tabIndex={5}
              />
            </FormControl>
            <FormControl
              id="url"
              label={t('views.admin.challenges.form.url')}
              sx={{ gridColumn: '1/-1' }}
              errorMsg={errors.url?.url}
            >
              <Input
                size="lg"
                placeholder={t('views.admin.challenges.form.url')}
                ref={register}
                name="url"
                tabIndex={5}
              />
            </FormControl>
          </>
        )}

        {watch('challengeType') !== ChallengeType.Link && (
          <>
            <FormControl
              id="comment1"
              label={`${t('views.admin.challenges.form.comment')} 1`}
              sx={{ gridColumn: '1/-1' }}
            >
              <Textarea
                size="lg"
                name="comment1"
                resize="none"
                sx={{ h: '96px' }}
                ref={register}
                placeholder={`${t('views.admin.challenges.form.comment')} 1`}
              />
            </FormControl>

            <FormControl
              id="color1"
              label={`${t('views.admin.challenges.form.color')} 1`}
              sx={{ gridColumn: '1/-1' }}
            >
              <Select
                size="lg"
                ref={register}
                name="color1"
                placeholder={`${t(
                  'views.admin.challenges.form.color_placeholder',
                )} 1`}
              >
                {COMMENT_COLORS.map(({ label, value }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl
              id="comment2"
              label={`${t('views.admin.challenges.form.comment')} 2`}
              sx={{ gridColumn: '1/-1' }}
            >
              <Textarea
                size="lg"
                name="comment2"
                resize="none"
                sx={{ h: '96px' }}
                ref={register}
                placeholder={`${t('views.admin.challenges.form.comment')} 2`}
              />
            </FormControl>

            <FormControl
              id="color1"
              label={`${t('views.admin.challenges.form.color')} 2`}
              sx={{ gridColumn: '1/-1' }}
            >
              <Select
                size="lg"
                ref={register}
                name="color2"
                placeholder={`${t(
                  'views.admin.challenges.form.color_placeholder',
                )} 2`}
              >
                {COMMENT_COLORS.map(({ label, value }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        <FormControl
          id="releasedAt"
          label={t('views.admin.challenges.form.released_at')}
          isRequired
          sx={{ gridColumn: '1/4' }}
          errorMsg={errors.releasedAt?.message}
        >
          <Input
            type="date"
            name="releasedAt"
            ref={register}
            defaultValue={formatDateTime(new Date().toString(), 'YYYY-MM-DD')}
            tabIndex={5}
            size="lg"
          />
        </FormControl>

        <FormControl
          id="releasedAtHr"
          label={t('views.admin.challenges.form.time')}
          isRequired
        >
          <TimeSelect innerRef={register} name={'releasedAtHr'} tabIndex={6} />
        </FormControl>

        <FormControl id="releasedAtMin" sx={{ mt: 'auto' }}>
          <TimeSelect
            isMin={true}
            innerRef={register}
            name={'releasedAtMin'}
            tabIndex={7}
          />
        </FormControl>

        <FormControl
          id="endedAt"
          label={t('views.admin.challenges.form.ended_at')}
          isRequired
          sx={{ gridColumn: '1/4' }}
          errorMsg={errors.endedAt?.message}
        >
          <Input
            type="date"
            name="endedAt"
            ref={register}
            defaultValue={formatDateTime(
              dayjs().add(7, 'day').toString(),
              'YYYY-MM-DD',
            )}
            tabIndex={8}
            size="lg"
          />
        </FormControl>

        <FormControl
          id="endedAtHr"
          label={t('views.admin.challenges.form.time')}
          isRequired
        >
          <TimeSelect innerRef={register} name={'endedAtHr'} tabIndex={9} />
        </FormControl>

        <FormControl id="endedAtMin" sx={{ mt: 'auto' }}>
          <TimeSelect
            isMin={true}
            innerRef={register}
            name={'endedAtMin'}
            tabIndex={10}
          />
        </FormControl>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        size="lg"
        isLoading={isSubmitting || isLoading}
        tabIndex={11}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.challenges.form.create')
          : t('views.admin.challenges.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(t('views.admin.shared.required')),
  description: yup.string(),
  challengeType: yup
    .string()
    .oneOf([
      ChallengeType.Daily,
      ChallengeType.Post,
      ChallengeType.QRCode,
      ChallengeType.Link,
    ]),
  point: yup.number().integer(),
  releasedAt: yup.date().required(t('views.admin.shared.required')),
  releasedAtHr: yup.number().positive().integer().min(0).max(23),
  releasedAtMin: yup.number().positive().integer().min(0).max(59),
  endedAt: yup.date().required(t('views.admin.shared.required')),
  endedAtHr: yup.number().positive().integer().min(0).max(23),
  endedAtMin: yup.number().positive().integer().min(0).max(59),
  comment1: yup.string(),
  comment2: yup.string(),
  color1: yup.string(),
  color2: yup.string(),
  form_id: yup.string(),
  rewarding_message: yup.string(),
  url: yup.string(),
  only_once: yup.bool(),
})
