import React from 'react'
import { Checkbox } from '@chakra-ui/react'

import { useUpdateAppConfig } from '../mutations'
import { AppConfig } from '../types'

type Props = {
  data: AppConfig
}

export const AppConfigCheckbox = (props: Props) => {
  const { data } = props
  const { mutate: updateAppConfig, isLoading } = useUpdateAppConfig()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateAppConfig({
      id: data.id,
      value: e.target.checked,
    })
  }

  return (
    <Checkbox
      isDisabled={isLoading}
      isChecked={data.value}
      onChange={onChange}
      colorScheme="green"
    />
  )
}
