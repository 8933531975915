import React, { Fragment } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Meta } from '@/features/filters/types'
import { Location } from '@/features/locations/types'
import { useLocations } from '@/features/locations/queries'

import { Pagination } from '@/components/shared/Pagination'
import { LocationTable } from './LocationTable'
import {
  CreateLocationFormModal,
  ImportLocationsModal,
} from '@/features/locations/components'

import { useMeta } from '@/features/filters'
import { t } from '@/lib/helpers'
import { useQRCodeCategories } from '@/features/qrcode_categories/queries'
import { LoadingOverlay } from '@/components/shared/Table/TableLoadingOverlay'

type Props = {
  locations: Location[]
  meta: Meta
}

function LocationsPages(props: Props) {
  const { locations: initialLocations, meta: initialMeta } = props

  const createDisclosure = useDisclosure()
  const importDisclosure = useDisclosure()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: locations, isLoading: isLoadingLocations } = useLocations({
    initialData: { data: initialLocations, meta: initialMeta },
    params: meta,
  })
  const { data: qrCategoriesData, isLoading: isLoadingQrCategories } =
    useQRCodeCategories({ params: { per: -1 } })
  const qrCategories = qrCategoriesData?.data ?? []

  if (isLoadingLocations || isLoadingQrCategories) {
    return (
      <Box sx={{ h: '100%', position: 'relative' }}>
        <LoadingOverlay background="none" />
      </Box>
    )
  }

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.locations.index.title')}
          addons={
            <React.Fragment>
              <Button
                variant="outline"
                onClick={importDisclosure.onOpen}
                sx={{ h: '48px', w: '160px', marginRight: '16px' }}
              >
                {t('views.admin.locations.index.import')}
              </Button>
              <Button
                onClick={createDisclosure.onOpen}
                sx={{ h: '48px', w: '148px' }}
              >
                {t('views.admin.locations.index.new')}
              </Button>
            </React.Fragment>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <LocationTable data={locations.data} qrCategories={qrCategories} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <ImportLocationsModal disclosure={importDisclosure} />
      <CreateLocationFormModal
        disclosure={createDisclosure}
        qrCategories={qrCategories}
      />
    </Fragment>
  )
}

export default withApp(LocationsPages)
