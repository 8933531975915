import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { MESSAGES } from '../constants'
import { MessageFormInput } from '../types'
import { transformMessageInput } from '../utils'

const createMessage = async (form: MessageFormInput) => {
  const formData = transformMessageInput(form)

  await fetchAPI({
    method: 'post',
    path: '/messages',
    data: formData,
  })
}

export const useCreateMessage = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createMessage, {
    onSuccess: async () => {
      await queryClient.refetchQueries([MESSAGES])

      toast({
        description: t('views.admin.messages.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
