import React, { Fragment } from 'react'
import { Box, Button, Flex } from '@chakra-ui/react'
import { useQueryClient } from 'react-query'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Post } from '@/features/posts/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { PostTable } from './PostTable'

import { POSTS } from '@/features/posts/constants'

import { useMeta } from '@/features/filters'
import { usePosts } from '@/features/posts/queries'
import { t } from '@/lib/helpers'

type Props = {
  posts: Post[]
  meta: Meta
}

function PostsPage(props: Props) {
  const { posts: initialPosts, meta: initialMeta } = props

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: posts } = usePosts({
    initialData: { data: initialPosts, meta: initialMeta },
    params: meta,
  })

  const queryClient = useQueryClient()

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.posts.index.title')}
          addons={
            <Button
              onClick={() => {
                queryClient.refetchQueries([POSTS])
              }}
              sx={{ h: '48px', minW: '100px' }}
            >
              {t('views.admin.posts.index.refresh')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <PostTable data={posts.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>
    </Fragment>
  )
}

export default withApp(PostsPage)
