import React, { useMemo } from 'react'
import { Select, Box } from '@chakra-ui/react'

import { DataTable } from '@/components/shared/Table'

import { t } from '@/lib/helpers'
import { CalendarSettingsT } from '@/features/calendars/types'

type Props = {
  data?: CalendarSettingsT[]
  onChange: (data: CalendarSettingsT[]) => void
  isLoading: boolean
}

export function CityCalendarSettingTable({ data, onChange, isLoading }: Props) {
  const options = useMemo(() => {
    if (data) {
      return data.map((_item, index) => index + 1)
    }
    return []
  }, [data])

  const onSelectOrder = (ordering: number, row: CalendarSettingsT) => {
    const newData = data
    const duplicateIndex = newData.findIndex(
      (item) => item.ordering === ordering,
    )
    const replaceIndex = newData.findIndex(
      (item) => item.garbage_name === row.garbage_name,
    )

    if (duplicateIndex !== -1) {
      const prev = newData[duplicateIndex].garbage_name
      newData[duplicateIndex].garbage_name = newData[replaceIndex].garbage_name
      newData[replaceIndex].garbage_name = prev
    } else {
      newData[replaceIndex].ordering = ordering
    }

    onChange([...newData])
  }

  const columns = useMemo(() => {
    return [
      {
        Header: t('views.admin.settings.index.order'),
        accessor: 'ordering',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = cellProps.row.values
          return (
            <Box
              key={row.id}
              sx={{
                '.chakra-select__wrapper': {
                  w: '100px',
                },
              }}
            >
              <Select
                size="md"
                sx={{
                  w: '100px',
                }}
                value={row.ordering ?? undefined}
                onChange={(e) => onSelectOrder(Number(e.target.value), row)}
              >
                {[undefined, ...options].map((item) => (
                  <option value={item} key={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </Box>
          )
        },
      },
      {
        Header: t('views.admin.settings.index.garbage_type'),
        accessor: 'garbage_name',
        disableSortBy: true,
      },
    ]
  }, [data])

  return <DataTable data={data} columns={columns} isLoading={isLoading} />
}
