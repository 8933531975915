import React, { Fragment, useMemo, useState } from 'react'
import { get } from 'lodash'
import { HStack, Button, useDisclosure } from '@chakra-ui/react'
import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'

import { EditRankingModal } from '@/features/post_rankings/components'
import { RankingResponse } from '@/features/post_rankings/types'
import { t } from '@/lib/helpers'
import { useDeletePostRanking } from '@/features/post_rankings/mutations'

type Props = {
  data: RankingResponse[]
}

export const PostRankingsTable = ({ data }: Props) => {
  const [formData, setFormData] = useState<RankingResponse>()
  const [editMode, setEditMode] = useState(false)
  const { mutate: deletePostRanking } = useDeletePostRanking()
  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t('views.admin.post_ranking.index.form.starts_at'),
        accessor: 'starts_at',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value,
      },
      {
        Header: t('views.admin.post_ranking.index.form.ends_at'),
        accessor: 'ends_at',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value,
      },
      {
        Header: t('views.admin.post_ranking.index.form.no1_point'),
        accessor: 'point_no1',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t('views.admin.post_ranking.index.form.no2_point'),
        accessor: 'point_no2',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t('views.admin.post_ranking.index.form.no3_point'),
        accessor: 'point_no3',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t('views.admin.post_ranking.index.form.no4_to_10_point'),
        accessor: 'point_no4_10',
        disableSortBy: true,
        Cell: (cellProps) => cellProps.value.toLocaleString(),
      },
      {
        Header: t('views.admin.post_ranking.index.action'),
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')

          return (
            <HStack spacing="2px">
              <Button
                as="a"
                size="sm"
                variant="outline"
                href={`/admin/post_rankings/${row.id}/export.csv`}
                mr={4}
                download
              >
                export csv
              </Button>
              <TableEditButton
                disabled={!row.editable}
                ariaLabel={'edit rankings'}
                onClick={() => {
                  setFormData(row)
                  setEditMode(true)
                  onOpen()
                }}
              />
              <TableTrashButton
                disabled={!row.deleteable}
                ariaLabel={'delete ranking'}
                onClick={() => {
                  if (confirm('Confirm Delete ?')) {
                    deletePostRanking(row.id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [t])

  return (
    <Fragment>
      <DataTable data={data.sort((a, b) => a.id - b.id)} columns={columns} />

      {editMode ? (
        <EditRankingModal
          disclosure={disclosure}
          initialData={formData}
          isLoading={false}
        />
      ) : (
        <></>
      )}
    </Fragment>
  )
}
