import React, { useMemo } from 'react'
import { Button, Grid, Input } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { formatDateTime, t } from '@/lib/helpers'
import { FormControl } from '@/features/forms/components'

import { PostRankingsFormInput } from '../types'

type Props = {
  defaultValues?: PostRankingsFormInput
  onSubmitForm: (data: PostRankingsFormInput) => void
  isEditMode?: boolean
}
export const PostRankingForm = ({
  defaultValues,

  isEditMode,
  onSubmitForm,
}: Props) => {
  const { register, handleSubmit, errors } = useForm<any>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const buttomText = useMemo(() => {
    return !isEditMode
      ? 'views.admin.post_ranking.index.form.create'
      : 'views.admin.forms.form.update'
  }, [isEditMode, t])

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid sx={{ gridTemplateColumns: 'repeat(1, 1fr)', gap: '32px' }}>
        <Grid sx={{ gridTemplateColumns: 'repeat(1, 1fr)', gap: '24px' }}>
          <FormControl
            id="starts_at"
            label={t('views.admin.post_ranking.index.form.starts_at')}
            isRequired
            errorMsg={errors.starts_at?.message}
          >
            <Input
              type="date"
              name="starts_at"
              ref={register}
              defaultValue={
                defaultValues &&
                formatDateTime(defaultValues.starts_at, 'YYYY-MM-DD')
              }
              tabIndex={4}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="ends_at"
            label={t('views.admin.post_ranking.index.form.ends_at')}
            isRequired
            errorMsg={errors.ends_at?.message}
          >
            <Input
              type="date"
              name="ends_at"
              ref={register}
              defaultValue={
                defaultValues &&
                formatDateTime(defaultValues.ends_at, 'YYYY-MM-DD')
              }
              tabIndex={5}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="point_no1"
            label={t('views.admin.post_ranking.index.form.no1_point')}
            isRequired
            errorMsg={errors.point_no1?.message}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.post_ranking.index.form.no1_point')}
              ref={register}
              defaultValue={defaultValues.point_no1}
              name="point_no1"
              tabIndex={1}
            />
          </FormControl>

          <FormControl
            id="point_no2"
            label={t('views.admin.post_ranking.index.form.no2_point')}
            isRequired
            errorMsg={errors.point_no2?.message}
          >
            <Input
              type="number"
              name="point_no2"
              size="lg"
              defaultValue={defaultValues.point_no2}
              placeholder={t('views.admin.post_ranking.index.form.no2_point')}
              ref={register}
            />
          </FormControl>
          <FormControl
            id="point_no3"
            label={t('views.admin.post_ranking.index.form.no3_point')}
            isRequired
            errorMsg={errors.point_no3?.message}
          >
            <Input
              type="number"
              name="point_no3"
              size="lg"
              defaultValue={defaultValues.point_no3}
              placeholder={t('views.admin.post_ranking.index.form.no3_point')}
              ref={register}
            />
          </FormControl>
          <FormControl
            id="point_no4_10"
            label={t('views.admin.post_ranking.index.form.no4_to_10_point')}
            isRequired
            errorMsg={errors.point_no4_10?.message}
          >
            <Input
              type="number"
              name="point_no4_10"
              size="lg"
              defaultValue={defaultValues.point_no4_10}
              placeholder={t(
                'views.admin.post_ranking.index.form.no4_to_10_point',
              )}
              ref={register}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        tabIndex={6}
      >
        {t(`${buttomText}`)}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  starts_at: yup.date().required(t('views.shared.form.required')),
  ends_at: yup.date().required(t('views.shared.form.required')),
  point_no1: yup.number().positive().integer().min(0),
  point_no2: yup.number().positive().integer().min(0),
  point_no3: yup.number().positive().integer().min(0),
  point_no4_10: yup.number().positive().integer().min(0),
})
