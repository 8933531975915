import React, { Fragment } from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Campaign, Submission } from '@/features/campaigns/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { CampaignSubmissionTable } from './CampaignSubmissionTable'

import { useSubmissions } from '@/features/campaigns/queries'
import { useMeta } from '@/features/filters'

type Props = {
  campaign: Campaign
  submissions: Submission[]
  meta: Meta
}

function SubmissionsPage(props: Props) {
  const { submissions: initialSubmissions, meta: initialMeta, campaign } = props

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: submissions } = useSubmissions({
    campaign,
    initialData: { data: initialSubmissions, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader title={campaign.title} />

        <Box sx={{ mb: '24px' }}>
          <CampaignSubmissionTable
            campaignId={campaign.id}
            type={campaign.campaign_type}
            data={submissions.data}
          />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>
    </Fragment>
  )
}

export default withApp(SubmissionsPage)
