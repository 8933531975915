import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { get } from 'lodash'

import { t } from '@/lib/helpers'
import { withApp } from '@/features/Layout'
import { CityLayout } from '@/features/cities/components'
import {
  CalendarBySiteItem,
  CalendarSettingsT,
} from '@/features/calendars/types'
import { City, CollectionSite } from '@/features/cities/types'
import { useCalendarSettings } from '@/features/calendars/queries/getCalendarSettings'
import { useUpdateCalendarSettings } from '@/features/calendars/mutations/updateCalendarSettings'

import { CityCalendarSettingTable } from './CityCalendarSettingTable'

type Props = {
  city: City
  collection_site: CollectionSite
  calendars: CalendarBySiteItem[]
}

function CityCalendarSettingPage(props: Props) {
  const { city, collection_site } = props

  const { data: settings } = useCalendarSettings({
    city_id: city.id,
    collection_site_id: collection_site.id,
  })
  const { mutate: updateCalendarSettings, isLoading } =
    useUpdateCalendarSettings()
  const [settingData, setSettingData] = useState<CalendarSettingsT[]>()

  const onSubmit = async () => {
    await updateCalendarSettings({
      city_id: city.id,
      collection_site_id: collection_site.id,
      garbages: settingData,
    })
  }

  useEffect(() => {
    setSettingData(settings)
  }, [settings])
  return (
    <CityLayout
      title={t('views.admin.settings.index.title')}
      hideAddon={true}
      onSubmitSettings={onSubmit}
      breadcrumbData={[
        {
          title: t('views.admin.cities.collection_sites.index.cities'),
          link: '/admin/cities',
        },
        {
          title: get(city, 'name'),
          link: `/admin/cities/${city.id}/collection_sites`,
        },
        {
          title: t('views.admin.cities.index.collection_site'),
        },
        {
          title: collection_site.name,
          link: `/admin/cities/${city.id}/collection_sites/${collection_site.order_number}/calendars`,
        },
        {
          title: t('views.admin.settings.index.title'),
        },
      ]}
    >
      <Box sx={{ px: '40px' }}>
        <CityCalendarSettingTable
          data={settingData ?? []}
          onChange={setSettingData}
          isLoading={isLoading}
        />
      </Box>
    </CityLayout>
  )
}
export default withApp(CityCalendarSettingPage)
