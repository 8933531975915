import React, { Fragment } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Message } from '@/features/messages/types'
import { CityCollection } from '@/features/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { MessageTable } from './MessageTable'
import { CreateMessageModal } from '@/features/messages/components'

import { useMessages } from '@/features/messages/queries'
import { useMeta } from '@/features/filters'
import { t } from '@/lib/helpers'

type Props = {
  messages: Message[]
  cities: CityCollection
  meta: Meta
}

function MessagesPage(props: Props) {
  const { messages: initialMessages, cities, meta: initialMeta } = props

  const disclosure = useDisclosure()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: messages } = useMessages({
    initialData: { data: initialMessages, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.messages.index.title')}
          addons={
            <Button
              onClick={disclosure.onOpen}
              sx={{ h: '48px', minW: '100px' }}
            >
              {t('views.admin.messages.index.new')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <MessageTable data={messages.data} cities={cities} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <CreateMessageModal disclosure={disclosure} cities={cities} />
    </Fragment>
  )
}

export default withApp(MessagesPage)
