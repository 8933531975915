import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { formatDateTime, t } from '@/lib/helpers'

import { VISIBLE_ECOS } from '../constants'
import { getErrorMessages } from '../utils'
import { VisibleEcosFormInput } from '../types'

const createVisibleEcos = async (form: VisibleEcosFormInput) => {
  await fetchAPI({
    method: 'post',
    path: `/visible_ecos`,
    data: {
      ...form,
      ends_at: formatDateTime(form.ends_at, 'YYYY-MM-DD'),
      starts_at: formatDateTime(form.starts_at, 'YYYY-MM-DD'),
    },
  })
}

export const useCreateVisibleEcos = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createVisibleEcos, {
    onSuccess: async () => {
      await queryClient.refetchQueries([VISIBLE_ECOS])

      toast({
        description: t('views.admin.messages.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMessages(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
