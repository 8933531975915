import { Meta } from '@/features/filters/types'
import { fetchAPI } from '@/lib/api'
import { AxiosError } from 'axios'
import { get } from 'lodash'
import { useQuery } from 'react-query'
import { POST_RANKINGS } from '../constants'
import { RankingResponse } from '../types'

type PostRankingsType = {
  data: RankingResponse[]
  meta: Meta
}

const getPostRanking = async (params: Meta): Promise<PostRankingsType> => {
  const response = await fetchAPI({
    path: `/post_rankings`,
    params,
  })

  const data = get(response, 'data.post_rankings', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useGetPostRanking = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: PostRankingsType
}) => {
  return useQuery<PostRankingsType, AxiosError>({
    queryKey: [POST_RANKINGS, params],
    queryFn: () => getPostRanking(params),
    keepPreviousData: true,
    initialData,
  })
}
