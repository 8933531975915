export const ADMINS = 'admins'
export const ADMIN_LEVEL = {
  PENDING: 'pending',
  ADMIN: 'admin',
  SYSTEM_ADMIN: 'system_admin',
}
export const ADMIN_LEVELS = [
  ADMIN_LEVEL.PENDING,
  ADMIN_LEVEL.ADMIN,
  ADMIN_LEVEL.SYSTEM_ADMIN,
]
