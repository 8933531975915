import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { CITY_COLLECTION_SITES } from '../constants'
import { CollectionSiteFormInput } from '../types'
import { transformCollectionSiteInput } from '../utils'

const updateCityCollectionSite = async ({
  form,
  city_id,
  id,
}: {
  form: CollectionSiteFormInput
  city_id: number
  id: number
}) => {
  const data = transformCollectionSiteInput(form)

  await fetchAPI({
    method: 'put',
    path: `/cities/${city_id}/collection_sites/${id}`,
    data,
  })
}

export const useUpdateCityCollectionSite = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateCityCollectionSite, {
    onSuccess: async () => {
      await queryClient.refetchQueries([CITY_COLLECTION_SITES])

      toast({
        description: t('views.admin.challenges.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
