import { EditorState } from 'draft-js'

export enum ArticleStatus {
  Draft = 'draft',
  Published = 'published',
  Hide = 'hide',
}

export type Article = {
  id: number
  title: string
  content: string
  detail?: string
  provider?: string
  status: ArticleStatus
  cover_image_url: string
  created_at: string
  published_at: string
  updated_at: string
}

export type ArticleFormInput = {
  title: string
  detail?: string
  provider?: string
  cover_image: File | string
  content: EditorState | null
}
