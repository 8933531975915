import React, { useState } from 'react'
import { get } from 'lodash'
import { Box } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { withApp } from '@/features/Layout'
import { CityLayout } from '@/features/cities/components'

import { City } from '@/features/cities/types'
import { CalendarBySiteItem } from '@/features/calendars/types'

import { Calendar } from '@/features/calendars/components'

import { useCalendarBySite } from '@/features/calendars/queries'
import { getPathParams } from '@/lib/routes'
import { t } from '@/lib/helpers'

type Props = {
  city: City
  collection_site_name: string
  calendars: CalendarBySiteItem[]
}

function CityCollectionSiteCalendarPage(props: Props) {
  const { city, collection_site_name, calendars: initialCalendars } = props

  const query = getPathParams()
  const [curMonth, setMonth] = useState(dayjs())

  const { data: calendars } = useCalendarBySite({
    initialData: { data: initialCalendars },
    params: {
      start_date: curMonth.format('YYYY-MM-DD'),
      city_id: get(query, 'city_id'),
      collection_site_id: get(query, 'collection_site_id'),
    },
  })

  return (
    <CityLayout
      title={'Calendar'}
      isDisplySetting={true}
      breadcrumbData={[
        {
          title: t('views.admin.cities.collection_sites.index.cities'),
          link: '/admin/cities',
        },
        {
          title: get(city, 'name'),
          link: `/admin/cities/${city.id}/collection_sites`,
        },
        {
          title: t('views.admin.cities.index.collection_site'),
        },
        {
          title: collection_site_name,
        },
      ]}
    >
      <Box sx={{ px: '40px' }}>
        <Calendar
          data={calendars.data}
          curMonth={curMonth}
          setMonth={setMonth}
        />
      </Box>
    </CityLayout>
  )
}

export default withApp(CityCollectionSiteCalendarPage)
