import React from 'react'
import { Icon, IconButton, SystemStyleObject, Tooltip } from '@chakra-ui/react'
import {
  FiEdit,
  FiTrash2,
  FiEyeOff,
  FiEye,
  FiFeather,
  FiArchive,
  FiCheckCircle,
  FiSlash,
  FiFile,
} from 'react-icons/fi'
import { t } from '@/lib/helpers'

type TableButtonProps = {
  onClick: () => void
  ariaLabel: string
  sx?: SystemStyleObject
  disabled?: boolean
}

export const TableTrashButton = ({
  onClick,
  ariaLabel,
  disabled,
  sx,
}: TableButtonProps) => {
  return (
    <Tooltip label={t('views.admin.shared.table.delete')}>
      <IconButton
        disabled={disabled}
        aria-label={ariaLabel}
        variant="ghost"
        colorScheme="red"
        icon={
          <Icon as={FiTrash2} sx={{ color: 'red.400', fontSize: '18px' }} />
        }
        sx={{ minW: '30px', h: '30px', ...sx }}
        onClick={() => {
          onClick()
        }}
      />
    </Tooltip>
  )
}

export const TableFileButton = ({
  onClick,
  ariaLabel,
  sx,
}: TableButtonProps) => {
  return (
    <IconButton
      aria-label={ariaLabel}
      variant="ghost"
      colorScheme="red"
      icon={<Icon as={FiFile} sx={{ color: 'gray.400', fontSize: '18px' }} />}
      sx={{ minW: '30px', h: '30px', _hover: { bg: 'gray.50' }, ...sx }}
      onClick={() => {
        onClick()
      }}
    />
  )
}

export const TableEditButton = ({
  onClick,
  ariaLabel,
  sx,
  disabled,
}: TableButtonProps) => {
  return (
    <Tooltip label={t('views.admin.shared.table.edit')}>
      <IconButton
        disabled={disabled}
        aria-label={ariaLabel}
        variant="ghost"
        icon={<Icon as={FiEdit} sx={{ color: 'gray.400', fontSize: '18px' }} />}
        sx={{ minW: '30px', h: '30px', _hover: { bg: 'gray.50' }, ...sx }}
        onClick={() => {
          onClick()
        }}
      />
    </Tooltip>
  )
}

type HideButtonProps = TableButtonProps & {
  isHide: boolean
}

export const TableHideButton = ({
  onClick,
  ariaLabel,
  sx,
  isHide,
}: HideButtonProps) => {
  return (
    <IconButton
      aria-label={ariaLabel}
      title={isHide ? 'Unhide' : 'Hide'}
      variant="ghost"
      icon={
        <Icon
          as={isHide ? FiEye : FiEyeOff}
          sx={{ color: 'gray.400', fontSize: '18px' }}
        />
      }
      sx={{ minW: '30px', h: '30px', _hover: { bg: 'gray.50' }, ...sx }}
      onClick={() => {
        onClick()
      }}
    />
  )
}

export const TablePublishButton = ({
  onClick,
  ariaLabel,
  sx,
  isPublished,
}: TableButtonProps & {
  isPublished: boolean
}) => {
  return (
    <IconButton
      aria-label={ariaLabel}
      title={isPublished ? 'Hide' : 'Publish'}
      icon={
        <Icon
          as={isPublished ? FiArchive : FiFeather}
          sx={{
            fontSize: '18px',
            color: isPublished && 'gray.400',
          }}
        />
      }
      variant="ghost"
      colorScheme={isPublished ? undefined : 'brand'}
      sx={{
        minW: '30px',
        h: '30px',
        ...(isPublished && { _hover: { bg: 'gray.50' } }),
        ...sx,
      }}
      onClick={() => {
        onClick()
      }}
    />
  )
}

export const TableToggleApproveButton = ({
  onClick,
  ariaLabel,
  sx,
  isOn,
  title,
}: TableButtonProps & { isOn: boolean; title: string }) => {
  return (
    <IconButton
      aria-label={ariaLabel}
      title={title}
      icon={
        <Icon
          as={isOn ? FiSlash : FiCheckCircle}
          sx={{ fontSize: '18px', color: isOn && 'red.400' }}
        />
      }
      variant="ghost"
      colorScheme={isOn ? 'red' : 'brand'}
      sx={{ minW: '30px', h: '30px', ...sx }}
      onClick={() => {
        onClick()
      }}
    />
  )
}
