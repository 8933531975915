import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Button, Input, Checkbox, VStack, HStack } from '@chakra-ui/react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormControl } from '@/features/forms/components'

import { ReactSelect } from '@/components/shared/ReactSelect'

import { t } from '@/lib/helpers'

import { SelectMarkerColor } from '../components'
import { QRCodeCategoryFormInput } from '../types'

type Props = {
  defaultValues?: QRCodeCategoryFormInput
  onSubmitForm: (data: QRCodeCategoryFormInput) => void
  isLoading: boolean
  isEditing: boolean
}

const qrcodeTypeOptions = [
  { value: 'location', label: 'Location' },
  { value: 'url', label: 'URL' },
]

export const QRCategoryForm = (props: Props) => {
  const { defaultValues, onSubmitForm, isLoading, isEditing } = props
  const [qrCodeType, setQRCodeType] = useState(() => {
    return qrcodeTypeOptions[defaultValues?.url ? 1 : 0]
  })
  const {
    register,
    control,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<QRCodeCategoryFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: QRCodeCategoryFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="20px">
        <FormControl
          id="name"
          label={t('views.admin.qrcode_categories.table.name')}
          errorMsg={errors.name?.message}
          isRequired
        >
          <Input size="lg" ref={register} name="name" autoFocus />
        </FormControl>

        <FormControl
          id="point"
          label={t('views.admin.qrcode_categories.table.point')}
          errorMsg={errors.point?.message}
          isRequired
        >
          <Input type="number" size="lg" ref={register} name="point" />
        </FormControl>

        <FormControl
          id="qrCodeType"
          label={t('views.admin.qrcode_categories.table.type')}
        >
          <ReactSelect
            options={qrcodeTypeOptions}
            onChange={(selectOption) => setQRCodeType(selectOption)}
            value={qrCodeType}
          />
        </FormControl>

        {qrCodeType.value === 'location' && (
          <Controller
            name="marker_hue"
            control={control}
            render={(field) => {
              const { onChange, value } = field
              return (
                <SelectMarkerColor
                  label={t('views.admin.qrcode_categories.table.marker_color')}
                  placeholder={t(
                    'views.admin.qrcode_categories.placeholder.marker_color',
                  )}
                  onChange={onChange}
                  value={value}
                />
              )
            }}
          />
        )}

        {qrCodeType.value === 'url' && (
          <FormControl
            id="url"
            label={t('views.admin.qrcode_categories.table.url')}
          >
            <Input size="lg" ref={register} name="url" placeholder="url" />
          </FormControl>
        )}

        <FormControl id="only_once">
          <HStack spacing="10px">
            <Checkbox
              ref={register}
              name="only_once"
              colorScheme="green"
              defaultIsChecked={defaultValues?.only_once}
              isDisabled={isEditing}
            >
              {t('views.admin.qrcode_categories.table.only_once')}
            </Checkbox>
            <small>({t('views.admin.qrcode_categories.table.only_once_remark')})</small>
          </HStack>
        </FormControl>
      </VStack>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        size="lg"
        isLoading={isSubmitting || isLoading}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.shared.form.create')
          : t('views.admin.shared.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  name: yup.string().required(t('views.admin.shared.form.required')),
  point: yup.string().required(t('views.admin.shared.form.required')),
  type: yup.string(),
  marker_hue: yup.string(),
  url: yup.string(),
  only_once: yup.bool(),
})
