import React from 'react'
import { Box, CircularProgress, Grid, Text } from '@chakra-ui/react'

type Props = {
  background?: string
}

export const LoadingOverlay = ({ background }: Props) => {
  return (
    <Box
      w="100%"
      h="100%"
      position="absolute"
      background={background || 'rgba(255,255,255, 0.7)'}
      zIndex="100"
      pt="200px"
    >
      <Grid justifyItems="center" gap={2}>
        <CircularProgress isIndeterminate color="brand.400" />

        <Text textAlign="center" fontSize="14px">
          Loading...
        </Text>
      </Grid>
    </Box>
  )
}
