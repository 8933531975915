import { useCallback, useState } from 'react'

import { Meta } from './types'

export const useMeta = (initialMeta: Meta) => {
  const [meta, setLocalMeta] = useState(initialMeta)

  const setMeta = useCallback((key: keyof Meta, value) => {
    setLocalMeta((curState) => ({
      ...curState,
      [key]: value,
      ...(key !== 'page' && { page: 1 }),
    }))
  }, [])

  const setMetas = useCallback((meta: Meta) => {
    setLocalMeta((curState) => {
      return { ...curState, ...meta }
    })
  }, [])

  return {
    meta,
    setMeta,
    setMetas,
  }
}
