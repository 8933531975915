import React, { useMemo } from 'react'
import { get } from 'lodash'
import { HStack, Image } from '@chakra-ui/react'

import { Post } from '@/features/posts/types'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableHideButton,
  TableToggleApproveButton,
  TableTrashButton,
} from '@/components/shared/Table'
import {
  useDeletePost,
  useUpdatePost,
  useUpdatePostApproval,
} from '@/features/posts/mutations'

type Props = {
  data: Post[]
}

export const PostTable = ({ data }: Props) => {
  const { mutate: deletePost } = useDeletePost()
  const { mutate: updatePost } = useUpdatePost()
  const { mutate: updatePostApproval } = useUpdatePostApproval()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.posts.index.image'),
        accessor: 'image',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')
          let img
          if (value) {
            img = <a href={value} target="_blank"><Image src={value} sx={{ boxSize: '80px' }} /></a>
          } else {
            img = null
          }

          return img
        },
      },
      {
        Header: t('views.admin.posts.index.user_name'),
        accessor: 'user.nickname',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.posts.index.message'),
        accessor: 'message',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.posts.index.tag'),
        accessor: 'tag',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.posts.index.posted_at'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original') as Post
          const { id, hide, approved } = row

          const hideText = hide ? 'unhide' : 'hide'
          const approveText = approved ? 'deny' : 'approve'

          return (
            <HStack spacing="2px">
              <TableHideButton
                ariaLabel={`${hideText} post`}
                onClick={() => {
                  if (confirm(t(`views.admin.posts.table.${hideText}_post`))) {
                    updatePost(id)
                  }
                }}
                isHide={hide}
              />
              <TableToggleApproveButton
                ariaLabel={`${approveText} post`}
                title={`${approveText} post`}
                onClick={() => {
                  if (
                    confirm(t(`views.admin.posts.table.${approveText}_post`))
                  ) {
                    updatePostApproval(id)
                  }
                }}
                isOn={approved}
              />
              <TableTrashButton
                ariaLabel={'delete post'}
                onClick={() => {
                  if (confirm(t('views.admin.posts.table.confirm_delete'))) {
                    deletePost(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return <DataTable data={memoizedData} columns={columns} />
}
