import React from 'react'
import { isEmpty, get } from 'lodash'
import { Box, Button, Flex, Grid, Input } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormControl } from '@/features/forms/components'

import { ArticleFormInput } from '../types'
import { SUPPORTED_FORMATS } from '@/features/constants'
import { BannerInput } from '@/components/shared/BannerInput'
import { EditorImage } from '@/components/shared/Editor'
import { t } from '@/lib/helpers'

type Props = {
  defaultValues?: ArticleFormInput
  onSubmitForm: (data: ArticleFormInput) => void
  isLoading: boolean
}

export const ArticleForm = (props: Props) => {
  const { defaultValues, onSubmitForm, isLoading } = props

  const {
    register,
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm<ArticleFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: ArticleFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gridTemplateColumns: '316px 1fr', gap: '32px' }}>
        <Grid
          id="PrizeLeft"
          sx={{
            gap: '24px 16px',
            alignContent: 'start',
          }}
        >
          <FormControl
            id="title"
            label={t('views.admin.articles.form.title')}
            isRequired
            errorMsg={errors.title?.message}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.articles.form.title')}
              ref={register}
              name="title"
              tabIndex={1}
            />
          </FormControl>

          <FormControl
            id="detail"
            label={t('views.admin.articles.form.description')}
          >
            <Input
              size="lg"
              placeholder={t(
                'views.admin.articles.form.description_placeholder',
              )}
              ref={register}
              name="detail"
              tabIndex={2}
            />
          </FormControl>

          <FormControl
            id="provider"
            label={t('views.admin.articles.form.provider')}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.articles.form.provider_placeholder')}
              ref={register}
              name="provider"
              tabIndex={3}
            />
          </FormControl>

          <Controller
            name="cover_image"
            defaultValue={null}
            control={control}
            render={({ onChange, value }) => {
              const error = get(errors, 'cover_image.message')

              return (
                <FormControl
                  id="cover_image"
                  label={'Banner Image'}
                  sx={{ gridColumn: '1/-1' }}
                  errorMsg={error}
                >
                  <BannerInput file={value} onChange={onChange} />
                </FormControl>
              )
            }}
          />
        </Grid>

        <Grid
          id="PrizeRight"
          sx={{
            gap: '24px 16px',
            alignContent: 'start',
            maxW: '100%',
            overflowX: 'auto',
            border: '1px solid',
            borderColor: 'gray.200',
            gridTemplateRows: '1fr',
            '.rdw-image-modal-upload-option-label': {
              overflow: 'hidden',
            },
            '.demo-wrapper, .rdw-editor-wrapper': {
              height: '100%',
            },
            '.demo-editor': {
              minH: '363px',
              maxH: 'calc(100% - 44px)',
              p: '0 16px 8px',
            },
          }}
        >
          <Box sx={{ h: '70vh' }}>
            <Controller
              name="content"
              defaultValue={null}
              control={control}
              render={({ onChange, value }) => {
                return (
                  <EditorImage
                    value={value}
                    handleChange={onChange}
                    initialState={get(defaultValues, 'content')}
                  />
                )
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Flex justify="flex-end" mt="32px">
        <Button
          sx={{ h: '54px', w: '116px' }}
          type="submit"
          isLoading={isSubmitting || isLoading}
        >
          {isEmpty(defaultValues)
            ? t('views.admin.articles.form.create')
            : t('views.admin.articles.form.update')}
        </Button>
      </Flex>
    </form>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(t('views.shared.form.required')),
  detail: yup.string(),
  cover_image: yup
    .mixed()
    .notRequired()
    .test(
      'fileType',
      t('views.admin.shared.form.file_not_support'),
      (value) => {
        if (typeof value === 'string') return true

        return value ? SUPPORTED_FORMATS.includes(value.type) : true
      },
    ),
})
