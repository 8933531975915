import React from 'react'
import { isEmpty, get } from 'lodash'
import { Button, Grid, Input, Select, Box } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import dayjs from 'dayjs'
import MultiSelect from 'react-multi-select-component'

import { FormControl, TimeSelect } from '@/features/forms/components'

import { formatDateTime, t } from '@/lib/helpers'
import { optionsToValues, valuesToOptions } from '@/features/filters/utils'
import { useCityOptions } from '@/lib/hooks/useCityOptions'

import { MessageFormInput } from '../types'
import { CityCollection } from '@/features/types'
import { BannerInput } from '@/components/shared/BannerInput'

import { CONTENT_TYPE } from '../constants'
import { SUPPORTED_FORMATS } from '@/features/constants'

type Props = {
  defaultValues?: MessageFormInput
  onSubmitForm: (data: MessageFormInput) => void
  cities: CityCollection
  isLoading: boolean
}

export const MessageForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, cities, isLoading } = props

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<MessageFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const onSubmit = (data: MessageFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  const cityOptions = useCityOptions(cities)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gridTemplateColumns: 'repeat(2, 1fr)', gap: '32px' }}>
        <Grid sx={{ gridTemplateColumns: 'repeat(5, 1fr)', gap: '24px 16px' }}>
          <FormControl
            id="title"
            label={t('views.admin.messages.form.title')}
            isRequired
            sx={{ gridColumn: '1/-1' }}
            errorMsg={errors.title?.message}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.messages.form.title')}
              ref={register}
              name="title"
              tabIndex={1}
            />
          </FormControl>

          <FormControl
            id="link"
            label={t('views.admin.messages.form.link')}
            isRequired
            sx={{ gridColumn: '1/-1' }}
            errorMsg={errors.link?.message}
          >
            <Input
              name="link"
              size="lg"
              placeholder={'URL'}
              ref={register}
              tabIndex={3}
            />
          </FormControl>

          <Controller
            name="city_ids"
            defaultValue={[]}
            control={control}
            render={({ onChange, value }) => {
              return (
                <FormControl
                  id="city_ids"
                  label={t('views.admin.messages.form.city')}
                  isRequired
                  sx={{
                    gridColumn: '1/4',
                    fontSize: '16px',
                    ...overrideSelectStyle,
                  }}
                >
                  <MultiSelect
                    options={cityOptions}
                    labelledBy={'Select'}
                    value={valuesToOptions<number>(value, cityOptions)}
                    onChange={(options) => {
                      onChange(optionsToValues(options))
                    }}
                  />
                </FormControl>
              )
            }}
          />

          <FormControl
            id="content_type"
            label={t('views.admin.messages.form.type')}
            isRequired
            sx={{ gridColumn: '4/-1' }}
            errorMsg={errors.content_type?.message}
          >
            <Select
              name="content_type"
              size="lg"
              ref={register}
              tabIndex={5}
              sx={{ textTransform: 'capitalize', fontSize: '16px' }}
            >
              {CONTENT_TYPE.map((t) => (
                <option value={t} key={t}>
                  {t}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl
            id="startsAt"
            label={t('views.admin.messages.form.released_at')}
            isRequired
            sx={{ gridColumn: '1/4' }}
            errorMsg={errors.startsAt?.message}
          >
            <Input
              type="date"
              name="startsAt"
              ref={register}
              defaultValue={formatDateTime(new Date().toString(), 'YYYY-MM-DD')}
              tabIndex={6}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="startsAtHr"
            label={t('views.admin.messages.form.time')}
            isRequired
          >
            <TimeSelect innerRef={register} name={'startsAtHr'} tabIndex={6} />
          </FormControl>

          <FormControl id="startsAtMin" sx={{ mt: 'auto' }}>
            <TimeSelect
              isMin={true}
              innerRef={register}
              name={'startsAtMin'}
              tabIndex={7}
            />
          </FormControl>

          <FormControl
            id="endsAt"
            label={t('views.admin.messages.form.ended_at')}
            isRequired
            sx={{ gridColumn: '1/4' }}
            errorMsg={errors.endsAt?.message}
          >
            <Input
              type="date"
              name="endsAt"
              ref={register}
              defaultValue={formatDateTime(
                dayjs().add(7, 'day').toString(),
                'YYYY-MM-DD',
              )}
              tabIndex={8}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="endsAtHr"
            label={t('views.admin.messages.form.time')}
            isRequired
          >
            <TimeSelect innerRef={register} name={'endsAtHr'} tabIndex={9} />
          </FormControl>

          <FormControl id="endsAtMin" sx={{ mt: 'auto' }}>
            <TimeSelect
              isMin={true}
              innerRef={register}
              name={'endsAtMin'}
              tabIndex={10}
            />
          </FormControl>
        </Grid>

        <Box>
          <Controller
            name="image"
            defaultValue={null}
            control={control}
            render={({ onChange, value }) => {
              const error = get(errors, 'image.message')

              return (
                <FormControl
                  id="image"
                  label={t('views.admin.messages.form.banner_image')}
                  sx={{ gridColumn: '1/-1' }}
                  errorMsg={error}
                >
                  <BannerInput file={value} onChange={onChange} />
                </FormControl>
              )
            }}
          />
        </Box>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        isLoading={isSubmitting || isLoading}
        tabIndex={11}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.messages.form.create')
          : t('views.admin.messages.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(t('views.shared.form.required')),
  link: yup
    .string()
    .url('Invalid URL')
    .required(t('views.shared.form.required')),
  city_ids: yup.array().of(yup.number()),
  content_type: yup.string().oneOf(CONTENT_TYPE),
  startsAt: yup.date().required(t('views.shared.form.required')),
  startsAtHr: yup.number().positive().integer().min(0).max(23),
  startsAtMin: yup.number().positive().integer().min(0).max(59),
  endsAt: yup.date().required(t('views.shared.form.required')),
  endsAtHr: yup.number().positive().integer().min(0).max(23),
  endsAtMin: yup.number().positive().integer().min(0).max(59),
  image: yup
    .mixed()
    .notRequired()
    .test('fileType', t('views.shared.form.file_not_support'), (value) => {
      if (typeof value === 'string') return true

      return value ? SUPPORTED_FORMATS.includes(value.type) : true
    }),
})

const overrideSelectStyle = {
  '.multi-select': {
    '--rmsc-main': '#3182CE',
    '--rmsc-border': '#dcdcdc',
    '--rmsc-radius': '8px',
    '--rmsc-h': '48px',
  },
}
