import { SelectOptions } from '@/components/shared/ReactSelect/types'
import { t } from '@/lib/helpers'
import { QuestionType } from './types'

export const QUESTION_TYPE_OPTIONS: SelectOptions<QuestionType> = [
  {
    label: t('views.admin.forms.index.multiple_choice'),
    value: 'multiple_choice',
  },
  {
    label: t('views.admin.forms.index.checkboxes'),
    value: 'checkboxes',
  },
  {
    label: t('views.admin.forms.index.answer_text'),
    value: 'answer_text',
  },
]

export const MAX = {
  QUESTION: 50,
  OPTION: 15,
}

export const LINK_FORMS = 'link_forms'
