import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { LINK_FORMS } from '../constants'
import { LinkFormInput } from '../types'
import { transformLinkFormInput } from '../utils'

const createLinkForm = async (form: LinkFormInput) => {
  await fetchAPI({
    method: 'post',
    path: '/forms',
    data: transformLinkFormInput(form),
  })
}

export const useCreateLinkForm = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createLinkForm, {
    onSuccess: async () => {
      await queryClient.refetchQueries([LINK_FORMS])

      toast({
        description: t('views.admin.forms.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
