import React, { Fragment, ReactNode } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader } from '@/features/Layout'

import { UploadXlsxModal } from '@/components/shared/UploadXlsxModal'
import { Breadcrumb, BreadcrumbItem } from '@/components/shared/Breadcrumb'

import { useUploadCity } from '@/features/cities/mutations'
import { t } from '@/lib/helpers'

type Props = {
  children: ReactNode
  title: string
  breadcrumbData?: BreadcrumbItem[]
  isDisplySetting?: boolean
  hideAddon?: boolean
  onSubmitSettings?: () => void
}

export const CityLayout = ({
  children,
  title,
  breadcrumbData = [],
  isDisplySetting = false,
  hideAddon = false,
  onSubmitSettings,
}: Props) => {
  const { onOpen, isOpen, onClose } = useDisclosure()

  const { mutateAsync: uploadCity, isLoading } = useUploadCity()

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={title}
          addons={
            hideAddon ? (
              onSubmitSettings ? (
                <Button onClick={() => onSubmitSettings()} sx={{ h: '48px' }}>
                  {t('views.admin.settings.index.save')}
                </Button>
              ) : (
                <></>
              )
            ) : (
              <Flex sx={{ flexDir: 'row', gap: '16px' }}>
                {isDisplySetting && (
                  <Button
                    onClick={() =>
                      (window.location.href =
                        window.location.pathname + '/settings')
                    }
                    sx={{ h: '48px' }}
                  >
                    {t('views.admin.settings.index.title')}
                  </Button>
                )}
                <Button onClick={onOpen} sx={{ h: '48px' }}>
                  {t('views.admin.shared.misc.upload')}
                </Button>
              </Flex>
            )
          }
          breadcrumb={<Breadcrumb data={breadcrumbData} />}
        />

        {children}
      </Box>

      <UploadXlsxModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={({ file }) => uploadCity({ file }, { onSuccess: onClose })}
        isLoading={isLoading}
      />
    </Fragment>
  )
}
