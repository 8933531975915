import React, { Fragment } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Faq } from '@/features/faqs/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'

import { useMeta } from '@/features/filters'
import { useFaqs } from '@/features/faqs/queries'
import { useUploadFaq } from '@/features/faqs/mutations'
import { t } from '@/lib/helpers'

import { FaqTable } from './FaqTable'
import { UploadXlsxModal } from '@/components/shared/UploadXlsxModal'

type Props = {
  faqs: Faq[]
  meta: Meta
}

function FaqsPage(props: Props) {
  const { faqs: initialFaqs, meta: initialMeta } = props

  const { mutateAsync: uploadFaq, isLoading } = useUploadFaq()

  const { onOpen, onClose, isOpen } = useDisclosure()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: faqs } = useFaqs({
    initialData: { data: initialFaqs, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.faqs.index.title')}
          addons={
            <Button onClick={onOpen} sx={{ h: '48px', w: '100px' }}>
              {t('views.admin.shared.misc.upload')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <FaqTable data={faqs.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <UploadXlsxModal
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={({ file }) => uploadFaq({ file }, { onSuccess: onClose })}
        isLoading={isLoading}
      />
    </Fragment>
  )
}

export default withApp(FaqsPage)
