import React from 'react'
import { isEmpty } from 'lodash'
import { Grid, Box, Text, Link } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ThemeProvider } from '@/lib/styles'

import authBackground from '@/images/auth-bg@1.png'
import Logo from '@/images/logo.svg'

type LinkProps = {
  title: string
  href: string
}

type OptionsProps = {
  title: string
  leftLink?: LinkProps
  rightLink?: LinkProps
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const withAuth = (Component, options: OptionsProps) => (props) => {
  const { title, leftLink, rightLink } = options

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Grid
          sx={{
            gridTemplateColumns: '47fr 53fr',
            minH: '100vh',
            gap: '108px',
            justifyItems: 'center',
          }}
        >
          <Grid
            sx={{
              alignSelf: 'center',
              justifySelf: 'right',
              w: '460px',
              gap: '16px',
              transform: 'translateY(-20%)',
            }}
          >
            <Box
              sx={{
                boxSize: '108px',
                background: `no-repeat center/contain url(${Logo})`,
              }}
            />
            <Text as="h1" sx={{ fontWeight: 600, fontSize: '32px' }}>
              {title}
            </Text>

            <Box sx={{ mb: '8px' }}>
              <Component {...props} />
            </Box>

            <Grid
              sx={{
                gridTemplateColumns: 'repeat(2, max-content)',
                justifyContent: 'space-between',
                fontSize: '16px',
                color: 'brand.500',
              }}
            >
              <Box>
                {!isEmpty(leftLink) && (
                  <Link href={leftLink.href}>{leftLink.title}</Link>
                )}
              </Box>
              <Box>
                {!isEmpty(rightLink) && (
                  <Link href={rightLink.href}>{rightLink.title}</Link>
                )}
              </Box>
            </Grid>
          </Grid>

          <Box
            sx={{
              h: '100%',
              w: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundImage: `url(${authBackground})`,
            }}
          />
        </Grid>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
