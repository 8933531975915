import { useMemo } from 'react'

type Params = File | string | undefined

export const useMemoImage = (image: Params) => {
  return useMemo(() => {
    if (!image) return null

    if (typeof image === 'string') {
      return image
    }

    return URL.createObjectURL(image)
  }, [image])
}
