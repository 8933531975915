import axios, { AxiosRequestConfig, AxiosPromise } from 'axios'

type FetchParams = {
  url?: string
  prefix?: string
  path: string
} & Omit<AxiosRequestConfig, 'baseURL'>

axios.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      'X-CSRF-Token': document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content'),
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
})

function getBaseUrl({ prefix, path }) {
  const urlObject = {
    ...(prefix && { prefix }),
    ...(path && { path }),
  }

  return Object.values(urlObject).join('')
}

export function fetchAPI({
  path,
  prefix = '/admin',
  ...options
}: FetchParams): AxiosPromise {
  return axios({
    baseURL: getBaseUrl({ prefix, path }),
    ...options,
  })
}
