import { extendTheme } from '@chakra-ui/react'

import colors from './colors'
import components from './components'
import layerStyles from './layerStyles'
import textStyles from './textStyles'

const breakpoints = {
  xs: 0,
  sm: '600px',
  md: '900px',
  lg: '1200px',
  xl: '1536px',
}

const theme = extendTheme({
  colors,
  components,
  breakpoints,
  layerStyles,
  textStyles,
  styles: {
    global: {
      'html, body': {
        color: 'gray.900',
      },
      body: {
        fontSize: '14px',
      },
    },
  },
  fonts: {
    body: 'Noto Sans JP, system-ui, sans-serif',
    heading: 'Noto Sans JP, Georgia, serif',
    mono: 'Menlo, monospace',
  },
})

export default theme
