import React from 'react'
import { get } from 'lodash'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { QRCategoryForm } from './QRCategoryForm'

import { useUpdateQRCodeCategory } from '../mutations'
import { QRCodeCategory } from '../types'
import { transformQRCodeCategoryIntoForm } from '../utils'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
  initialData?: QRCodeCategory
}

export const EditQRCodeCategoryFormModal = (props: Props) => {
  const { disclosure, initialData } = props

  const { isOpen, onClose } = disclosure

  const { mutate: updateQRCategory, isLoading } = useUpdateQRCodeCategory()

  const isEditing: boolean = true

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" sx={{ mb: '20px' }} isTruncated>
          {t('views.admin.qrcode_categories.edit.title', {
            title: get(initialData, 'name'),
          })}
        </Text>

        <QRCategoryForm
          isLoading={isLoading}
          isEditing={isEditing}
          defaultValues={transformQRCodeCategoryIntoForm(initialData)}
          onSubmitForm={(data) => {
            updateQRCategory(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
