import React, { useMemo } from 'react'
import { get, startsWith } from 'lodash'

import { Calendar } from '@/features/calendars/types'

import { formatDateTime, t } from '@/lib/helpers'

import { DataTable } from '@/components/shared/Table'

type Props = {
  data: Calendar[]
  isLoading: boolean
}

export const CalendarTable = ({ data, isLoading }: Props) => {
  const columns = useMemo(() => {
    return [
      {
        Header: t('views.admin.calendars.index.garbage_name'),
        accessor: 'garbage_name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.calendars.index.city_name'),
        accessor: 'city_name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.calendars.index.collection_site'),
        accessor: 'collection_site_name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.calendars.index.updated_at'),
        accessor: 'updated_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.calendars.index.schedules_at'),
        accessor: 'garbaged_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'row.original.garbaged_time')

          return startsWith(value, 'D') ? value : formatDateTime(value)
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <DataTable data={memoizedData} columns={columns} isLoading={isLoading} />
  )
}
