import React, { Fragment, useMemo, useState } from 'react'
import { get, startCase } from 'lodash'
import { HStack, Image, useDisclosure } from '@chakra-ui/react'

import { Message } from '@/features/messages/types'
import { CityCollection } from '@/features/types'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { EditMessageModal } from '@/features/messages/components'
import { useDeleteMessage } from '@/features/messages/mutations'

type Props = {
  data: Message[]
  cities: CityCollection
}

export const MessageTable = ({ data, cities }: Props) => {
  const [actionData, setActionData] = useState<Message>()

  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const { mutate: deleteMessage } = useDeleteMessage()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.messages.index.message_title'),
        accessor: 'title',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.messages.index.image'),
        accessor: 'image_url',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return value ? <Image src={value} sx={{ boxSize: '80px' }} /> : '-'
        },
      },
      {
        Header: t('views.admin.messages.index.type'),
        accessor: 'content_type',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return startCase(value)
        },
      },
      {
        Header: t('views.admin.messages.index.city'),
        accessor: 'cities',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return value.toString()
        },
      },
      {
        Header: t('views.admin.messages.index.created_at'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.messages.index.release_date'),
        accessor: 'starts_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.messages.index.release_end_date'),
        accessor: 'ends_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.shared.table.edit_delete'),
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id } = row

          return (
            <HStack spacing="2px">
              <TableEditButton
                ariaLabel={'edit message'}
                onClick={() => {
                  setActionData(row)
                  onOpen()
                }}
              />
              <TableTrashButton
                ariaLabel={'delete message'}
                onClick={() => {
                  if (confirm('Confirm Delete ?')) {
                    deleteMessage(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      <EditMessageModal
        disclosure={disclosure}
        cities={cities}
        initialData={actionData}
      />
    </Fragment>
  )
}
