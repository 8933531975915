import React from 'react'
import { useTable, useSortBy } from 'react-table'

import { Table } from './Table'
import { useSort } from './hooks'
import { DataTableProps } from './types'

export const DataTable = <T extends {}>(props: DataTableProps<T>) => {
  const { columns, data, initialState, renderNoData, isLoading } = props

  const tableInstance = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState,
    },
    useSortBy,
  )

  useSort({
    ...props,
    tableInstance,
  })

  return (
    <Table
      isLoading={isLoading}
      renderNoData={renderNoData}
      tableInstance={tableInstance}
    />
  )
}
