import { fetchAPI } from '@/lib/api'
import { useMutation } from 'react-query'

const logout = async (): Promise<void> => {
  await fetchAPI({
    method: 'delete',
    prefix: '/admins',
    path: '/sign_out',
  })
}

export const useLogout = () => {
  return useMutation(logout, {
    onSettled() {
      window.location.href = '/admins/sign_in'
    },
  })
}
