import { Meta } from '@/features/filters/types'
import { fetchAPI } from '@/lib/api'
import { AxiosError } from 'axios'
import { get } from 'lodash'
import { useQuery } from 'react-query'
import { LINK_FORMS } from '../constants'
import { LinkForm } from '../types'

type LinkFormResponse = {
  data: LinkForm[]
  meta: Meta
}

const getLinkForms = async (params: Meta): Promise<LinkFormResponse> => {
  const response = await fetchAPI({
    path: `/forms`,
    params,
  })

  const data = get(response, 'data.forms', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useLinkForms = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: LinkFormResponse
}) => {
  return useQuery<LinkFormResponse, AxiosError>({
    queryKey: [LINK_FORMS, params],
    queryFn: () => getLinkForms(params),
    keepPreviousData: true,
    initialData,
  })
}
