import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { CollectionSite } from '../types'
import { Meta } from '@/features/filters/types'
import { CITY_COLLECTION_SITES } from '../constants'

type CityCollectionSitesResponse = {
  data: CollectionSite[]
  meta: Meta
}

type Params = {
  city_id: number
  meta: Meta
}

const getCityCollectionSites = async (
  params: Params,
): Promise<CityCollectionSitesResponse> => {
  const { city_id } = params

  const response = await fetchAPI({
    path: `/cities/${city_id}/collection_sites`,
    params: params.meta,
  })

  const data = get(response, 'data.collection_sites', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useCityCollectionSites = ({
  params,
  initialData,
}: {
  params: Params
  initialData: CityCollectionSitesResponse
}) => {
  return useQuery<CityCollectionSitesResponse, AxiosError>({
    queryKey: [CITY_COLLECTION_SITES, params],
    queryFn: () => getCityCollectionSites(params),
    keepPreviousData: true,
    initialData,
  })
}
