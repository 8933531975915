import { Modal } from '@/components/shared/Modal'
import { CityCollection, CityCollectionSiteCollection } from '@/features/types'
import { t } from '@/lib/helpers'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'
import React from 'react'
import { useCreateHoliday } from '../mutations'
import { HolidayForm } from './HolidayForm'

type Props = {
  disclosure: UseDisclosureProps
  cities: CityCollection
  sites: CityCollectionSiteCollection
}

export const CreateHolidayModal = (props: Props) => {
  const { disclosure, cities, sites } = props
  const { isOpen, onClose } = disclosure

  const { mutate: createHoliday, isLoading } = useCreateHoliday()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="480px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.holidays.new.title')}
        </Text>
        <HolidayForm
          isLoading={isLoading}
          onSubmitForm={(data) => {
            createHoliday(data, { onSuccess: onClose })
          }}
          cities={cities}
          sites={sites}
        />
      </Box>
    </Modal>
  )
}
