import React from 'react'
import { Button, Input } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { t } from '@/lib/helpers'

import { CollectionSiteFormInput } from '../types'
import { FormControl } from '@/features/forms/components'

type Props = {
  defaultValues?: CollectionSiteFormInput
  onSubmitForm: (data: CollectionSiteFormInput) => void
  isLoading: boolean
}

export const CollectionSiteForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, isLoading } = props

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<CollectionSiteFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: CollectionSiteFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        id="name"
        label={t('views.admin.cities.collection_sites.form.site_name')}
        errorMsg={errors.name?.message}
      >
        <Input size="lg" ref={register} name="name" />
      </FormControl>

      <FormControl
        id="order_number"
        label={t('views.admin.cities.collection_sites.form.order_number')}
        errorMsg={errors.order_number?.message}
        sx={{ mt: 4 }}
      >
        <Input type="number" size="lg" ref={register} name="order_number" />
      </FormControl>

      <Button
        type="submit"
        sx={{ w: '100%', mt: 8 }}
        size="lg"
        isLoading={isSubmitting || isLoading}
      >
        {t('views.admin.cities.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  name: yup.string(),
  order_number: yup.string(),
})
