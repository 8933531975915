import React, { Fragment, useMemo, useState } from 'react'
import { get } from 'lodash'
import { HStack, useDisclosure } from '@chakra-ui/react'

import { Tag } from '@/features/tags/types'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableEditButton,
  TableToggleApproveButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { EditTagFormModal } from '@/features/tags/components'
import { useDeleteTag } from '@/features/tags/mutations'
import { useUpdateTagShow } from '@/features/tags/mutations/updateTagShow'

type Props = {
  data: Tag[]
}

export const TagTable = ({ data }: Props) => {
  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const [actionData, setActionData] = useState<Tag>()
  const { mutateAsync: deleteTag } = useDeleteTag()
  const { mutate: updateTagShow } = useUpdateTagShow()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.tags.index.tag'),
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.tags.index.number_of_posts'),
        accessor: 'number_of_posts',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.tags.index.created_at'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.tags.index.show_on_search'),
        accessor: 'show_on_search',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return value ? t('views.admin.tags.index.show') : t('views.admin.tags.index.hide')
        }
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id, show_on_search } = row
          const toggleText = show_on_search ? 'hide' : 'show'

          return (
            <HStack spacing="2px">
              <TableEditButton
                ariaLabel={'edit'}
                onClick={() => {
                  setActionData(row)
                  onOpen()
                }}
              />
              <TableToggleApproveButton
                ariaLabel={`${toggleText} tag`}
                title={`${toggleText} tag`}
                onClick={() => {
                  if (
                    confirm(
                      t(`views.admin.tags.index.show_on_search_action.${toggleText}`),
                    )
                  ) {
                    updateTagShow(id)
                  }
                }}
                isOn={show_on_search}
              />
              <TableTrashButton
                ariaLabel={'delete'}
                onClick={() => {
                  if (
                    confirm(t('views.admin.challenges.table.confirm_delete'))
                  ) {
                    deleteTag(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      <EditTagFormModal disclosure={disclosure} initialData={actionData} />
    </Fragment>
  )
}
