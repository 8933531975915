import React, { Fragment, useMemo } from 'react'
import { get } from 'lodash'
import { HStack, Image } from '@chakra-ui/react'

import { Submission, CampaignType } from '@/features/campaigns/types'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableHideButton,
  TableToggleApproveButton,
  TableTrashButton,
} from '@/components/shared/Table'
import {
  useUpdateSubmissionHide,
  useUpdateSubmissionApproval,
  useDeleteSubmission,
} from '@/features/campaigns/mutations'

type Props = {
  data: Submission[]
  type: CampaignType
  campaignId: number
}

export const CampaignSubmissionTable = ({ campaignId, type, data }: Props) => {
  const { mutate: updateSubmissionHide } = useUpdateSubmissionHide()
  const { mutate: updateSubmissionApproval } = useUpdateSubmissionApproval()
  const { mutate: deleteSubmission } = useDeleteSubmission()

  const columns = useMemo(() => {
    switch (type) {
      case CampaignType.first_campaign:
        return [
          {
            Header: '#',
            accessor: 'id',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.name'),
            accessor: 'name',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.furigana'),
            accessor: 'furigana',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.postal_code'),
            accessor: 'postal_code',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.street_address'),
            accessor: 'street_address',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.phone_number'),
            accessor: 'phone_number',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.choice_answer'),
            accessor: 'choice_answer',
            disableSortBy: true,
            Cell: (cellProps) => {
              const value = get(cellProps, 'value')

              return value ? value.join(', ') : ''
            },
          },
          {
            Header: t('views.admin.campaigns.submission.persons_in_household'),
            accessor: 'persons_in_household',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.chosen_gift'),
            accessor: 'chosen_gift',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.electricity_usage'),
            accessor: 'electricity_usage',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.original_practice'),
            accessor: 'original_practice',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.created_at'),
            accessor: 'created_at',
            disableSortBy: true,
            Cell: (cellProps) => {
              const value = get(cellProps, 'value')

              return formatDateTime(value)
            },
          },
          {
            Header: '',
            accessor: 'action',
            disableSortBy: true,
            Cell: (cellProps) => {
              const row = get(cellProps, 'row.original') as Submission
              const { id } = row

              return (
                <TableTrashButton
                  ariaLabel={'delete submission'}
                  onClick={() => {
                    if (
                      confirm(t('views.admin.campaigns.table.confirm_delete'))
                    ) {
                      deleteSubmission({ campaignId, id })
                    }
                  }}
                />
              )
            },
          },
        ]
      case CampaignType.second_campaign:
        return [
          {
            Header: '#',
            accessor: 'id',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.name'),
            accessor: 'name',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.furigana'),
            accessor: 'furigana',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.postal_code'),
            accessor: 'postal_code',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.street_address'),
            accessor: 'street_address',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.phone_number'),
            accessor: 'phone_number',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.image'),
            accessor: 'image.url',
            disableSortBy: true,
            Cell: (cellProps) => {
              const value = get(cellProps, 'value')

              return value ? (
                <a href={value} target="_blank" rel="noopener noreferrer">
                  <Image src={value} width="80px" height="auto" />
                </a>
              ) : (
                '-'
              )
            },
          },
          {
            Header: t('views.admin.campaigns.submission.message'),
            accessor: 'message',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.created_at'),
            accessor: 'created_at',
            disableSortBy: true,
            Cell: (cellProps) => {
              const value = get(cellProps, 'value')

              return formatDateTime(value)
            },
          },
          {
            Header: '',
            accessor: 'action',
            disableSortBy: true,
            Cell: (cellProps) => {
              const row = get(cellProps, 'row.original') as Submission
              const { id, approved } = row

              const approveText = approved ? 'deny' : 'approve'

              return (
                <HStack spacing="2px">
                  <TableToggleApproveButton
                    ariaLabel={`${approveText}`}
                    title={`${approveText}`}
                    onClick={() => {
                      if (
                        confirm(
                          t(
                            `views.admin.campaigns.submission.table.${approveText}_post`,
                          ),
                        )
                      ) {
                        updateSubmissionApproval({ campaignId, id })
                      }
                    }}
                    isOn={approved}
                  />
                  <TableTrashButton
                    ariaLabel={'delete submission'}
                    onClick={() => {
                      if (
                        confirm(t('views.admin.campaigns.table.confirm_delete'))
                      ) {
                        deleteSubmission({ campaignId, id })
                      }
                    }}
                  />
                </HStack>
              )
            },
          },
        ]
      case CampaignType.third_campaign:
        return [
          {
            Header: '#',
            accessor: 'id',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.image'),
            accessor: 'image',
            disableSortBy: true,
            Cell: (cellProps) => {
              const value = get(cellProps, 'value')

              return value ? (
                <Image src={value} sx={{ boxSize: '80px' }} />
              ) : null
            },
          },
          {
            Header: t('views.admin.campaigns.submission.message'),
            accessor: 'message',
            disableSortBy: true,
          },
          {
            Header: t('views.admin.campaigns.submission.created_at'),
            accessor: 'created_at',
            disableSortBy: true,
            Cell: (cellProps) => {
              const value = get(cellProps, 'value')

              return formatDateTime(value)
            },
          },
          {
            Header: '',
            accessor: 'action',
            disableSortBy: true,
            Cell: (cellProps) => {
              const row = get(cellProps, 'row.original') as Submission
              const { id, hide, approved } = row

              const hideText = hide ? 'unhide' : 'hide'
              const approveText = approved ? 'deny' : 'approve'

              return (
                <HStack spacing="2px">
                  <TableHideButton
                    ariaLabel={`${hideText} post`}
                    onClick={() => {
                      if (
                        confirm(
                          t(
                            `views.admin.campaigns.submission.table.${hideText}_post`,
                          ),
                        )
                      ) {
                        updateSubmissionHide({ campaignId, id })
                      }
                    }}
                    isHide={hide}
                  />
                  <TableToggleApproveButton
                    ariaLabel={`${approveText} post`}
                    title={`${approveText} post`}
                    onClick={() => {
                      if (
                        confirm(
                          t(
                            `views.admin.campaigns.submission.table.${approveText}_post`,
                          ),
                        )
                      ) {
                        updateSubmissionApproval({ campaignId, id })
                      }
                    }}
                    isOn={approved}
                  />
                  <TableTrashButton
                    ariaLabel={'delete post'}
                    onClick={() => {
                      if (
                        confirm(t('views.admin.campaigns.table.confirm_delete'))
                      ) {
                        deleteSubmission({ campaignId, id })
                      }
                    }}
                  />
                </HStack>
              )
            },
          },
        ]
      default:
        return []
    }
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />
    </Fragment>
  )
}
