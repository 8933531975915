import React from 'react'
import { get } from 'lodash'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { t } from '@/lib/helpers'

import { VisibleEcosResponse } from '../types'
import { VisibleEcosForm } from './VisibleEcosForm'
import { useUpdateVisibleEcos } from '../mutations/updateVisibleEcos'

type Props = {
  disclosure: UseDisclosureProps
  initialData: VisibleEcosResponse
  isLoading: boolean
}

export const EditVisibleEcosModal = (props: Props) => {
  const { disclosure, initialData } = props
  const { isOpen, onClose } = disclosure
  const { mutate: updatePostRanking } = useUpdateVisibleEcos()
  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="480px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.forms.form.edit')}
        </Text>
        <VisibleEcosForm
          defaultValues={initialData}
          onSubmitForm={(data) => {
            updatePostRanking(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
          isEditMode={true}
        />
      </Box>
    </Modal>
  )
}
