import React, { Fragment } from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Campaign } from '@/features/campaigns/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { CampaignTable } from './CampaignTable'

import { useCampaigns } from '@/features/campaigns/queries'
import { useMeta } from '@/features/filters'
import { t } from '@/lib/helpers'

type Props = {
  campaigns: Campaign[]
  meta: Meta
}

function CampaignsPage(props: Props) {
  const { campaigns: initialCampaigns, meta: initialMeta } = props

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: campaigns } = useCampaigns({
    initialData: { data: initialCampaigns, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader title={t('views.admin.campaigns.index.title')} />

        <Box sx={{ mb: '24px' }}>
          <CampaignTable data={campaigns.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>
    </Fragment>
  )
}

export default withApp(CampaignsPage)
