import { useEffect } from 'react'
import { isEmpty, get, noop } from 'lodash'

import { UseSort } from '../types'

export function useSort<T>(props: UseSort<T>) {
  const { columns, onSort = noop, tableInstance } = props

  const {
    state: { sortBy },
  } = tableInstance

  const internalSort = (sortBy) => {
    if (isEmpty(sortBy)) {
      return onSort({
        sortKey: '',
        sortDirection: '',
      })
    }

    const { id, desc } = sortBy[0]
    const column = columns.find((c) => c.accessor === id)

    const sortKey = get(column, 'sortKey')

    if (!sortKey) {
      throw new Error('Sortable column must have sortKey attribute')
    }

    onSort({
      sortKey,
      sortDirection: desc ? 'desc' : 'asc',
    })
  }

  useEffect(() => {
    internalSort(sortBy)
  }, [sortBy])
}
