const colors = {
  gray: {
    0: '#ffffff',
    100: '#e8e8e8',
    200: '#dcdcdc',
    300: '#c9c9c9',
    400: '#b7b7b7',
    500: '#a3a3a3',
    600: '#696969',
    700: '#474747',
    800: '#3d3d3d',
    900: '#1e1e1e',
  },
  brand: {
    50: '#f1f8e9',
    100: '#dcedc8',
    200: '#c5e1a5',
    300: '#aed581',
    400: '#9ccc65',
    500: '#8BC34A',
    600: '#7cb342',
    700: '#689f38',
    800: '#558b2f',
    900: '#33691e',
  },
  blue: {
    100: '#ddf6ff',
    200: '#b0deff',
    300: '#82c6fb',
    400: '#53aff7',
    500: '#2598f3',
    600: '#0c7fda',
    700: '#0062aa',
    800: '#00467b',
    900: '#002a4d',
  },
  yellow: {
    50: '#fff9da',
    100: '#ffeead',
    200: '#ffe37d',
    300: '#ffd74b',
    400: '#ffcc1a',
    500: '#e6b300',
    600: '#b38b00',
    700: '#806300',
    800: '#4e3c00',
    900: '#1d1400',
  },
  red: {
    50: '#ffe4e3',
    100: '#ffb9b4',
    200: '#fa8d85',
    300: '#f76055',
    400: '#f33325',
    500: '#da1a0c',
    600: '#aa1208',
    700: '#7a0a05',
    800: '#4c0500',
    900: '#200000',
  },
}

export default colors
