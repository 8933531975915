import { useLogout } from '@/features/auth/mutations'
import Logo from '@/images/logo.svg'
import { t } from '@/lib/helpers'
import { useActiveRoute } from '@/lib/routes'
import { Box, Flex, HStack, Icon, Image, Link, Text } from '@chakra-ui/react'
import React from 'react'
import { FiLogOut } from 'react-icons/fi'

type Menu = {
  title: string
  href: string
  checkActive: string[]
}

const menus: Menu[] = [
  {
    title: t('views.admin.shared.navigation.calendars'),
    href: '/admin/calendars',
    checkActive: ['calendars', 'index'],
  },
  {
    title: t('views.admin.shared.navigation.cities'),
    href: '/admin/cities',
    checkActive: [
      'cities',
      'cityCollectionSites',
      'cityGarbages',
      'cityCollectionSiteCalendar',
    ],
  },
  {
    title: t('views.admin.shared.navigation.garbages'),
    href: '/admin/garbages',
    checkActive: ['garbages'],
  },
  {
    title: t('views.admin.shared.navigation.challenges'),
    href: '/admin/challenges',
    checkActive: ['challenges'],
  },
  {
    title: t('views.admin.shared.navigation.campaigns'),
    href: '/admin/campaigns',
    checkActive: ['campaigns', 'campaignSubmissions'],
  },
  {
    title: t('views.admin.shared.navigation.prizes'),
    href: '/admin/prize_exchanges',
    checkActive: ['prize_exchanges'],
  },
  {
    title: t('views.admin.shared.navigation.shippings'),
    href: '/admin/shippings',
    checkActive: ['shippings'],
  },
  {
    title: t('views.admin.shared.navigation.articles'),
    href: '/admin/articles',
    checkActive: ['articles'],
  },
  {
    title: t('views.admin.shared.navigation.users'),
    href: '/admin/users',
    checkActive: ['users'],
  },
  {
    title: t('views.admin.shared.navigation.posts'),
    href: '/admin/posts',
    checkActive: ['posts'],
  },
  {
    title: t('views.admin.shared.navigation.locations'),
    href: '/admin/locations',
    checkActive: ['locations'],
  },
  {
    title: t('views.admin.shared.navigation.forms'),
    href: '/admin/forms',
    checkActive: ['forms'],
  },
  {
    title: t('views.admin.shared.navigation.messages'),
    href: '/admin/messages',
    checkActive: ['messages'],
  },
  {
    title: t('views.admin.shared.navigation.admins'),
    href: '/admin/admins',
    checkActive: ['admins'],
  },
  {
    title: t('views.admin.shared.navigation.faqs'),
    href: '/admin/faqs',
    checkActive: ['faqs'],
  },
  {
    title: t('views.admin.shared.navigation.tags'),
    href: '/admin/tags',
    checkActive: ['tags'],
  },
  {
    title: t('views.admin.shared.navigation.qrcode_categories'),
    href: '/admin/qrcode_categories',
    checkActive: ['qrcode_categories'],
  },
  {
    title: t('views.admin.shared.navigation.app_configs'),
    href: '/admin/app_configs',
    checkActive: ['app_configs'],
  },
  {
    title: t('views.admin.shared.navigation.bonus_points'),
    href: '/admin/bonus_points',
    checkActive: ['bonus_points'],
  },
  {
    title: t('views.admin.shared.navigation.holidays'),
    href: '/admin/holidays',
    checkActive: ['holidays'],
  },
  {
    title: t('views.admin.shared.navigation.post_rankings'),
    href: '/admin/post_rankings',
    checkActive: ['post_rankings'],
  },
  {
    title: t('views.admin.shared.navigation.visible_ecos'),
    href: '/admin/visible_ecos',
    checkActive: ['visible_ecos'],
  },
]

export const Navbar = () => {
  const checkActiveRoute = useActiveRoute()
  const { mutate: logout } = useLogout()

  return (
    <Flex direction="column" h="full">
      <Box p={3} borderBottom="1px" borderColor="#c5e1a5">
        <Image src={Logo} />
      </Box>

      {menus.map(({ title, href, checkActive }) => {
        const isActive = checkActiveRoute(checkActive)
        return (
          <NavItem key={title} title={title} href={href} isActive={isActive} />
        )
      })}

      <HStack
        p={5}
        spacing={2}
        mt="auto"
        borderTop="1px"
        borderColor="#c5e1a5"
        cursor="pointer"
        onClick={() => logout()}
      >
        <Icon as={FiLogOut} sx={{ color: 'brand.500', boxSize: '20px' }} />
        <Text>{t('views.admin.shared.navigation.log_out')}</Text>
      </HStack>
    </Flex>
  )
}

type NavItemProps = {
  title: string
  href: string
  isActive: boolean
}

const NavItem = ({ title, href, isActive }: NavItemProps) => {
  return (
    <Link
      sx={{
        display: 'block',
        padding: 4,
        transition: 'borderColor 0.2s ease',
        textDecor: 'none',
        borderLeftWidth: '6px',
        borderColor: 'transparent',
        _focus: {
          borderColor: '#c5e1a5',
        },
        _hover: {
          borderColor: '#c5e1a5',
        },
        ...(isActive && activeStyle),
      }}
      onClick={(e) => {
        e.preventDefault()

        window.location.href = href
      }}
      href={href}
    >
      {title}
    </Link>
  )
}

const activeStyle = {
  fontWeight: 700,
  color: 'brand.500',
  borderColor: '#8BC34A',
}
