import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { Article } from '../types'
import { Meta } from '@/features/filters/types'

import { ARTICLES } from '../constants'

type ArticlesResponse = {
  data: Article[]
  meta: Meta
}

const getArticles = async (params: Meta): Promise<ArticlesResponse> => {
  const response = await fetchAPI({
    path: `/articles`,
    params,
  })

  const data = get(response, 'data.articles', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useArticles = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: ArticlesResponse
}) => {
  return useQuery<ArticlesResponse, AxiosError>({
    queryKey: [ARTICLES, params],
    queryFn: () => getArticles(params),
    keepPreviousData: true,
    initialData,
  })
}
