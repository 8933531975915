import { Pagination } from '@/components/shared/Pagination'
import { CityLayout } from '@/features/cities/components'
import { useCityCollectionSites } from '@/features/cities/queries'
import { City, CollectionSite } from '@/features/cities/types'
import { useMeta } from '@/features/filters'
import { Meta } from '@/features/filters/types'
import { withApp } from '@/features/Layout'
import { t } from '@/lib/helpers'
import { Box, Flex } from '@chakra-ui/layout'
import React from 'react'
import { CityCollectionSiteTable } from './CityCollectionSiteTable'

type Props = {
  city: City
  collection_sites: CollectionSite[]
  meta: Meta
}

function CityCollectionSitesPage(props: Props) {
  const { collection_sites: initialData, city, meta: initialMeta } = props

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: cityCollectionSites } = useCityCollectionSites({
    params: {
      city_id: city.id,
      meta,
    },
    initialData: { data: initialData, meta: initialMeta },
  })

  return (
    <CityLayout
      title={t('views.admin.cities.collection_sites.index.title', {
        name: city.name,
      })}
      breadcrumbData={[
        {
          title: t('views.admin.cities.collection_sites.index.cities'),
          link: '/admin/cities',
        },
        {
          title: city.name,
        },
        {
          title: t('views.admin.cities.index.collection_site'),
        },
      ]}
    >
      <Box mb="24px">
        <CityCollectionSiteTable
          data={cityCollectionSites.data}
          cityId={city.id}
        />
      </Box>

      <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
        <Pagination
          page={meta.page}
          per={meta.per}
          total={meta.total}
          onChangePage={(page) => {
            setMeta('page', page)
          }}
        />
      </Flex>
    </CityLayout>
  )
}

export default withApp(CityCollectionSitesPage)
