import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { Challenge } from '../types'
import { Meta } from '@/features/filters/types'

import { CHALLENGES } from '../constants'

type ChallengesResponse = {
  data: Challenge[]
  meta: Meta
}

const getChallenges = async (params: Meta): Promise<ChallengesResponse> => {
  const response = await fetchAPI({
    path: `/challenges`,
    params,
  })

  const data = get(response, 'data.challenges', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useChallenges = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: ChallengesResponse
}) => {
  return useQuery<ChallengesResponse, AxiosError>({
    queryKey: [CHALLENGES, params],
    queryFn: () => getChallenges(params),
    keepPreviousData: true,
    initialData,
  })
}
