import React from 'react'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'
import { get } from 'lodash'

import { Modal } from '@/components/shared/Modal'
import { ArticleForm } from './ArticleForm'

import { useUpdateArticle } from '../mutations'

import { Article } from '../types'
import { transformArticleIntoForm } from '../utils'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
  initialData?: Article
}

export const EditArticleModal = (props: Props) => {
  const { disclosure, initialData } = props
  const { isOpen, onClose } = disclosure

  const { mutate: updateArticle, isLoading } = useUpdateArticle()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="1280px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.articles.form.edit_title', {
            title: get(initialData, 'title'),
          })}
        </Text>
        <ArticleForm
          defaultValues={transformArticleIntoForm(initialData)}
          isLoading={isLoading}
          onSubmitForm={(data) => {
            updateArticle(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
