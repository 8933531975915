import React, { useMemo, Fragment, useState } from 'react'
import { get } from 'lodash'
import {
  Button,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FiCalendar } from 'react-icons/fi'

import { City } from '@/features/cities/types'

import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { TextWithIcon } from '@/components/shared/components'
import GarbageIcon from '@/icons/garbage.svg'

import { useDeleteCity } from '@/features/cities/mutations'
import { t } from '@/lib/helpers'
import {
  AddOfficialLinkModal,
  EditOfficialLinkModal,
} from '@/features/cities/components'

type Props = {
  data: City[]
}

export const CityTable = ({ data }: Props) => {
  const [actionData, setActionData] = useState<City>()
  const addDisclosure = useDisclosure()
  const editDisclosure = useDisclosure()

  const { mutate: deleteCity } = useDeleteCity()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.cities.index.name'),
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.cities.index.direct_url'),
        accessor: 'official_link',
        disableSortBy: true,

        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const value = get(cellProps, 'value')

          return value ? (
            <Text
              sx={{
                fontSize: '14px',
                width: { base: '140px', xl: '100%' },
                wordBreak: 'break-all',
              }}
            >
              {value}
            </Text>
          ) : (
            <Button
              variant="link"
              sx={{ fontSize: '14px' }}
              onClick={() => {
                setActionData(row)
                addDisclosure.onOpen()
              }}
            >
              {t('views.admin.cities.index.add_url')}
            </Button>
          )
        },
      },
      {
        Header: t('views.admin.cities.index.collection_sites_url'),
        accessor: 'collection_sites_link',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const value = get(cellProps, 'value')

          return value ? (
            <Text
              sx={{
                fontSize: '14px',
                width: { base: '140px', xl: '100%' },
                wordBreak: 'break-all',
              }}
            >
              {value}
            </Text>
          ) : (
            <Button
              variant="link"
              sx={{ fontSize: '14px' }}
              onClick={() => {
                setActionData(row)
                addDisclosure.onOpen()
              }}
            >
              {t('views.admin.cities.index.add_url')}
            </Button>
          )
        },
      },
      {
        Header: t('views.admin.cities.index.collection_site'),
        accessor: 'collection_sites_count',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')
          const { id } = get(cellProps, 'row.original')

          return (
            <HStack spacing="24px">
              <TextWithIcon
                icon={<Icon as={FiCalendar} sx={{ color: 'brand.500' }} />}
                text={
                  <Link
                    href={`cities/${id}/collection_sites`}
                    sx={{ color: 'gray.600' }}
                  >{`Collection Site (${value})`}</Link>
                }
              />

              <TextWithIcon
                icon={<Image src={GarbageIcon} sx={{ boxSize: '20px' }} />}
                text={
                  <Link
                    href={`cities/${id}/garbages`}
                    sx={{ color: 'gray.600' }}
                  >{`Garbages`}</Link>
                }
                sx={{ gridTemplateColumns: '20px 1fr' }}
              />
            </HStack>
          )
        },
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id, official_link, collection_sites_link } = row
          const isShowEdit = official_link || collection_sites_link
          return (
            <HStack spacing="2px">
              {isShowEdit && (
                <TableEditButton
                  ariaLabel={'edit challenge'}
                  onClick={() => {
                    setActionData(row)
                    editDisclosure.onOpen()
                  }}
                />
              )}
              <TableTrashButton
                ariaLabel={'delete city'}
                onClick={() => {
                  if (confirm(t('views.admin.cities.index.confirm_delete'))) {
                    deleteCity(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />
      <AddOfficialLinkModal
        initialData={actionData}
        disclosure={addDisclosure}
      />
      <EditOfficialLinkModal
        initialData={actionData}
        disclosure={editDisclosure}
      />
    </Fragment>
  )
}
