import {
  Button,
  Checkbox,
  Grid,
  Input,
  NumberInput,
  NumberInputField,
  Textarea,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'
import React from 'react'
import { Controller, get, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { BannerInput } from '@/components/shared/BannerInput'
import { FormControl, TimeSelect } from '@/features/forms/components'

import { formatDateTime, t } from '@/lib/helpers'

import { SUPPORTED_FORMATS } from '@/features/constants'
import { PrizeFormInput } from '../types'

type Props = {
  defaultValues?: PrizeFormInput
  onSubmitForm: (data: PrizeFormInput) => void
  isLoading: boolean
}

export const PrizeForm = (props: Props) => {
  const { defaultValues, onSubmitForm, isLoading } = props

  const {
    register,
    handleSubmit,
    control,
    errors,
    formState: { isSubmitting },
  } = useForm<PrizeFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const onSubmit = (data: PrizeFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gridTemplateColumns: '510px 340px', gap: '32px' }}>
        <Grid
          id="PrizeLeft"
          sx={{
            gridTemplateColumns: 'repeat(10, 1fr)',
            gap: '24px 16px',
            alignContent: 'start',
          }}
        >
          <FormControl
            id="title"
            label={t('views.admin.prize_exchanges.form.title')}
            isRequired
            sx={{ gridColumn: '1/-1' }}
            errorMsg={errors.title?.message}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.prize_exchanges.form.title')}
              ref={register}
              name="title"
              tabIndex={1}
            />
          </FormControl>

          <FormControl
            id="description"
            label={t('views.admin.prize_exchanges.form.description')}
            sx={{ gridColumn: '1/-1' }}
          >
            <Textarea
              size="lg"
              name="description"
              placeholder={t(
                'views.admin.prize_exchanges.form.description_placeholder',
              )}
              resize="none"
              sx={{ h: '96px' }}
              ref={register}
              tabIndex={2}
            />
          </FormControl>

          <FormControl
            id="cost"
            label={t('views.admin.prize_exchanges.form.cost')}
            isRequired
            sx={{ gridColumn: '1/6' }}
          >
            <NumberInput
              size="lg"
              defaultValue={3}
              name="cost"
              tabIndex={3}
              ref={register}
            >
              <NumberInputField ref={register} />
            </NumberInput>
          </FormControl>
          <FormControl
            id="limit_items"
            label={t('views.admin.prize_exchanges.form.limit_items')}
            isRequired
            sx={{ gridColumn: '6/-1' }}
          >
            <NumberInput
              size="lg"
              defaultValue={0}
              name="limit_items"
              tabIndex={4}
              ref={register}
            >
              <NumberInputField ref={register} />
            </NumberInput>
          </FormControl>

          <FormControl
            id="points"
            label={t('views.admin.prize_exchanges.form.points')}
            isRequired
            sx={{ gridColumn: '1/6' }}
          >
            <NumberInput
              size="lg"
              defaultValue={0}
              name="points"
              tabIndex={5}
              ref={register}
            >
              <NumberInputField ref={register} />
            </NumberInput>
          </FormControl>

          <FormControl
            id="order_number"
            label={t('views.admin.prize_exchanges.form.order_number')}
            sx={{ gridColumn: '6/-1' }}
            errorMsg={errors.order_number?.message}
          >
            <NumberInput
              size="lg"
              defaultValue={0}
              name="order_number"
              tabIndex={5}
              ref={register}
            >
              <NumberInputField ref={register} />
            </NumberInput>
          </FormControl>

          <FormControl
            id="startsAt"
            label={t('views.admin.prize_exchanges.form.starts_at')}
            isRequired
            sx={{ gridColumn: '1/7' }}
            errorMsg={errors.startsAt?.message}
          >
            <Input
              type="date"
              name="startsAt"
              ref={register}
              defaultValue={formatDateTime(new Date().toString(), 'YYYY-MM-DD')}
              tabIndex={6}
              size="lg"
            />
          </FormControl>
          <FormControl
            id="startsAtHr"
            label={t('views.admin.prize_exchanges.form.time')}
            sx={{ gridColumn: '7/9' }}
            isRequired
          >
            <TimeSelect innerRef={register} name={'startsAtHr'} tabIndex={7} />
          </FormControl>
          <FormControl id="startsAtMin" sx={{ mt: 'auto', gridColumn: '9/-1' }}>
            <TimeSelect
              isMin={true}
              innerRef={register}
              name={'startsAtMin'}
              tabIndex={8}
            />
          </FormControl>

          <FormControl
            id="endsAt"
            label={t('views.admin.prize_exchanges.form.ends_at')}
            isRequired
            sx={{ gridColumn: '1/7' }}
            errorMsg={errors.endsAt?.message}
          >
            <Input
              type="date"
              name="endsAt"
              ref={register}
              defaultValue={formatDateTime(
                dayjs().add(7, 'day').toString(),
                'YYYY-MM-DD',
              )}
              tabIndex={9}
              size="lg"
            />
          </FormControl>
          <FormControl
            id="endsAtHr"
            label={t('views.admin.prize_exchanges.form.time')}
            sx={{ gridColumn: '7/9' }}
            isRequired
          >
            <TimeSelect innerRef={register} name={'endsAtHr'} tabIndex={10} />
          </FormControl>
          <FormControl id="endsAtMin" sx={{ mt: 'auto', gridColumn: '9/-1' }}>
            <TimeSelect
              isMin={true}
              innerRef={register}
              name={'endsAtMin'}
              tabIndex={11}
            />
          </FormControl>
          <FormControl id="age_restricted" sx={{ gridColumn: '1/-1' }}>
            <Checkbox ref={register} name="age_restricted">
              {t('views.admin.prize_exchanges.form.age_restricted')}
            </Checkbox>
          </FormControl>
        </Grid>

        <Grid
          id="PrizeRight"
          sx={{
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '24px 16px',
            alignContent: 'start',
          }}
        >
          <Controller
            name="banner"
            defaultValue={null}
            control={control}
            render={({ onChange, value }) => {
              const error = get(errors, 'banner.message')

              return (
                <FormControl
                  id="banner"
                  label={t('views.admin.prize_exchanges.form.banner_image')}
                  sx={{ gridColumn: '1/-1' }}
                  errorMsg={error}
                >
                  <BannerInput file={value} onChange={onChange} />
                </FormControl>
              )
            }}
          />

          <FormControl
            id="first_win_prob"
            label={t('views.admin.prize_exchanges.form.1st_prize_prob')}
            sx={{ gridColumn: '1/2' }}
            isRequired
          >
            <NumberInput
              size="lg"
              defaultValue={10}
              name="first_win_prob"
              tabIndex={13}
              ref={register}
              min={1}
              max={1000}
            >
              <NumberInputField ref={register} />
            </NumberInput>
          </FormControl>

          <FormControl
            id="second_win_prob"
            label={t('views.admin.prize_exchanges.form.2nd_prize_prob')}
            sx={{ gridColumn: '2/-1' }}
            isRequired
          >
            <NumberInput
              size="lg"
              defaultValue={15}
              name="second_win_prob"
              tabIndex={14}
              ref={register}
              min={1}
              max={1000}
            >
              <NumberInputField ref={register} />
            </NumberInput>
          </FormControl>

          <FormControl id="note" label={'Note'} sx={{ gridColumn: '1/-1' }}>
            <Textarea
              size="lg"
              name="note"
              placeholder={'Note'}
              resize="none"
              sx={{ h: '257px' }}
              ref={register}
              tabIndex={17}
            />
          </FormControl>

          <Button
            sx={{ h: '54px', gridColumn: '1/-1 ' }}
            type="submit"
            isLoading={isSubmitting || isLoading}
          >
            {isEmpty(defaultValues)
              ? t('views.admin.prize_exchanges.form.create')
              : t('views.admin.prize_exchanges.form.update')}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(t('views.shared.form.required')),
  description: yup.string(),
  banner: yup
    .mixed()
    .notRequired()
    .test('fileType', t('views.shared.form.file_not_support'), (value) => {
      if (typeof value === 'string') return true

      return value ? SUPPORTED_FORMATS.includes(value.type) : true
    }),
  note: yup.string(),
  cost: yup.number().integer(),
  limit_items: yup.number().integer(),
  points: yup.number().integer(),
  startsAt: yup.date().required(t('views.shared.form.required')),
  startsAtHr: yup.number().positive().integer().min(0).max(23),
  startsAtMin: yup.number().positive().integer().min(0).max(59),
  endsAt: yup.date().required(t('views.shared.form.required')),
  endsAtHr: yup.number().positive().integer().min(0).max(23),
  endsAtMin: yup.number().positive().integer().min(0).max(59),
  first_win_prob: yup
    .number()
    .integer()
    .required(t('views.shared.form.required')),
  second_win_prob: yup
    .number()
    .integer()
    .required(t('views.shared.form.required')),
  order_number: yup.number().positive().integer(),
  age_restricted: yup.boolean(),
})
