import { useMemo } from 'react'

import { CityCollection } from '@/features/types'
import { t } from '../helpers'

type OptionItem = {
  label: string
  value: number
}

export const useCityOptions = (
  cities: CityCollection,
  { emptyFirstItem = false } = {},
) => {
  return useMemo<OptionItem[]>((): OptionItem[] => {
    const options = cities.map((c) => ({ value: c.id, label: c.name }))

    return emptyFirstItem
      ? ([
          { label: t('views.admin.cities.form.select_city'), value: '' },
          ...options,
        ] as OptionItem[])
      : options
  }, [])
}
