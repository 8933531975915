import React from 'react'
import { Box, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { QRCategoryForm } from './QRCategoryForm'

import { useCreateQRCodeCategory } from '../mutations'

type Props = {
  disclosure: UseDisclosureProps
}

export const CreateQRCodeCategoryFormModal = (props: Props) => {
  const { disclosure } = props

  const { isOpen, onClose } = disclosure

  const { mutate: createQRCategory, isLoading } = useCreateQRCodeCategory()

  const isEditing: boolean = false

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <QRCategoryForm
          isLoading={isLoading}
          isEditing={isEditing}
          onSubmitForm={(data) => {
            createQRCategory(data, { onSuccess: onClose })
          }}
        />
      </Box>
    </Modal>
  )
}
