import { Modal } from '@/components/shared/Modal'
import { LinkFormItem } from '@/features/link_forms/types'
import { t } from '@/lib/helpers'
import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { useCreateChallenge } from '../mutations'
import { ChallengeForm } from './ChallengeForm'

type Props = {
  isOpen: boolean
  onClose: () => void
  forms: LinkFormItem[]
}

export const CreateChallengeFormModal = ({ isOpen, onClose, forms }: Props) => {
  const { mutate: createChallenge, isLoading } = useCreateChallenge()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.challenges.new.title')}
        </Text>
        <ChallengeForm
          isLoading={isLoading}
          onSubmitForm={(data) => {
            createChallenge(data, { onSuccess: onClose })
          }}
          forms={forms}
        />
      </Box>
    </Modal>
  )
}
