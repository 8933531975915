import { useTheme } from '@chakra-ui/react'

import {
  StylesConfig,
  Theme,
  GroupTypeBase,
  OptionTypeBase,
} from 'react-select'
import { SelectOption } from './types'

export const useThemeConfig = <
  OptionType extends OptionTypeBase = SelectOption,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
>() => {
  const { colors } = useTheme()

  const customStyles: StylesConfig<OptionType, IsMulti, GroupType> = {
    control: (provided, state) => {
      const borderColor = state.isFocused ? colors.brand[600] : colors.gray[200]

      return {
        ...provided,
        borderColor: borderColor,
        boxShadow: 'none',
        fontSize: '16px',

        '&:hover': {
          borderColor: borderColor,
        },
      }
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        paddingTop: 3,
        paddingBottom: 3,
      }
    },
    indicatorSeparator: (provided) => {
      return {
        ...provided,
        backgroundColor: colors.gray[200],
      }
    },
    singleValue: (provided) => {
      return {
        ...provided,
        marginLeft: '6px',
      }
    },
    placeholder: (provided) => {
      return {
        ...provided,
        marginLeft: '6px',
      }
    },
    input: (provided) => {
      return {
        ...provided,
        marginLeft: '6px',
      }
    },
  }

  const selectTheme = (theme: Theme) => {
    return {
      ...theme,
      borderRadius: 6,
      colors: {
        ...theme.colors,
        primary: colors.brand[600],
        primary25: colors.brand[50],
        primary50: colors.brand[100],
        primary75: colors.brand[200],
      },
      spacing: {
        ...theme.spacing,
        controlHeight: 48,
      },
    }
  }

  return {
    styles: customStyles,
    theme: selectTheme,
  }
}
