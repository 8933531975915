import React from 'react'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { LocationForm } from './LocationForm'
import { useUpdateLocation } from '../mutations'
import { Location } from '../types'
import { transformLocationIntoForm } from '../utils'

import { t } from '@/lib/helpers'
import { QRCodeCategory } from '@/features/qrcode_categories/types'

type Props = {
  disclosure: UseDisclosureProps
  initialData?: Location
  qrCategories: QRCodeCategory[]
}

export const EditLocationFormModal = (props: Props) => {
  const { disclosure, initialData, qrCategories } = props

  const { isOpen, onClose } = disclosure

  const { mutate: updateLocation, isLoading } = useUpdateLocation()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" sx={{ mb: '20px' }} isTruncated>
          {t('views.admin.locations.edit.title')}
        </Text>

        <LocationForm
          isLoading={isLoading}
          qrCategories={qrCategories}
          defaultValues={transformLocationIntoForm(initialData)}
          onSubmitForm={(data) => {
            updateLocation(
              {
                form: data,
                id: initialData?.id,
              },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
