import React from 'react'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { PrizeForm } from './PrizeForm'

import { useCreatePrize } from '../mutations'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
}

export const CreatePrizeModal = (props: Props) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure

  const { mutate: createPrize, isLoading } = useCreatePrize()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="946px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.prize_exchanges.new.title')}
        </Text>
        <PrizeForm
          onSubmitForm={(data) => {
            createPrize(data, { onSuccess: onClose })
          }}
          isLoading={isLoading}
        />
      </Box>
    </Modal>
  )
}
