import React, { Fragment } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Tag } from '@/features/tags/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { CreateTagFormModal } from '@/features/tags/components'
import { TagTable } from './TagTable'

import { useTags } from '@/features/tags/queries'
import { useMeta } from '@/features/filters'
import { t } from '@/lib/helpers'

type Props = {
  tags: Tag[]
  meta: Meta
}

function TagsPage(props: Props) {
  const { tags: initialTags, meta: initialMeta } = props

  const { onOpen, isOpen, onClose } = useDisclosure()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: tags } = useTags({
    initialData: { data: initialTags, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.tags.index.title')}
          addons={
            <Button onClick={onOpen} sx={{ h: '48px', w: '148px' }}>
              {t('views.admin.tags.index.new')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <TagTable data={tags.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <CreateTagFormModal isOpen={isOpen} onClose={onClose} />
    </Fragment>
  )
}

export default withApp(TagsPage)
