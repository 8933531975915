import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'
import { CalendarSettingsPayload } from '../types'
import { CALENDAR_SETTINGS } from '../constants'

const updateCalendarSettings = async (params: CalendarSettingsPayload) => {
  await fetchAPI({
    method: 'put',
    path: `/cities/${params.city_id}/collection_sites/${params.collection_site_id}/calendars/settings`,
    data: params,
  })
}

export const useUpdateCalendarSettings = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateCalendarSettings, {
    onSuccess: async () => {
      await queryClient.refetchQueries([CALENDAR_SETTINGS])
      toast({
        description: t('views.admin.settings.index.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
