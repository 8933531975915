import React from 'react'

import { Modal } from '@/components/shared/Modal'
import { MessageForm } from './MessageForm'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { useCreateMessage } from '../mutations'
import { CityCollection } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
  cities: CityCollection
}

export const CreateMessageModal = (props: Props) => {
  const { disclosure, cities } = props
  const { isOpen, onClose } = disclosure

  const { mutate: createMessage, isLoading } = useCreateMessage()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="946px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.messages.new.title')}
        </Text>
        <MessageForm
          isLoading={isLoading}
          onSubmitForm={(data) => {
            createMessage(data, { onSuccess: onClose })
          }}
          cities={cities}
        />
      </Box>
    </Modal>
  )
}
