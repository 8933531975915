import { TableTrashButton } from '@/components/shared/Table'
import { FormControl } from '@/features/forms/components'
import { t } from '@/lib/helpers'
import { Button, Checkbox, Flex, Input, Select } from '@chakra-ui/react'
import React from 'react'
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { MAX, QUESTION_TYPE_OPTIONS } from '../constants'
import { LinkFormInput } from '../types'
import { OptionFields } from './OptionFields'

type Props = {
  control: Control<LinkFormInput>
  register: ReturnType<typeof useForm>['register']
  errors: DeepMap<LinkFormInput, FieldError>
}

export const QuestionFields = ({ control, register, errors }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  })

  const addQuestion = (focus = true) => {
    const newQuestion: LinkFormInput['questions'][number] = {
      title: '',
      question_type: 'multiple_choice',
      required: true,
      isNew: true,
    }

    append(newQuestion, focus)
  }

  React.useEffect(() => {
    if (!fields.length) addQuestion(false)
  }, [fields])

  return (
    <>
      {fields.map((field, index) => (
        <Flex
          key={field.id}
          sx={{
            flexDir: 'column',
            gap: '8px',
            border: '2px dashed #ccc',
            rounded: 'md',
            padding: '16px',
            '&:nth-of-type(2n+1)': {
              bgColor: 'gray.50',
            },
          }}
        >
          {!field.isNew && (
            <Input
              type="hidden"
              ref={register()}
              name={`questions[${index}].id`}
              defaultValue={field.id}
            />
          )}
          <Controller
            name={`questions[${index}].question_type`}
            defaultValue={field.question_type}
            control={control}
            render={({ onChange, value }) => {
              return (
                <>
                  <Flex sx={{ gap: '4px', alignItems: 'flex-end' }}>
                    <FormControl
                      id={`questions[${index}].title`}
                      label={t('views.admin.forms.form.question_number', {
                        number: index + 1,
                      })}
                      isRequired
                      errorMsg={errors.questions?.[index]?.title?.message}
                      sx={{ flex: 2 }}
                    >
                      <Input
                        size="lg"
                        placeholder={t(
                          'views.admin.forms.form.question_number',
                          {
                            number: index + 1,
                          },
                        )}
                        ref={register()}
                        name={`questions[${index}].title`}
                        defaultValue={field.title}
                      />
                    </FormControl>
                    <FormControl
                      id={`questions[${index}].question_type`}
                      label={t('views.admin.forms.form.question_type')}
                      isRequired
                      sx={{ flex: 1 }}
                    >
                      <Select size="lg" onChange={onChange} value={value}>
                        {QUESTION_TYPE_OPTIONS.map((q) => (
                          <option value={q.value} key={q.value}>
                            {q.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    {fields.length > 1 && (
                      <TableTrashButton
                        ariaLabel={'delete question'}
                        onClick={() => remove(index)}
                        sx={{ mb: '9px' }}
                      />
                    )}
                  </Flex>

                  <Checkbox
                    name={`questions[${index}].required`}
                    ref={register()}
                    colorScheme="green"
                    sx={{ fontWeight: 500 }}
                    defaultIsChecked={field.required}
                  >
                    {t('views.admin.forms.form.required')}
                  </Checkbox>

                  {value !== 'answer_text' && (
                    <OptionFields
                      questionIndex={index}
                      control={control}
                      register={register}
                      errors={errors}
                    />
                  )}
                </>
              )
            }}
          />
        </Flex>
      ))}
      {fields.length < MAX.QUESTION && (
        <Button onClick={() => addQuestion()} w="fit-content">
          {t('views.admin.forms.form.add_question')}
        </Button>
      )}
    </>
  )
}
