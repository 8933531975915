import { get } from 'lodash'

import { Location, LocationFormInput } from './types'

export const transformLocationIntoForm = (
  data: Location,
): LocationFormInput => {
  return {
    name: get(data, 'name'),
    latitude: get(data, 'latitude'),
    longitude: get(data, 'longitude'),
    address: get(data, 'address'),
    qrcode_category_id: get(data, 'qrcode_category.id'),
  }
}
