import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { useExportStatus, calculateResponse } from '@/features/forms/queries'
import { EditLinkFormModal } from '@/features/link_forms/components'
import { useDeleteLinkForm } from '@/features/link_forms/mutations/deleteLinkForm'
import { LinkForm } from '@/features/link_forms/types'
import { t } from '@/lib/helpers'
import { Button, HStack, Link, useDisclosure, useToast } from '@chakra-ui/react'
import { get } from 'lodash'
import React, { Fragment, useEffect, useMemo, useState } from 'react'

type Props = {
  data: LinkForm[]
}

const EXPORT_STATUS_POLLING_TIME = 10_000

export const FormTable = ({ data }: Props) => {
  const [actionData, setActionData] = useState<LinkForm>()
  const [editMode, setEditMode] = useState(false)

  const disclosure = useDisclosure()
  const { onOpen } = disclosure
  const toast = useToast()

  const { mutate: deleteLinkForm } = useDeleteLinkForm()

  const successCalculateToast = () => {
    toast({
      description: t('views.admin.forms.services.calculate_success'),
      status: 'success',
      position: 'top-right',
      isClosable: true,
    })
  }

  const columns = useMemo(() => {
    const LinkCell = (cellProps, transform = (value) => value) => {
      const value = get(cellProps, 'value')
      const originalData = get(cellProps, 'row.original')

      return (
        <Link
          onClick={() => {
            setActionData(originalData)
            setEditMode(false)
            onOpen()
          }}
          sx={{
            _hover: {
              color: 'brand.500',
            },
          }}
        >
          {transform(value) || '-'}
        </Link>
      )
    }

    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
        Cell: (cellProps) => LinkCell(cellProps),
      },
      {
        Header: t('views.admin.forms.index.form_title'),
        accessor: 'title',
        disableSortBy: true,
        Cell: (cellProps) => LinkCell(cellProps),
      },
      {
        Header: t('views.admin.forms.index.description'),
        accessor: 'description',
        disableSortBy: true,
        Cell: (cellProps) => LinkCell(cellProps),
      },
      {
        Header: t('views.admin.forms.index.question_count'),
        accessor: 'questions',
        disableSortBy: true,
        Cell: (cellProps) => LinkCell(cellProps, (value) => value.length),
      },
      {
        Header: t('views.admin.shared.table.edit_delete'),
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id } = row
          const [calculate, setCalculate] = useState<boolean>(false)
          const { data: exportStatus, refetch } = useExportStatus({ id })
          const isCalculateLoading = calculate || exportStatus?.calculate
          const isDownloadDisabled =
            calculate || exportStatus?.calculate || !exportStatus?.ready

          useEffect(() => {
            let interval
            if (exportStatus?.calculate || calculate) {
              interval = setInterval(async () => {
                await refetch()
                setCalculate(false)
              }, EXPORT_STATUS_POLLING_TIME)
            } else {
              clearInterval(interval)
            }
            return () => clearInterval(interval)
          }, [exportStatus?.ready, exportStatus?.calculate, calculate])

          return (
            <HStack spacing="2px">
              <Button
                size="sm"
                variant="outline"
                cursor="pointer"
                onClick={async () => {
                  if (isCalculateLoading) return

                  setCalculate(true)
                  await calculateResponse(id)
                  successCalculateToast()
                }}
                mr={2}
                isLoading={isCalculateLoading}
              >
                {t('views.admin.forms.form.calculate')}
              </Button>
              <Button
                as={isDownloadDisabled ? 'btn' : 'a'}
                size="sm"
                href={`/admin/forms/${id}/export.csv`}
                mr={2}
                disabled={isDownloadDisabled}
              >
                {t('views.admin.forms.form.download')}
              </Button>
              <TableEditButton
                ariaLabel={'edit form'}
                onClick={() => {
                  setActionData(row)
                  setEditMode(true)
                  onOpen()
                }}
              />
              <TableTrashButton
                ariaLabel={'delete form'}
                onClick={() => {
                  if (confirm('Confirm Delete ?')) {
                    deleteLinkForm(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      {
        editMode ? (
          <EditLinkFormModal disclosure={disclosure} initialData={actionData} />
        ) : null
        // <HolidayShowModal
        //   disclosure={disclosure}
        //   holiday={actionData}
        //   onEdit={() => setEditMode(true)}
        // />
      }
    </Fragment>
  )
}
