import React from 'react'
import { isEmpty } from 'lodash'
import { Button, Input } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormControl } from '@/features/forms/components'

import { t } from '@/lib/helpers'

import { TagFormInput } from '../types'

type Props = {
  defaultValues?: TagFormInput
  onSubmitForm: (data: TagFormInput) => void
  isLoading: boolean
}

export const TagForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, isLoading } = props

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<TagFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const onSubmit = (data: TagFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        id="name"
        label={t('views.admin.tags.form.tag')}
        isRequired
        sx={{ gridColumn: '1/-1' }}
        errorMsg={errors.name?.message}
      >
        <Input
          size="lg"
          label={t('views.admin.tags.form.tag')}
          ref={register}
          name="name"
          autoFocus
        />
      </FormControl>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        size="lg"
        isLoading={isSubmitting || isLoading}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.tags.form.create')
          : t('views.admin.tags.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  name: yup.string().required(t('views.admin.shared.required')),
})
