import React from 'react'
import { Box, Grid, Text } from '@chakra-ui/layout'
import { UseDisclosureProps } from '@chakra-ui/hooks'

import { Modal } from '@/components/shared/Modal'

import { t } from '@/lib/helpers'
import { Shipping } from '../types'

type Props = {
  shipping: Shipping
  disclosure: UseDisclosureProps
}

export const ShippingShowModal = (props: Props) => {
  const { disclosure, shipping } = props
  const { isOpen, onClose } = disclosure

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withCloseButton
      closeOnOverlayClick
      maxW="600px"
    >
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.shippings.show.title')}
        </Text>

        <Grid
          sx={{
            gridTemplateColumns: 'max-content 1fr',
            gap: '16px',
            fontSize: '16px',
          }}
        >
          <Text fontWeight={600}>メールアドレス</Text>
          <Text>{shipping.email}</Text>

          <Text fontWeight={600}>ふりがな</Text>
          <Text>{shipping.furigana || '-'}</Text>

          <Text fontWeight={600}>氏名</Text>
          <Text>{shipping.name}</Text>

          <Text fontWeight={600}>電話番号</Text>
          <Text>{shipping.phone_number || '-'}</Text>

          <Text fontWeight={600}>郵便番号</Text>
          <Text>{shipping.postal_code}</Text>

          <Text fontWeight={600}>住所</Text>
          <Text>{shipping.address}</Text>

          <Text fontWeight={600}>当選景品</Text>
          <Text>{shipping.prize}</Text>
        </Grid>
      </Box>
    </Modal>
  )
}
