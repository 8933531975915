import React from 'react'
import { isEmpty, get } from 'lodash'
import { Button, Grid, Input, Box, Textarea, Select } from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import dayjs from 'dayjs'

import { FormControl, TimeSelect } from '@/features/forms/components'
import { formatDateTime, t } from '@/lib/helpers'
import { BannerInput } from '@/components/shared/BannerInput'
import { SUPPORTED_FORMATS } from '@/features/constants'
import { BONUS_TYPES } from '../constants'
import type { BonusPointInput } from '../types'
import { QuizChoices } from './QuizChoices'

type Props = {
  defaultValues?: BonusPointInput
  onSubmitForm: (data: BonusPointInput) => void
  isLoading?: boolean
}

export const BonusPointForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, isLoading = false } = props

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
    watch,
  } = useForm<BonusPointInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const onSubmit = (data: BonusPointInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  const isQuiz = watch('bonus_type') === 'quiz'
  const isMultiQuiz = watch('bonus_type') === 'multi_selections_quiz'

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gridTemplateColumns: 'repeat(2, 1fr)', gap: '32px' }}>
        <Grid sx={{ gridTemplateColumns: 'repeat(5, 1fr)', gap: '24px 16px' }}>
          <FormControl
            id="header_text"
            label={t('views.admin.bonus_points.form.header_text')}
            isRequired
            sx={{ gridColumn: '1/-1' }}
            errorMsg={errors.header_text?.message}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.bonus_points.form.header_text')}
              ref={register}
              name="header_text"
              autoFocus
            />
          </FormControl>

          <FormControl
            id="title"
            label={t('views.admin.bonus_points.form.title')}
            isRequired
            sx={{ gridColumn: '1/-1' }}
            errorMsg={errors.title?.message}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.bonus_points.form.title')}
              ref={register}
              name="title"
            />
          </FormControl>

          <FormControl
            id="description"
            label={t('views.admin.bonus_points.form.description')}
            isRequired
            sx={{ gridColumn: '1/-1' }}
            errorMsg={errors.description?.message}
          >
            <Textarea
              name="description"
              size="lg"
              placeholder={t('views.admin.bonus_points.form.description')}
              ref={register}
            />
          </FormControl>

          <FormControl
            id="bonus_type"
            label={t('views.admin.bonus_points.form.bonus_type')}
            isRequired
            sx={{ gridColumn: '1/-1' }}
            errorMsg={errors.bonus_type?.message}
          >
            <Select size="lg" ref={register} name="bonus_type" tabIndex={3}>
              {BONUS_TYPES.map((value) => (
                <option value={value} key={value}>
                  {t(`views.admin.bonus_points.form.${value}`)}
                </option>
              ))}
            </Select>
          </FormControl>


          {!isMultiQuiz && (
            <FormControl
              id="point"
              label={t('views.admin.bonus_points.form.point')}
              isRequired
              sx={{ gridColumn: '1/-1' }}
              errorMsg={errors.point?.message}
            >
              <Input
                type="number"
                name="point"
                size="lg"
                placeholder={t('views.admin.bonus_points.form.point')}
                ref={register}
              />
            </FormControl>
          )}

          <FormControl
            id="quiz_answer"
            label={t('views.admin.bonus_points.form.quiz_answer')}
            sx={{
              gridColumn: '1/-1',
              display: isQuiz ? 'block' : 'none',
            }}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.bonus_points.form.quiz_answer')}
              ref={register}
              name="quiz_answer"
            />
          </FormControl>

          <FormControl
            id="quiz_answer_description"
            label={t('views.admin.bonus_points.form.quiz_answer_description')}
            sx={{
              gridColumn: '1/-1',
              display: (isQuiz || isMultiQuiz) ? 'block' : 'none',
            }}
          >
            <Textarea
              name="quiz_answer_description"
              size="lg"
              placeholder={t(
                'views.admin.bonus_points.form.quiz_answer_description',
              )}
              ref={register}
            />
          </FormControl>

          {isMultiQuiz && <QuizChoices control={control as any} register={register} errors={errors} /> }

          <FormControl
            id="starts_at"
            label={t('views.admin.bonus_points.form.starts_at')}
            isRequired
            sx={{ gridColumn: '1/4' }}
            errorMsg={errors.starts_at?.message}
          >
            <Input
              type="date"
              name="starts_at"
              ref={register}
              defaultValue={formatDateTime(new Date().toString(), 'YYYY-MM-DD')}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="starts_at_hr"
            label={t('views.admin.bonus_points.form.time')}
            isRequired
          >
            <TimeSelect innerRef={register} name="starts_at_hr" tabIndex={6} />
          </FormControl>

          <FormControl id="startsAtMin" sx={{ mt: 'auto' }}>
            <TimeSelect isMin={true} innerRef={register} name="starts_at_min" />
          </FormControl>

          <FormControl
            id="ends_at"
            label={t('views.admin.bonus_points.form.ended_at')}
            isRequired
            sx={{ gridColumn: '1/4' }}
            errorMsg={errors.ends_at?.message}
          >
            <Input
              type="date"
              name="ends_at"
              ref={register}
              defaultValue={formatDateTime(
                dayjs().add(7, 'day').toString(),
                'YYYY-MM-DD',
              )}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="ends_at_hr"
            label={t('views.admin.bonus_points.form.time')}
            isRequired
          >
            <TimeSelect innerRef={register} name="ends_at_hr" tabIndex={9} />
          </FormControl>

          <FormControl id="ends_at_min" sx={{ mt: 'auto' }}>
            <TimeSelect isMin innerRef={register} name="ends_at_min" />
          </FormControl>
        </Grid>

        <Box>
          <Controller
            name="image_url"
            defaultValue={null}
            control={control}
            render={({ onChange, value }) => {
              const error = get(errors, 'image_url.message')

              return (
                <FormControl
                  id="image_url"
                  label={t('views.admin.bonus_points.form.banner_image')}
                  sx={{ gridColumn: '1/-1' }}
                  errorMsg={error}
                >
                  <BannerInput file={value} onChange={onChange} />
                </FormControl>
              )
            }}
          />

          <Box mt="24px">
            <Controller
              name="cover_image"
              defaultValue={null}
              control={control}
              render={({ onChange, value }) => {
                const error = get(errors, 'cover_image.message')

                return (
                  <FormControl
                    id="cover_image"
                    label={t('views.admin.bonus_points.form.cover_image')}
                    isRequired
                    sx={{ gridColumn: '1/-1' }}
                    errorMsg={error}
                  >
                    <BannerInput file={value} onChange={onChange} />
                  </FormControl>
                )
              }}
            />
          </Box>

          <Box mt="24px" display={isQuiz ? 'block' : 'none'}>
            <Controller
              name="quiz_answer_image"
              defaultValue={null}
              control={control}
              render={({ onChange, value }) => {
                const error = get(errors, 'quiz_answer_image.message')

                return (
                  <FormControl
                    id="quiz_answer_image"
                    label={t('views.admin.bonus_points.form.quiz_answer_image')}
                    sx={{ gridColumn: '1/-1' }}
                    errorMsg={error}
                  >
                    <BannerInput file={value} onChange={onChange} />
                  </FormControl>
                )
              }}
            />
          </Box>
        </Box>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        isLoading={isSubmitting || isLoading}
        tabIndex={11}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.bonus_points.form.create')
          : t('views.admin.bonus_points.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(t('views.admin.shared.form.required')),
  header_text: yup.string().required(t('views.admin.shared.form.required')),
  description: yup.string().required(t('views.admin.shared.form.required')),
  point: yup
    .number()
    .positive()
    .integer(),
  bonus_type: yup.string().required(t('views.admin.shared.form.required')),
  starts_at: yup.date().required(t('views.admin.shared.form.required')),
  startsAtHr: yup.number().positive().integer().min(0).max(23),
  startsAtMin: yup.number().positive().integer().min(0).max(59),
  ends_at: yup.date().required(t('views.admin.shared.form.required')),
  endsAtHr: yup.number().positive().integer().min(0).max(23),
  endsAtMin: yup.number().positive().integer().min(0).max(59),
  image_url: yup
    .mixed()
    .notRequired()
    .test(
      'fileType',
      t('views.admin.shared.form.file_not_support'),
      (value) => {
        if (typeof value === 'string') return true

        return value ? SUPPORTED_FORMATS.includes(value.type) : true
      },
    ),
  cover_image: yup
    .mixed()
    .required(t('views.admin.shared.form.no_file_chosen')),
  quiz_choices: yup.array().of(yup.object().shape({
    answer: yup.string().required(),
    point: yup.number().required(),
    correct: yup.boolean()
  })),
})
