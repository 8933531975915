import React, { Dispatch, Fragment, SetStateAction } from 'react'
import { Grid, Button, Box, Text, Flex } from '@chakra-ui/react'
import { get } from 'lodash'
import { Dayjs } from 'dayjs'

import { JP_DATES } from '../constants'

import { CalendarBySiteItem } from '../types'
import { t } from '@/lib/helpers'

type CalendarItem = {
  date: number
  dateString: string
  text?: string
  style: string
}

type Props = {
  data: CalendarBySiteItem[]
  curMonth: Dayjs
  setMonth: Dispatch<SetStateAction<Dayjs>>
}

export const Calendar = (props: Props) => {
  const { data, curMonth, setMonth } = props

  const render = () => {
    // get first day of the month
    let startOfMonth = curMonth.date(1)
    let startOfNextMonth = startOfMonth.add(1, 'month')

    // To know number to fill before itself
    const orderInWeekOfFirstDayInWeek = startOfMonth.day()
    // To know number to fill after itself
    const orderInWeekOfLastDayInWeek = startOfNextMonth.date(0).day()

    let lastMonth = startOfMonth.subtract(orderInWeekOfFirstDayInWeek, 'day')

    const days: CalendarItem[] = []

    // Last Month
    for (let i = 0; i < orderInWeekOfFirstDayInWeek; i += 1) {
      days.push({
        date: lastMonth.date(),
        dateString: lastMonth.toString(),
        style: 'diffMonthStyle',
      })

      lastMonth = lastMonth.add(1, 'day')
    }

    const numDaysInCurrentMonth = startOfMonth.add(1, 'month').date(0).date()

    // Current Month
    for (let i = 1; i <= numDaysInCurrentMonth; i += 1) {
      const dayOfWeek = startOfMonth.day()
      const dayOfMonth = startOfMonth.date()
      const weekOfMonth = Math.ceil((dayOfMonth - 1 - dayOfWeek) / 7)

      const key = startOfMonth.format('YYYY-MM-DD')

      // check if is the odd or even week in month!
      // odd => lightGreenStyle , even normal style
      const style = startOfMonth.isSame(new Date(), 'day')
        ? 'todayStyle'
        : weekOfMonth % 2 === 0
        ? 'lightGreenStyle'
        : ''

      days.push({
        date: dayOfMonth,
        dateString: key,
        text: get(data, key, []).toString(),
        style,
      })

      startOfMonth = startOfMonth.add(1, 'day')
    }

    // Next Month
    for (let i = 1; i <= 6 - orderInWeekOfLastDayInWeek; i += 1) {
      days.push({
        date: startOfNextMonth.date(),
        dateString: startOfNextMonth.toString(),
        style: 'diffMonthStyle',
      })

      startOfNextMonth = startOfNextMonth.add(1, 'day')
    }

    return days.map((day) => {
      return (
        <Box
          key={day.dateString}
          sx={{ ...basicStyle, ...calendarStyle[day.style] }}
        >
          <Text>{day.date}</Text>
          <Text>{day.text}</Text>
        </Box>
      )
    })
  }

  return (
    <Grid
      sx={{
        gridTemplateColumns: 'repeat(7, 1fr)',
        gridTemplateRows: '40px 56px',
        gridAutoRows: '96px',
        borderLeft: '1px solid',
        borderColor: 'gray.200',
      }}
    >
      <Grid
        sx={{
          gridColumn: '1/-1',
          gridTemplateColumns: '1fr 12fr 1fr',
          alignItems: 'center',
          bg: 'brand.50',
          borderBottom: '1px solid',
          borderBottomColor: 'brand.200',
        }}
      >
        <Button
          sx={{ borderRadius: 'none' }}
          onClick={() => {
            setMonth(curMonth.subtract(1, 'month'))
          }}
        >
          {t('views.admin.calendars.calendar_grid.previous')}
        </Button>
        <Text
          sx={{
            textAlign: 'center',
            color: 'brand.800',
            fontWeight: 700,
            fontSize: '16px',
          }}
        >
          {curMonth.format('MMM YYYY')}
        </Text>
        <Button
          sx={{ borderRadius: 'none' }}
          onClick={() => {
            setMonth(curMonth.add(1, 'month'))
          }}
        >
          {t('views.admin.calendars.calendar_grid.next')}
        </Button>
      </Grid>

      <Fragment>
        {JP_DATES.map((d) => (
          <Flex
            key={d}
            sx={{
              p: '16px',
              fontWeight: 700,
              alignItems: 'center',
              borderBottom: '1px solid',
              borderColor: 'gray.100',
              _notLast: {
                borderRight: '1px solid',
                borderColor: 'gray.100',
              },
            }}
          >
            {d}
          </Flex>
        ))}
      </Fragment>

      <Fragment>{render()}</Fragment>
    </Grid>
  )
}

const basicStyle = {
  fontSize: '16px',
  p: '16px',
  borderBottom: '1px solid',
  borderRight: '1px solid',
  borderColor: 'gray.200',
}

const calendarStyle = {
  todayStyle: {
    bg: 'brand.500',
    color: 'white',
    fontWeight: 700,
  },
  lightGreenStyle: {
    bg: 'brand.50',
  },
  diffMonthStyle: {
    bg: 'gray.100',
    color: 'gray.400',
  },
}
