import React from 'react'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { t } from '@/lib/helpers'

import { useCreateVisibleEcos } from '../mutations/createVisibleEcos'
import { VisibleEcosForm } from './VisibleEcosForm'

type Props = {
  disclosure: UseDisclosureProps
}

export const CreateVisibleEcosModal = (props: Props) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure
  const { mutate: createVisibleEcos } = useCreateVisibleEcos()
  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="480px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.forms.form.create')}
        </Text>
        <VisibleEcosForm
          onSubmitForm={(data) => {
            createVisibleEcos(data, { onSuccess: onClose })
          }}
        />
      </Box>
    </Modal>
  )
}
