import React, { ReactNode } from 'react'
import { Box, Grid } from '@chakra-ui/react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ThemeProvider } from '@/lib/styles'

import { Navbar } from './Navbar'

type Props = {
  children: ReactNode
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const ApplicationLayout = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Grid templateColumns="240px 1fr" pos="relative">
          <Box>
            <Box
              pos="sticky"
              top="0"
              left="0"
              h="100vh"
              w="240px"
              overflow="auto"
            >
              <Navbar />
            </Box>
          </Box>
          <Box bg="#fafafa" p={8}>
            <Box rounded="lg" bg="white" px={4} shadow="sm">
              {children}
            </Box>
          </Box>
        </Grid>
      </ThemeProvider>
      <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export const withApp = (Component) => (props) => {
  return (
    <ApplicationLayout>
      <Component {...props} />
    </ApplicationLayout>
  )
}
