import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { GarbagePayload } from '../types'
import { GARBAGES } from '../constants'

const uploadGarbage = async ({ file, city_id }: GarbagePayload) => {
  const formData = new FormData()

  formData.append('file', file)
  formData.append('city_id', String(city_id))

  await fetchAPI({
    method: 'post',
    path: '/garbages',
    data: formData,
  })
}

export const useUploadGarbage = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(uploadGarbage, {
    onSuccess: async () => {
      await queryClient.refetchQueries([GARBAGES])

      toast({
        description: t('views.admin.garbages.services.upload_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
