import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { FaqPayload } from '../types'
import { FAQS } from '../constants'

const uploadFaq = async ({ file }: FaqPayload) => {
  const formData = new FormData()

  formData.append('file', file)

  await fetchAPI({
    method: 'post',
    path: '/faqs',
    data: formData,
  })
}

export const useUploadFaq = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(uploadFaq, {
    onSuccess: async () => {
      await queryClient.refetchQueries([FAQS])

      toast({
        description: t('views.admin.faqs.services.upload_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
