import React from 'react'
import { startCase } from 'lodash'
import { Button, Grid, Input, Select } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormControl } from '@/features/forms/components'

import { AdminFormInput } from '../types'
import { ADMIN_LEVELS } from '../constants'

import { t } from '@/lib/helpers'

type Props = {
  defaultValues?: AdminFormInput
  onSubmitForm: (data: AdminFormInput) => void
  isLoading: boolean
}

export const AdminForm = (props: Props) => {
  const { defaultValues, onSubmitForm, isLoading } = props

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<AdminFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const onSubmit = (data: AdminFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gap: '24px 16px' }}>
        <FormControl
          id="email"
          label={t('views.admin.admins.index.email')}
          isRequired
          errorMsg={errors.email?.message}
        >
          <Input
            size="lg"
            placeholder={t('views.admin.admins.index.email')}
            ref={register}
            name="email"
            tabIndex={1}
            type="email"
          />
        </FormControl>

        <FormControl
          id="password"
          label={t('devise.registrations.form.password')}
          isRequired
          errorMsg={errors.password?.message}
        >
          <Input
            size="lg"
            placeholder={t('devise.registrations.form.password')}
            ref={register}
            name="password"
            type="password"
            tabIndex={1}
          />
        </FormControl>

        <FormControl
          id="confirmPassword"
          label={t('devise.registrations.form.password_confirmation')}
          isRequired
          errorMsg={errors.confirmPassword?.message}
        >
          <Input
            size="lg"
            placeholder={t('devise.registrations.form.password_confirmation')}
            ref={register}
            name="confirmPassword"
            type="password"
            tabIndex={1}
          />
        </FormControl>

        <FormControl
          id="level"
          label={t('views.admin.admins.index.level')}
          isRequired
          errorMsg={errors.level?.message}
        >
          <Select
            name="level"
            size="lg"
            ref={register}
            tabIndex={5}
            sx={{ fontSize: '16px' }}
          >
            {ADMIN_LEVELS.map((t) => (
              <option value={t} key={t}>
                {startCase(t)}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        isLoading={isSubmitting || isLoading}
        tabIndex={6}
      >
        {t('views.admin.admins.form.create')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  email: yup.string().email().required(t('views.shared.form.required')),
  password: yup
    .string()
    .min(10, t('devise.registrations.form.password_hint'))
    .required(t('views.shared.form.required')),
  confirmPassword: yup
    .string()
    .required(t('views.shared.form.required'))
    .oneOf(
      [yup.ref('password'), null],
      t('views.shared.form.passwords_must_match'),
    ),
  level: yup
    .string()
    .required(t('views.shared.form.required'))
    .oneOf(ADMIN_LEVELS),
})
