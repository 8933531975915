import { TableTrashButton } from '@/components/shared/Table'
import { FormControl } from '@/features/forms/components'
import { t } from '@/lib/helpers'
import { Button, Flex, Input, Radio, RadioGroup } from '@chakra-ui/react'
import React from 'react'
import {
  Control,
  DeepMap,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form'

type Props = {
  control: Control<any>
  register: ReturnType<typeof useForm>['register']
  errors: DeepMap<any, FieldError>
}

export const QuizChoices = ({
  control,
  register,
  errors,
}: Props) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `quiz_choices`,
  })
  const [correctIndex, setCorrectIndex] = React.useState<number>(fields.findIndex((field) => field.correct))

  const addQuizChoice = (focus = true) => {
    const newQuizChoice = {
      isNew: true,
      answer: '',
      point: '',
      correct: false
    }

    append(newQuizChoice, focus)
  }

  React.useEffect(() => {
    if (!fields.length) {
      addQuizChoice(false)
      addQuizChoice(false)
    }
  }, [fields])

  const handleRemove = (index: number) => {
    remove(index)

    if (correctIndex > index) {
      setCorrectIndex(correctIndex - 1)
    }
  }

  return (
    <Flex sx={{ flexDir: 'column', gap: '8px', gridColumn: '1/-1' }}>
      <RadioGroup value={correctIndex} onChange={(nextValue: string) => setCorrectIndex(Number(nextValue))}>
        {fields.map((field, index) => (
          <Flex key={field.id} sx={{ gap: '4px', alignItems: 'flex-end' }}>
            <FormControl
              id={`quiz_choices[${index}]`}
              label={t('views.admin.bonus_points.form.quiz_choices.option_number', {
                number: index + 1,
              })}
              isRequired
              errorMsg={
                errors?.quiz_choices?.options?.[index]?.answer
                  ?.message
              }
              sx={{ mb: '8px' }}
            >
              {!field.isNew && (
                <Input
                  type="hidden"
                  ref={register()}
                  name={`quiz_choices[${index}].id`}
                  defaultValue={field.id}
                />
              )}
              <Radio
                defaultChecked={field.correct}
                isRequired={false}
                required={false}
                value={index}
              >
                {t('views.admin.bonus_points.form.quiz_choices.correct_answer')}
              </Radio>
              <Input
                type="hidden"
                ref={register()}
                name={`quiz_choices[${index}].correct`}
                value={index === correctIndex ? 'true' : 'false'}
              />
              <Input
                size="lg"
                placeholder={t('views.admin.bonus_points.form.quiz_choices.answer_text', {
                  number: index + 1,
                })}
                ref={register()}
                name={`quiz_choices[${index}].answer`}
                defaultValue={field.answer}
                sx={{ mt: '8px' }}
              />
              <Input
                size="lg"
                placeholder={t('views.admin.bonus_points.form.quiz_choices.point_number', {
                  number: index + 1,
                })}
                ref={register()}
                name={`quiz_choices[${index}].point`}
                defaultValue={field.point}
                sx={{ mt: '16px' }}
                type="number"
                min="0"
              />
            </FormControl>
            {fields.length > 2 && (
              <TableTrashButton
                ariaLabel={'delete option'}
                onClick={() => handleRemove(index)}
                sx={{ mb: '9px' }}
              />
            )}
          </Flex>
        ))}
        {fields.length < 3 && (
          <Button onClick={() => addQuizChoice()} sx={{ mt: '8px' }} variant="outline">
            {t('views.admin.bonus_points.form.quiz_choices.add_option')}
          </Button>
        )}
      </RadioGroup>
    </Flex>
  )
}
