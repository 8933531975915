import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { LOCATIONS, API_PATH } from '../constants'

const importLocations = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  await fetchAPI({
    method: 'post',
    path: `${API_PATH}/import`,
    data: formData,
  })
}

export const useImportLocations = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(importLocations, {
    onSuccess: async () => {
      await queryClient.refetchQueries([LOCATIONS])

      toast({
        description: t('views.admin.campaigns.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
