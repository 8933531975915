import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { AppConfig } from '../types'
import { Meta } from '@/features/filters/types'

import { APP_CONFIGS } from '../constants'

type AppConfigsResponse = {
  data: AppConfig[]
  meta: Meta
}

const getAppConfigs = async (params: Meta): Promise<AppConfigsResponse> => {
  const response = await fetchAPI({
    path: `/app_configs`,
    params,
  })

  const data = get(response, 'data.app_configs', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useAppConfigs = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: AppConfigsResponse
}) => {
  return useQuery<AppConfigsResponse, AxiosError>({
    queryKey: [APP_CONFIGS, params],
    queryFn: () => getAppConfigs(params),
    keepPreviousData: true,
    initialData,
  })
}
