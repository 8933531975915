import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { Message } from '../types'
import { Meta } from '@/features/filters/types'

import { MESSAGES } from '../constants'

type MessageResponse = {
  data: Message[]
  meta: Meta
}

const getMessages = async (params: Meta): Promise<MessageResponse> => {
  const response = await fetchAPI({
    path: `/messages`,
    params,
  })

  const data = get(response, 'data.messages', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useMessages = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: MessageResponse
}) => {
  return useQuery<MessageResponse, AxiosError>({
    queryKey: [MESSAGES, params],
    queryFn: () => getMessages(params),
    keepPreviousData: true,
    initialData,
  })
}
