import React, { Fragment, useMemo, useState } from 'react'
import get from 'lodash/get'
import {
  Icon,
  IconButton,
  Image,
  HStack,
  useDisclosure,
} from '@chakra-ui/react'
import { FiDownload, FiExternalLink } from 'react-icons/fi'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableEditButton,
  TableTrashButton,
  TableToggleApproveButton,
} from '@/components/shared/Table'

import { EditQRCodeCategoryFormModal } from '@/features/qrcode_categories/components'
import { QRCodeCategory } from '@/features/qrcode_categories/types'
import { MarkerColorLabel } from '@/features/qrcode_categories/components'

import {
  useDeleteQRCodeCategory,
  useUpdateQRCodeCategoryEnable,
} from '@/features/qrcode_categories/mutations'

type Props = {
  data: QRCodeCategory[]
}

export const QRCodeCategoryTable = ({ data }: Props) => {
  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const [actionData, setActionData] = useState<QRCodeCategory>()
  const { mutateAsync: deleteQRCodeCategory } = useDeleteQRCodeCategory()
  const { mutate: updateQRCodeCategoryEnable } = useUpdateQRCodeCategoryEnable()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.qrcode_categories.table.name'),
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.qrcode_categories.table.point'),
        accessor: 'point',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.qrcode_categories.table.marker_color'),
        accessor: 'marker_hue',
        disableSortBy: true,
        Cell: (cellProps) => {
          return <MarkerColorLabel value={get(cellProps, 'value')} />
        },
      },
      {
        Header: t('views.admin.qrcode_categories.table.url'),
        accessor: 'url',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.qrcode_categories.table.number_of_scans'),
        accessor: 'number_of_scans',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.shared.table.created_at'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.qrcode_categories.table.qrcode'),
        accessor: 'qrcode_image',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return <Image w="100px" src={`data:image/png;base64, ${value}`} />
        },
      },
      {
        Header: t('views.admin.qrcode_categories.table.type'),
        accessor: 'kind',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id, enabled } = row

          const enableText = enabled ? 'disable' : 'enable'

          return (
            <HStack spacing="2px">
              <IconButton
                as="a"
                href={`/admin/qrcode_categories/${id}/export.csv`}
                aria-label={'export'}
                icon={
                  <Icon
                    as={FiExternalLink}
                    sx={{ fontSize: '18px' }}
                  />
                }
                variant="ghost"
                sx={{ minW: '30px', h: '30px', color: 'gray.400' }}
              />
              <IconButton
                as="a"
                href={`data:image/png;base64, ${row.qrcode_image}`}
                aria-label={t('views.admin.qrcode_categories.table.download')}
                download={`${row.name}-qrcode.png`}
                variant="ghost"
                icon={
                  <Icon
                    as={FiDownload}
                    sx={{ color: 'gray.400', fontSize: '18px' }}
                  />
                }
                sx={{
                  minW: '30px',
                  h: '30px',
                  _hover: { bg: 'gray.50' },
                }}
              />

              <TableEditButton
                ariaLabel={'edit'}
                onClick={() => {
                  setActionData(row)
                  onOpen()
                }}
              />
              <TableTrashButton
                ariaLabel={'delete'}
                onClick={() => {
                  if (
                    confirm(t('views.admin.challenges.table.confirm_delete'))
                  ) {
                    deleteQRCodeCategory(row.id)
                  }
                }}
              />
              <TableToggleApproveButton
                ariaLabel={`${enableText} QR Code`}
                title={`${enableText} QR Code`}
                onClick={() => {
                  if (
                    confirm(
                      t(`views.admin.qrcode_categories.table.${enableText}_qrcode`),
                    )
                  ) {
                    updateQRCodeCategoryEnable(id)
                  }
                }}
                isOn={enabled}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      <EditQRCodeCategoryFormModal
        disclosure={disclosure}
        initialData={actionData}
      />
    </Fragment>
  )
}
