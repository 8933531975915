import React, { Fragment, useCallback } from 'react'
import {
  Box,
  Button,
  Center,
  Icon,
  Image,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react'
import { useDropzone } from 'react-dropzone'
import { FiTrash2 } from 'react-icons/fi'

import { dashedBorder } from '@/components/shared/styles'

import { useMemoImage } from '@/lib/hooks'
import { t } from '@/lib/helpers'

type Props = {
  sx?: SystemStyleObject
  file: File
  onChange: (files: File[]) => void
}

export const BannerInput = (props: Props) => {
  const { sx, onChange, file } = props

  const onDrop = useCallback((file) => {
    onChange(file[0])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['.png', '.jpg', '.jpeg'],
    multiple: false,
  })

  const memoImage = useMemoImage(file)

  return (
    <Center
      sx={{
        pos: 'relative',
        h: '205px',
        p: '8px',
        ...sx,
        ...(memoImage
          ? {
              border: '1px solid',
              borderColor: 'brand.500',
            }
          : {
              backgroundImage: dashedBorder,
            }),
      }}
    >
      {memoImage ? (
        <Fragment>
          <Icon
            as={FiTrash2}
            sx={{
              color: 'red.500',
              pos: 'absolute',
              top: '-24px',
              right: 0,
              fontSize: '16px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onChange(null)
            }}
          />
          <Image src={memoImage} sx={{ maxW: '100%', maxH: '100%' }} />
        </Fragment>
      ) : (
        <Box {...getRootProps()} sx={{ outline: 'none', cursor: 'pointer' }}>
          <input {...getInputProps()} />
          <Text
            sx={{
              textAlign: 'center',
              color: 'gray.200',
              fontStyle: 'italic',
              mb: '24px',
              mt: '32px',
            }}
          >
            {t('views.admin.shared.form.no_file_chosen')}
            <br />
            {t('views.admin.shared.form.support_format')}
          </Text>

          <Button variant="outline" sx={{ w: '245px', fontSize: '14px' }}>
            {t('views.admin.shared.form.select_image')}
          </Button>
        </Box>
      )}
    </Center>
  )
}
