import React, { Fragment } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { BonusPoint } from '@/features/bonus_points/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { BonusPointsTable } from './BonusPointsTable'
import { CreateBonusPointFormModal } from '@/features/bonus_points/components'

import { useMeta } from '@/features/filters'
import { useBonusPoints } from '@/features/bonus_points/queries'
import { t } from '@/lib/helpers'

type Props = {
  bonus_points: BonusPoint[]
  meta: Meta
}

function BonusPointsPage(props: Props) {
  const { bonus_points, meta: initialMeta } = props
  const { meta, setMeta } = useMeta(initialMeta)
  const { data: bonusPoints } = useBonusPoints({
    initialData: { data: bonus_points, meta: initialMeta },
    params: meta,
  })

  const { onOpen, isOpen, onClose } = useDisclosure()

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.bonus_points.index.title')}
          addons={
            <Button onClick={onOpen} sx={{ h: '48px', w: '148px' }}>
              {t('views.admin.bonus_points.index.new')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <BonusPointsTable data={bonusPoints.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <CreateBonusPointFormModal isOpen={isOpen} onClose={onClose} />
    </Fragment>
  )
}

export default withApp(BonusPointsPage)
