import React, { useMemo } from 'react'
import { get, startCase } from 'lodash'
import { HStack } from '@chakra-ui/react'

import { Admin, AdminLevel } from '@/features/admins/types'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableToggleApproveButton,
  TableTrashButton,
} from '@/components/shared/Table'

import {
  useDeleteAdmin,
  useUpdateAdminLevel,
} from '@/features/admins/mutations'

type Props = {
  data: Admin[]
}

export const AdminTable = ({ data }: Props) => {
  const { mutate: deleteAdmin } = useDeleteAdmin()
  const { mutate: updateAdminLevel } = useUpdateAdminLevel()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.admins.index.email'),
        accessor: 'email',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.admins.index.level'),
        accessor: 'level',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return startCase(value)
        },
      },
      {
        Header: t('views.admin.admins.index.created_at'),
        accessor: 'created_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (cellProps) => {
          const { id, level } = get(cellProps, 'row.original')

          const isPending = level === AdminLevel.Pending

          return (
            <HStack spacing="2px">
              {level !== AdminLevel.SystemAdmin && (
                <TableToggleApproveButton
                  ariaLabel={`${isPending ? 'approve' : 'disable'} admin`}
                  title={`${isPending ? 'approve' : 'disable'} admin`}
                  onClick={() => {
                    updateAdminLevel({
                      id,
                      level: isPending ? AdminLevel.Admin : AdminLevel.Pending,
                    })
                  }}
                  isOn={isPending}
                />
              )}
              <TableTrashButton
                ariaLabel={'delete admin'}
                onClick={() => {
                  if (confirm(t('views.admin.admins.index.confirm_delete'))) {
                    deleteAdmin(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return <DataTable data={memoizedData} columns={columns} />
}
