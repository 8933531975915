import { FormControl } from '@/features/forms/components'
import { t } from '@/lib/helpers'
import { Button, Grid, Input } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { MAX } from '../constants'
import { LinkFormInput } from '../types'
import { QuestionFields } from './QuestionFields'

type Props = {
  defaultValues?: Partial<LinkFormInput>
  onSubmitForm: (data: LinkFormInput) => void
  isLoading: boolean
}

export const LinkFormForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, isLoading } = props

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm<LinkFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: LinkFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gridTemplateColumns: 'repeat(1, 1fr)', gap: '32px' }}>
        <Grid sx={{ gridTemplateColumns: 'repeat(1, 1fr)', gap: '24px' }}>
          <FormControl
            id="title"
            label={t('views.admin.forms.form.title')}
            isRequired
            errorMsg={errors?.title?.message}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.forms.form.title')}
              ref={register}
              name="title"
            />
          </FormControl>
          <FormControl
            id="title"
            label={t('views.admin.forms.form.description')}
            isRequired
            errorMsg={errors?.description?.message}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.forms.form.description')}
              ref={register}
              name="description"
            />
          </FormControl>

          <QuestionFields
            control={control}
            register={register}
            errors={errors}
          />
        </Grid>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        isLoading={isSubmitting || isLoading}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.forms.form.create')
          : t('views.admin.forms.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(t('views.admin.shared.form.required')).max(50),
  description: yup
    .string()
    .required(t('views.admin.shared.form.required'))
    .max(200),
  questions: yup
    .array(
      yup.object({
        question_id: yup.string().nullable(),
        title: yup
          .string()
          .required(t('views.admin.shared.form.required'))
          .max(200),
        question_type: yup
          .string()
          .required(t('views.admin.shared.form.required')),
        options: yup
          .array(
            yup.object({
              text: yup
                .string()
                .required(t('views.admin.shared.form.required'))
                .max(200),
            }),
          )
          .max(MAX.OPTION)
          .test(
            'question_option_by_type',
            t('views.admin.shared.form.required'),
            (value, testContext) =>
              testContext.parent.question_type === 'answer_text' ||
              value.length > 1,
          ),
        required: yup.boolean(),
      }),
    )
    .ensure()
    .min(1)
    .max(MAX.QUESTION),
})
