import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

type ExportStatusResponse = {
  calculate: boolean
  ready: boolean
}

const getExportStatus = async (id: number): Promise<ExportStatusResponse> => {
  const response = await fetchAPI({
    path: `/forms/${id}/export_status`,
  })

  return response.data
}

export const useExportStatus = ({ id }: { id?: number }) => {
  return useQuery<ExportStatusResponse, AxiosError>({
    queryKey: [id],
    queryFn: () => getExportStatus(id),
    keepPreviousData: true,
  })
}
