import React from 'react'
import { noop } from 'lodash'
import { Box, Flex, Grid, IconButton, Icon, Button } from '@chakra-ui/react'
import {
  CgChevronLeft,
  CgChevronRight,
  CgChevronDoubleLeft,
  CgChevronDoubleRight,
} from 'react-icons/cg'

const BUTTON_TO_SHOW = 6

type Props = {
  page: number
  total: number
  onChangePage: (number) => void
  per: number
}

export const Pagination = (props: Props) => {
  const { page, total, onChangePage = noop, per = 15 } = props

  if (total <= per) return null

  const totalPage = Math.ceil(total / per)

  const renderButton = () => {
    if (totalPage <= BUTTON_TO_SHOW) {
      return Array(totalPage)
        .fill(undefined)
        .map((_, idx) => {
          const isActive = page === idx + 1

          return (
            <Button
              key={`_${idx}`}
              {...buttonStyle(isActive)}
              onClick={isActive ? noop : () => onChangePage(idx + 1)}
            >
              {idx + 1}
            </Button>
          )
        })
    }

    const pages = []

    if (page + BUTTON_TO_SHOW - totalPage >= 2) {
      const numberVisibleInRange = totalPage - BUTTON_TO_SHOW
      pages.push(numberVisibleInRange)
      pages.push('. . .')

      for (let i = totalPage - 4; i <= totalPage; i += 1) {
        pages.push(i)
      }
    } else {
      if (page === 1) {
        for (let i = page; i <= page + 2; i += 1) {
          pages.push(i)
        }
      } else {
        for (let i = page - 1; i <= page + 1; i += 1) {
          pages.push(i)
        }
      }

      pages.push('. . .')

      for (let i = totalPage - 2; i <= totalPage; i += 1) {
        pages.push(i)
      }
    }

    return pages.map((p) => {
      if (p === '. . .') {
        return (
          <Box fontWeight="300" key={p}>
            {p}
          </Box>
        )
      }

      return (
        <Button
          key={p}
          {...buttonStyle(page === p)}
          onClick={() => onChangePage(p)}
        >
          {p}
        </Button>
      )
    })
  }

  return (
    <Flex justify="center">
      <Grid gridAutoFlow="column" gap="8px" alignItems="center">
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronDoubleLeft} />}
          isDisabled={page === 1}
          onClick={() => onChangePage(1)}
          aria-label="first-page"
        />
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronLeft} />}
          isDisabled={page === 1}
          onClick={() => onChangePage(page - 1)}
          aria-label="previous-page"
        />

        {renderButton()}

        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronRight} />}
          isDisabled={page >= totalPage}
          onClick={() => onChangePage(page + 1)}
          aria-label="next-page"
        />
        <IconButton
          {...arrowButtonStyle}
          icon={<Icon as={CgChevronDoubleRight} />}
          isDisabled={page >= totalPage}
          onClick={() => onChangePage(totalPage)}
          aria-label="last-page"
        />
      </Grid>
    </Flex>
  )
}

const buttonStyle = (isActive: boolean) => {
  return {
    boxSize: '40px',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '8px',
    variant: 'outline',
    color: 'gray.900',
    borderColor: 'gray.100',
    _hover: {
      backgroundColor: 'brand.50',
    },
    ...(isActive && {
      color: 'white',
      variant: 'solid',
      cursor: 'unset',
      fontWeight: 700,
      _hover: {
        backgroundColor: 'brand.500',
        color: 'white',
      },
    }),
  }
}

const arrowButtonStyle = {
  boxSize: '40px',
  minW: '0',
  fontSize: '28px',
  variant: 'outline',
  color: 'brand.500',
  borderRadius: '8px',
  borderColor: 'gray.100',
}
