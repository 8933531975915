import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { LOCATIONS, API_PATH } from '../constants'
import { LocationFormInput } from '../types'

const createLocation = async (data: LocationFormInput) => {
  await fetchAPI({
    method: 'post',
    path: API_PATH,
    data,
  })
}

export const useCreateLocation = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createLocation, {
    onSuccess: async () => {
      await queryClient.refetchQueries([LOCATIONS])

      toast({
        description: t('views.admin.campaigns.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
