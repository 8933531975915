import React, { Fragment, useMemo, useState } from 'react'
import { get } from 'lodash'
import { Button, Link, HStack, useDisclosure } from '@chakra-ui/react'

import { Campaign } from '@/features/campaigns/types'

import { formatDateTime, t } from '@/lib/helpers'

import {
  DataTable,
  TableEditButton,
  TableToggleApproveButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { EditCampaignFormModal } from '@/features/campaigns/components'
import {
  useDeleteCampaign,
  useUpdateCampaignEnable,
} from '@/features/campaigns/mutations'

type Props = {
  data: Campaign[]
}

export const CampaignTable = ({ data }: Props) => {
  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const [actionData, setActionData] = useState<Campaign>()
  const { mutateAsync: deleteCampaign } = useDeleteCampaign()
  const { mutate: updateCampaignEnable } = useUpdateCampaignEnable()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.campaigns.index.campaign_title'),
        accessor: 'title',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original') as Campaign
          const { id } = row
          const value = get(cellProps, 'value')

          return (
            <Link href={`/admin/campaigns/${id}/submissions`} title={value}>
              {value}
            </Link>
          )
        },
      },
      {
        Header: t('views.admin.campaigns.index.point'),
        accessor: 'point',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.campaigns.index.tag'),
        accessor: 'tag.name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.campaigns.index.release_at'),
        accessor: 'released_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.campaigns.index.release_end_at'),
        accessor: 'ended_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id, enabled, campaign_type } = row

          const enableText = enabled ? 'disable' : 'enable'

          return (
            <HStack spacing="2px" justifyContent="flex-end">
              {campaign_type !== 'third_campaign' && (
                <Button
                  as="a"
                  size="sm"
                  variant="outline"
                  href={`/admin/campaigns/${id}/export.csv`}
                  mr={4}
                  download
                >
                  export csv
                </Button>
              )}
              <TableEditButton
                ariaLabel={'edit campaign'}
                onClick={() => {
                  setActionData(row)
                  onOpen()
                }}
              />
              <TableToggleApproveButton
                ariaLabel={`${enableText} campaign`}
                title={`${enableText} campaign`}
                onClick={() => {
                  if (
                    confirm(
                      t(`views.admin.campaigns.table.${enableText}_campaign`),
                    )
                  ) {
                    updateCampaignEnable(id)
                  }
                }}
                isOn={enabled}
              />

              <TableTrashButton
                ariaLabel={'delete campaign'}
                onClick={() => {
                  if (
                    confirm(t('views.admin.campaigns.table.confirm_delete'))
                  ) {
                    deleteCampaign(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      <EditCampaignFormModal disclosure={disclosure} initialData={actionData} />
    </Fragment>
  )
}
