import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { CITIES } from '../constants'

import { City } from '../types'
import { Meta } from '@/features/filters/types'

type CitiesResponse = {
  data: City[]
  meta: Meta
}

const getCities = async (params: Meta): Promise<CitiesResponse> => {
  const response = await fetchAPI({
    path: `/cities`,
    params,
  })

  const data = get(response, 'data.cities', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useCities = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: CitiesResponse
}) => {
  return useQuery<CitiesResponse, AxiosError>({
    queryKey: [CITIES, params],
    queryFn: () => getCities(params),
    keepPreviousData: true,
    initialData,
  })
}
