import styled from '@emotion/styled'

export const TableStyled = styled.table(({ theme }: any) => {
  return {
    width: '100%',
    textAlign: 'left',
    color: theme.colors.gray[600],
  }
})

export const ColumnHeader = styled.th(({ isSorted, canSort, theme }: any) => {
  return {
    boxShadow: `inset 0px -1px 0px ${theme.colors.brand[200]}`,
    padding: '12px 36px',
    textTransform: 'capitalize',
    fontSize: '12px',
    fontWeight: 600,
    color: theme.colors.brand[800],
    backgroundColor: theme.colors.brand[50],

    ...(isSorted && {
      backgroundColor: theme.colors.gray[100],
      color: theme.colors.gray[900],
    }),

    ...(canSort && {
      '&:hover': {
        cursor: 'pointer',
        userSelect: 'none',
        color: theme.colors.gray[900],
        backgroundColor: theme.colors.gray[100],
      },
    }),
  }
})

export const Cell = styled.td(() => ({
  padding: '14px 36px',
  boxShadow: 'inset 0px -1px 0px #e8e8e8',
  transition: 'color 0.3s ease',
  verticalAlign: 'top',
}))
