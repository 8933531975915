import { formatDateTime } from '@/lib/helpers'
import { PostRankingsFormInput, RankingResponse } from './types'

export const transformDataIntoParam = (data: PostRankingsFormInput) => {
  return {
    point_no1: data.point_no1,
    point_no2: data.point_no2,
    point_no3: data.point_no3,
    point_no4: data.point_no4_10,
    point_no5: data.point_no4_10,
    point_no6: data.point_no4_10,
    point_no7: data.point_no4_10,
    point_no8: data.point_no4_10,
    point_no9: data.point_no4_10,
    point_no10: data.point_no4_10,
    starts_at: formatDateTime(data.starts_at, 'YYYY-MM-DD'),
    ends_at: formatDateTime(data.ends_at, 'YYYY-MM-DD'),
  }
}
export const transformDataIntoForm = (
  data: RankingResponse,
): PostRankingsFormInput => {
  return {
    starts_at: formatDateTime(data.starts_at, 'YYYY-MM-DD'),
    ends_at: formatDateTime(data.ends_at, 'YYYY-MM-DD'),
    point_no1: data.point_no1,
    point_no2: data.point_no2,
    point_no3: data.point_no3,
    point_no4_10: data.point_no4_10,
  }
}

export const getErrorMessages = (error) => {
  const ERROR_MSG = {
    starts_at: `Start date can be select only current date or future.`,
    ends_at: `Please, select end date after the start date.`,
  }
  const data = Object.keys(error.response.data)

  return ERROR_MSG[data[0]]
}
