import React from 'react'
import { Input } from '@chakra-ui/react'
import debounce from 'lodash/debounce'

import { useUpdateAppConfig } from '../mutations'
import { AppConfig } from '../types'

type Props = {
  data: AppConfig
}

export const AppConfigInput = (props: Props) => {
  const { data } = props
  const { mutate: updateAppConfig, isLoading } = useUpdateAppConfig()

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateAppConfig({
      id: data.id,
      value: e.target.value,
    })
  }

  return (
    <Input
      isDisabled={isLoading}
      defaultValue={`${data.value}`}
      onChange={debounce(onChange, 600)}
      colorScheme="green"
    />
  )
}
