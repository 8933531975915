import React from 'react'
import { isEmpty } from 'lodash'
import {
  Button,
  Input,
  Textarea,
  VStack,
  HStack,
  Select,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { FormControl } from '@/features/forms/components'
import { LocationFormInput } from '../types'

import { t } from '@/lib/helpers'
import { QRCodeCategory } from '@/features/qrcode_categories/types'

type Props = {
  defaultValues?: LocationFormInput
  onSubmitForm: (data: LocationFormInput) => void
  qrCategories: QRCodeCategory[]
  isLoading: boolean
}

export const LocationForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, isLoading, qrCategories } = props

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<LocationFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const onSubmit = (data: LocationFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4}>
        <FormControl
          id="name"
          label={t('views.admin.locations.form.name')}
          isRequired
          errorMsg={errors.name?.message}
        >
          <Input size="lg" ref={register} name="name" autoFocus />
        </FormControl>

        <HStack spacing={4} w="full">
          <FormControl
            id="latitude"
            label={t('views.admin.locations.form.latitude')}
            isRequired
            errorMsg={errors.latitude?.message}
          >
            <Input size="lg" ref={register} name="latitude" />
          </FormControl>

          <FormControl
            id="longitude"
            label={t('views.admin.locations.form.longitude')}
            isRequired
            errorMsg={errors.longitude?.message}
          >
            <Input size="lg" ref={register} name="longitude" />
          </FormControl>
        </HStack>

        <FormControl
          id="address"
          label={t('views.admin.locations.form.address')}
          isRequired
          errorMsg={errors.address?.message}
        >
          <Textarea size="lg" rows={3} ref={register} name="address" />
        </FormControl>

        <FormControl
          id="qrcode_category_id"
          label={t('views.admin.locations.form.qrcode_category')}
          sx={{ gridColumn: '1/-1' }}
          isRequired
          errorMsg={errors.qrcode_category_id?.message}
        >
          <Select
            size="lg"
            ref={register}
            name="qrcode_category_id"
            tabIndex={5}
          >
            {qrCategories.map(({ id, name }) => (
              <option value={id} key={id}>
                {name}
              </option>
            ))}
          </Select>
        </FormControl>
      </VStack>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        size="lg"
        isLoading={isSubmitting || isLoading}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.shared.form.create')
          : t('views.admin.shared.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  name: yup.string().required(t('views.admin.shared.required')),
  latitude: yup.string().required(t('views.admin.shared.required')),
  longitude: yup.string().required(t('views.admin.shared.required')),
  address: yup.string().required(t('views.admin.shared.required')),
  qrcode_category_id: yup.string().required(t('views.admin.shared.required')),
})
