import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'
import {
  EditHolidayModal,
  HolidayShowModal,
} from '@/features/holidays/components'
import { useDeleteHoliday } from '@/features/holidays/mutations'
import { Holiday } from '@/features/holidays/types'
import { CityCollection, CityCollectionSiteCollection } from '@/features/types'
import { formatDateTime, t } from '@/lib/helpers'
import { HStack, Link, useDisclosure } from '@chakra-ui/react'
import { get } from 'lodash'
import React, { Fragment, useMemo, useState } from 'react'

type Props = {
  data: Holiday[]
  cities: CityCollection
  sites: CityCollectionSiteCollection
}

export const HolidayTable = ({ data, cities, sites }: Props) => {
  const [actionData, setActionData] = useState<Holiday>()
  const [editMode, setEditMode] = useState(false)

  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const { mutate: deleteHoliday } = useDeleteHoliday()

  const columns = useMemo(() => {
    const LinkCell = (cellProps, transform = (value) => value) => {
      const value = get(cellProps, 'value')
      const originalData = get(cellProps, 'row.original')

      return (
        <Link
          onClick={() => {
            setActionData(originalData)
            setEditMode(false)
            onOpen()
          }}
          sx={{
            _hover: {
              color: 'brand.500',
            },
          }}
        >
          {transform(value) || '-'}
        </Link>
      )
    }

    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
        Cell: (cellProps) => LinkCell(cellProps),
      },
      {
        Header: t('views.admin.holidays.index.holiday_title'),
        accessor: 'title',
        disableSortBy: true,
        Cell: (cellProps) => LinkCell(cellProps),
      },
      {
        Header: t('views.admin.holidays.index.city'),
        accessor: 'city_names',
        disableSortBy: true,
        Cell: (cellProps) => LinkCell(cellProps, (value) => value.toString()),
      },
      {
        Header: t('views.admin.holidays.index.collection_site'),
        accessor: 'collection_site_names',
        disableSortBy: true,
        Cell: (cellProps) => LinkCell(cellProps, (value) => value.toString()),
      },
      {
        Header: t('views.admin.holidays.index.release_date'),
        accessor: 'starts_at',
        disableSortBy: true,
        Cell: (cellProps) =>
          LinkCell(cellProps, (value) => formatDateTime(value, 'YYYY-MM-DD')),
      },
      {
        Header: t('views.admin.holidays.index.release_end_date'),
        accessor: 'ends_at',
        disableSortBy: true,
        Cell: (cellProps) =>
          LinkCell(cellProps, (value) => formatDateTime(value, 'YYYY-MM-DD')),
      },
      {
        Header: t('views.admin.shared.table.edit_delete'),
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id } = row

          return (
            <HStack spacing="2px">
              <TableEditButton
                ariaLabel={'edit holiday'}
                onClick={() => {
                  setActionData(row)
                  setEditMode(true)
                  onOpen()
                }}
              />
              <TableTrashButton
                ariaLabel={'delete holiday'}
                onClick={() => {
                  if (confirm('Confirm Delete ?')) {
                    deleteHoliday(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      {editMode ? (
        <EditHolidayModal
          disclosure={disclosure}
          cities={cities}
          sites={sites}
          initialData={actionData}
        />
      ) : (
        <HolidayShowModal
          disclosure={disclosure}
          cities={cities}
          sites={sites}
          holiday={actionData}
          onEdit={() => setEditMode(true)}
        />
      )}
    </Fragment>
  )
}
