import React, { Fragment, useMemo, useState } from 'react'
import { get, startCase } from 'lodash'
import { Select, Link, useDisclosure } from '@chakra-ui/react'

import { Shipping, ShippingStatus } from '@/features/shippings/types'

import { DataTable, TableTrashButton } from '@/components/shared/Table'
import {
  useDeleteShipping,
  useUpdateShipping,
} from '@/features/shippings/mutations'
import { SHIPPING_STATUS } from '@/features/shippings/constants'
import { t } from '@/lib/helpers'
import { ShippingShowModal } from '@/features/shippings/components'

type Props = {
  data: Shipping[]
}

export const ShippingTable = ({ data }: Props) => {
  const { mutate: deleteShipping } = useDeleteShipping()
  const { mutate: updateShipping } = useUpdateShipping()

  const [actionData, setActionData] = useState<Shipping | null>(null)
  const disclosure = useDisclosure()

  const columns = useMemo(() => {
    const LinkCell = (cellProps) => {
      const value = get(cellProps, 'value')
      const originalData = get(cellProps, 'row.original')

      return (
        <Link
          onClick={() => {
            setActionData(originalData)
            disclosure.onOpen()
          }}
          sx={{
            _hover: {
              color: 'brand.500',
            },
          }}
        >
          {value || '-'}
        </Link>
      )
    }

    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
        Cell: LinkCell,
      },
      {
        Header: t('views.admin.shippings.index.email'),
        accessor: 'email',
        disableSortBy: true,
        Cell: LinkCell,
      },
      {
        Header: t('views.admin.shippings.index.full_name'),
        accessor: 'name',
        disableSortBy: true,
        Cell: LinkCell,
      },
      {
        Header: t('views.admin.shippings.index.tel_number'),
        accessor: 'phone_number',
        disableSortBy: true,
        Cell: LinkCell,
      },
      {
        Header: t('views.admin.shippings.index.prize'),
        accessor: 'prize',
        disableSortBy: true,
        Cell: LinkCell,
      },
      {
        Header: t('views.admin.shippings.index.deliver_status'),
        accessor: 'status',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original') as Shipping
          const { id, status } = row

          return (
            <Select
              size="sm"
              defaultValue={status}
              onChange={({ target: { value: selectedOption } }) => {
                if (selectedOption !== status) {
                  if (
                    confirm(t('views.admin.shippings.index.confirm_update'))
                  ) {
                    updateShipping({
                      id,
                      status: selectedOption as ShippingStatus,
                    })
                  }
                }
              }}
            >
              {SHIPPING_STATUS.map((stat) => (
                <option value={stat} key={stat}>
                  {startCase(stat)}
                </option>
              ))}
            </Select>
          )
        },
      },
      // {
      //   Header: 'Sending at',
      //   accessor: 'sending_at',
      //   disableSortBy: true,
      //   Cell: (cellProps) => {
      //     const value = get(cellProps, 'value')

      //     return formatDateTime(value)
      //   },
      // },
      // {
      //   Header: 'End at',
      //   accessor: 'ended_at',
      //   disableSortBy: true,
      //   Cell: (cellProps) => {
      //     const value = get(cellProps, 'value')

      //     return formatDateTime(value)
      //   },
      // },
      {
        Header: '',
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id } = row

          return (
            <TableTrashButton
              ariaLabel={'delete shipping'}
              onClick={() => {
                if (confirm(t('views.admin.shippings.index.confirm_delete'))) {
                  deleteShipping(id)
                }
              }}
            />
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />
      {actionData && (
        <ShippingShowModal shipping={actionData} disclosure={disclosure} />
      )}
    </Fragment>
  )
}
