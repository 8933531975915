import React from 'react'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'
import { get } from 'lodash'

import { Modal } from '@/components/shared/Modal'
import { PrizeForm } from './PrizeForm'

import { useUpdatePrize } from '../mutations'
import { Prize } from '../types'
import { transformPrizeIntoForm } from '../utils'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
  initialData?: Prize
}

export const EditPrizeModal = (props: Props) => {
  const { disclosure, initialData } = props

  const { isOpen, onClose } = disclosure

  const { mutate: updatePrize, isLoading } = useUpdatePrize()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="946px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.prize_exchanges.edit.title', {
            title: get(initialData, 'id'),
          })}
        </Text>
        <PrizeForm
          defaultValues={transformPrizeIntoForm(initialData)}
          isLoading={isLoading}
          onSubmitForm={(data) => {
            updatePrize(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
