import React from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { City } from '@/features/cities/types'
import { Meta } from '@/features/filters/types'

import { CityLayout } from '@/features/cities/components'
import { withApp } from '@/features/Layout'

import { Pagination } from '@/components/shared/Pagination'
import { CityTable } from './CityTable'

import { useMeta } from '@/features/filters'
import { useCities } from '@/features/cities/queries'
import { t } from '@/lib/helpers'

type Props = {
  cities: City[]
  meta: Meta
}

function CitiesPage(props: Props) {
  const { cities: initialCities, meta: initialMeta } = props

  const { meta, setMeta } = useMeta(initialMeta)

  const { data: cities } = useCities({
    initialData: { data: initialCities, meta: initialMeta },
    params: meta,
  })

  return (
    <CityLayout title={t('views.admin.cities.index.title')}>
      <Box sx={{ mb: '24px' }}>
        <CityTable data={cities.data} />
      </Box>

      <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
        <Pagination
          page={meta.page}
          per={meta.per}
          total={meta.total}
          onChangePage={(page) => {
            setMeta('page', page)
          }}
        />
      </Flex>
    </CityLayout>
  )
}

export default withApp(CitiesPage)
