import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { CITY_COLLECTION_SITES } from '../constants'

const deleteCityCollectionSite = async ({
  cityId,
  siteId,
}: {
  cityId: number
  siteId: number
}) => {
  await fetchAPI({
    path: `/cities/${cityId}/collection_sites/${siteId}`,
    method: 'delete',
  })
}

export const useDeleteCityCollectionSite = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(deleteCityCollectionSite, {
    async onSuccess() {
      await queryClient.refetchQueries([CITY_COLLECTION_SITES])

      toast({
        description: t(
          'views.admin.cities.collection_sites.index.delete_success',
        ),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
