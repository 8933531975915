import { Tag } from '@/features/tags/types'
import { LinkForm } from '../link_forms/types'

export type Challenge = {
  id: number
  challengeType: string
  title: string
  description: string
  point: number
  createdAt: string
  updatedAt: string
  releasedAt: string
  endedAt: string
  tag: Tag
  comment1: string
  comment2: string
  color1: string
  color2: string
  form?: Omit<LinkForm, 'questions'>
  rewarding_message?: string
  url?: string
}

export enum ChallengeType {
  Daily = 'daily',
  Post = 'post',
  QRCode = 'qrcode',
  Link = 'link',
}

export type ChallengeFormInput = {
  title: string
  description?: string
  challengeType: ChallengeType
  only_once: boolean
  point: string
  releasedAt: string
  releasedAtHr: string
  releasedAtMin: string
  endedAt: string
  endedAtHr: string
  endedAtMin: string
  tagId: number
  comment1: string
  comment2: string
  color1: string
  color2: string
  form_id: string
  rewarding_message?: string
  url?: string
}
