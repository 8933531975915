import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { Campaign } from '../types'
import { Meta } from '@/features/filters/types'

import { CAMPAIGNS } from '../constants'

type CampaignsResponse = {
  data: Campaign[]
  meta: Meta
}

const getCampaigns = async (params: Meta): Promise<CampaignsResponse> => {
  const response = await fetchAPI({
    path: `/campaigns`,
    params,
  })

  const data = get(response, 'data.campaigns', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useCampaigns = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: CampaignsResponse
}) => {
  return useQuery<CampaignsResponse, AxiosError>({
    queryKey: [CAMPAIGNS, params],
    queryFn: () => getCampaigns(params),
    keepPreviousData: true,
    initialData,
  })
}
