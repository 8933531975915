import React from 'react'

import { FormControl } from '@/features/forms/components'
import { ReactSelect, valueToOption } from '@/components/shared/ReactSelect'

import { useMarkerHues } from '../queries'

type Props = {
  value: string
  onChange: (value: string) => void
  label: string
  id?: string
  placeholder?: string
}

export const SelectMarkerColor = (props: Props) => {
  const { value, id, label, placeholder, onChange } = props
  const { data: markerHueOptions, isLoading } = useMarkerHues()

  if (isLoading) {
    return null
  }

  return (
    <FormControl id={id} label={label}>
      <ReactSelect
        placeholder={placeholder}
        options={markerHueOptions}
        onChange={(selectOption) => onChange(selectOption?.value)}
        value={valueToOption<string>(value, markerHueOptions)}
      />
    </FormControl>
  )
}
