import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { ADMINS } from '../constants'
import { AdminFormInput } from '../types'

const createAdmin = async (form: AdminFormInput) => {
  const { email, password, level } = form

  await fetchAPI({
    method: 'post',
    path: '/admins',
    data: {
      admin: {
        email,
        password,
        level,
      },
    },
  })
}

export const useCreateAdmin = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createAdmin, {
    onSuccess: async () => {
      await queryClient.refetchQueries([ADMINS])

      toast({
        description: t('views.admin.admins.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
