import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { CityOfficialUrlPayload } from '../types'
import { CITIES } from '../constants'

const updateOfficialLink = async ({ form, id }: CityOfficialUrlPayload) => {
  const formData = new FormData()
  formData.append('collection_sites_link', form.collection_sites_link ?? '')
  formData.append('official_link', form.official_link ?? '')

  await fetchAPI({
    method: 'put',
    path: `/cities/${id}`,
    data: formData,
  })
}

export const useUpdateOfficialLink = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateOfficialLink, {
    onSuccess: async () => {
      await queryClient.refetchQueries([CITIES])

      toast({
        description: t('views.admin.cities.services.edit_url_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
