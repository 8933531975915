import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { HOLIDAYS } from '../constants'
import { HolidayFormInput } from '../types'
import { transformHolidayInput } from '../utils'

const updateHoliday = async ({
  form,
  id,
}: {
  form: HolidayFormInput
  id: number
}) => {
  await fetchAPI({
    method: 'put',
    path: `/holidays/${id}`,
    data: transformHolidayInput(form),
  })
}

export const useUpdateHoliday = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateHoliday, {
    onSuccess: async () => {
      await queryClient.refetchQueries([HOLIDAYS])

      toast({
        description: t('views.admin.holidays.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
