type Route = {
  name: string
  pattern: string
}

export const routes: Route[] = [
  {
    name: 'index',
    pattern: '/admin',
  },
  {
    name: 'calendars',
    pattern: '/admin/calendars',
  },
  {
    name: 'cities',
    pattern: '/admin/cities',
  },
  {
    name: 'cityCollectionSites',
    pattern: '/admin/cities/[city_id]/collection_sites',
  },
  {
    name: 'cityGarbages',
    pattern: '/admin/cities/[city_id]/garbages',
  },
  {
    name: 'cityCollectionSiteCalendar',
    pattern:
      '/admin/cities/[city_id]/collection_sites/[collection_site_id]/calendars',
  },
  {
    name: 'garbages',
    pattern: '/admin/garbages',
  },
  {
    name: 'challenges',
    pattern: '/admin/challenges',
  },
  {
    name: 'campaigns',
    pattern: '/admin/campaigns',
  },
  {
    name: 'campaignSubmissions',
    pattern: '/admin/campaigns/[campaign_id]/submissions',
  },
  {
    name: 'prize_exchanges',
    pattern: '/admin/prize_exchanges',
  },
  {
    name: 'shippings',
    pattern: '/admin/shippings',
  },
  {
    name: 'articles',
    pattern: '/admin/articles',
  },
  {
    name: 'users',
    pattern: '/admin/users',
  },
  {
    name: 'posts',
    pattern: '/admin/posts',
  },
  {
    name: 'locations',
    pattern: '/admin/locations',
  },
  {
    name: 'forms',
    pattern: '/admin/forms',
  },
  {
    name: 'messages',
    pattern: '/admin/messages',
  },
  {
    name: 'admins',
    pattern: '/admin/admins',
  },
  {
    name: 'faqs',
    pattern: '/admin/faqs',
  },
  {
    name: 'tags',
    pattern: '/admin/tags',
  },
  {
    name: 'qrcode_categories',
    pattern: '/admin/qrcode_categories',
  },
  {
    name: 'app_configs',
    pattern: '/admin/app_configs',
  },
  {
    name: 'bonus_points',
    pattern: '/admin/bonus_points',
  },
  {
    name: 'holidays',
    pattern: '/admin/holidays',
  },
  {
    name: 'post_rankings',
    pattern: '/admin/post_rankings',
  },
  {
    name: 'visible_ecos',
    pattern: '/admin/visible_ecos',
  },
]
