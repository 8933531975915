import React from 'react'
import { get } from 'lodash'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { CollectionSiteForm } from './CollectionSiteForm'

import { CollectionSite } from '../types'

import { useUpdateCityCollectionSite } from '../mutations'
import { transformCollectionSiteIntoForm } from '../utils'

import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
  initialData: CollectionSite
}

export const EditCollectionSiteModal = (props: Props) => {
  const { disclosure, initialData } = props
  const { isOpen, onClose } = disclosure

  const { mutate: updateCityCollectionSite, isLoading } =
    useUpdateCityCollectionSite()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.cities.collection_sites.index.edit')}
        </Text>

        <CollectionSiteForm
          isLoading={isLoading}
          defaultValues={transformCollectionSiteIntoForm(initialData)}
          onSubmitForm={(data) => {
            updateCityCollectionSite(
              {
                form: data,
                city_id: get(initialData, 'city_id'),
                id: get(initialData, 'id'),
              },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
