import React from 'react'
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs'
import { Box, Center, useTheme } from '@chakra-ui/react'
import { isEmpty } from 'lodash'

import { LoadingOverlay } from './TableLoadingOverlay'
import { TableStyled, ColumnHeader, Cell } from './Table.styles'
import { TableProps } from './types'

import { t } from '@/lib/helpers'

export function Table(props: TableProps) {
  const { isLoading, renderNoData, tableInstance } = props
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    data,
  } = tableInstance

  const tableHeadersColumn = headerGroups[0].headers || []
  const { colors } = useTheme()

  return (
    <Box sx={{ position: 'relative' }}>
      {isLoading && <LoadingOverlay />}

      <TableStyled {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps()

            return (
              <tr key={headerGroupProps.key} {...headerGroupProps}>
                {headerGroup.headers.map((column) => {
                  const sortByToggleProps = column.getSortByToggleProps()
                  const headerProps = column.getHeaderProps(sortByToggleProps)
                  const { isSorted, canSort, isSortedDesc } = column

                  const minWidth = Number(column.minWidth)
                  const maxWidth = Number(column.maxWidth)

                  return (
                    <ColumnHeader
                      key={headerProps.key}
                      isSorted={isSorted}
                      canSort={canSort}
                      {...headerProps}
                    >
                      <Box
                        sx={{
                          ...(minWidth > 0 && { minW: minWidth }),
                          ...(maxWidth < 1000 && { maxW: maxWidth }),
                        }}
                      >
                        <Box
                          sx={{
                            ...(canSort && {
                              position: 'relative',
                              pr: '24px',
                              display: 'inline-flex',
                            }),
                          }}
                        >
                          {column.render('Header')}
                          {isSorted && (
                            <Center
                              ml="2"
                              sx={{
                                position: 'absolute',
                                right: '0',
                                top: '-3px',
                              }}
                            >
                              {isSortedDesc ? (
                                <BsArrowDownShort size="22px" />
                              ) : (
                                <BsArrowUpShort size="22px" />
                              )}
                            </Center>
                          )}
                        </Box>
                      </Box>
                    </ColumnHeader>
                  )
                })}
              </tr>
            )
          })}
        </thead>

        {isEmpty(data) ? (
          <tbody {...getTableBodyProps()}>
            <tr>
              <td colSpan={tableHeadersColumn.length}>
                {renderNoData ? (
                  renderNoData()
                ) : (
                  <Box sx={{ textAlign: 'center', p: '8', color: 'gray.900' }}>
                    {t('views.admin.shared.misc.no_data')}
                  </Box>
                )}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)

              const rowProps = row.getRowProps()

              return (
                <tr
                  key={rowProps.key}
                  css={{
                    '&:hover': {
                      backgroundColor: '#fafbfc',
                      '.chakra-link': {
                        color: colors.brand[400],
                        textDecoration: 'underline',
                      },
                    },
                  }}
                  {...rowProps}
                >
                  {row.cells.map((cell) => {
                    const cellProps = cell.getCellProps()
                    const minWidth = Number(cell.column.minWidth)
                    const maxWidth = Number(cell.column.maxWidth)

                    return (
                      <Cell key={cellProps.key} {...cellProps}>
                        <Box
                          sx={{
                            ...(minWidth > 0 && { minW: minWidth }),
                            ...(maxWidth < 1000 && { maxW: maxWidth }),
                          }}
                        >
                          {cell.render('Cell')}
                        </Box>
                      </Cell>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        )}
      </TableStyled>
    </Box>
  )
}
