import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { ADMINS } from '../constants'
import { AdminLevel } from '../types'

const updateAdminLevel = async ({
  level,
  id,
}: {
  level: AdminLevel
  id: number
}) => {
  await fetchAPI({
    method: 'put',
    path: `/admins/${id}`,
    data: {
      level,
    },
  })
}

export const useUpdateAdminLevel = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateAdminLevel, {
    onSuccess: async () => {
      await queryClient.refetchQueries([ADMINS])

      toast({
        description: t('views.admin.admins.services.update_level_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
