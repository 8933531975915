import { Modal } from '@/components/shared/Modal'
import { LinkFormItem } from '@/features/link_forms/types'
import { t } from '@/lib/helpers'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'
import { get } from 'lodash'
import React from 'react'
import { useUpdateChallenge } from '../mutations'
import { Challenge } from '../types'
import { transformChallengeIntoForm } from '../utils'
import { ChallengeForm } from './ChallengeForm'

type Props = {
  disclosure: UseDisclosureProps
  initialData?: Challenge
  forms: LinkFormItem[]
}

export const EditChallengeFormModal = ({
  disclosure,
  initialData,
  forms,
}: Props) => {
  const { isOpen, onClose } = disclosure

  const { mutate: updateChallenge, isLoading } = useUpdateChallenge()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" sx={{ mb: '20px' }} isTruncated>
          {t('views.admin.challenges.edit.title', {
            title: get(initialData, 'title'),
          })}
        </Text>

        <ChallengeForm
          isLoading={isLoading}
          defaultValues={transformChallengeIntoForm(initialData)}
          onSubmitForm={(data) => {
            updateChallenge(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
          forms={forms}
        />
      </Box>
    </Modal>
  )
}
