import React from 'react'
import { Box } from '@chakra-ui/react'

import { Garbage } from '@/features/garbages/types'

import { withApp } from '@/features/Layout'
import { CityLayout } from '@/features/cities/components'

import { CityGarbageTable } from './CityGarbageTable'

import { t } from '@/lib/helpers'

type Props = {
  city_name: string
  garbages: Garbage[]
}

function CityGarbagesPage(props: Props) {
  const { city_name, garbages } = props

  return (
    <CityLayout
      title={'Garbages'}
      breadcrumbData={[
        {
          title: t('views.admin.cities.collection_sites.index.cities'),
          link: '/admin/cities',
        },
        {
          title: city_name,
        },
        {
          title: t('views.admin.cities.garbages.garbage'),
        },
      ]}
    >
      <Box>
        <CityGarbageTable data={garbages} />
      </Box>
    </CityLayout>
  )
}

export default withApp(CityGarbagesPage)
