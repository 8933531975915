import * as React from 'react'
import Select, { GroupTypeBase, OptionTypeBase, Props } from 'react-select'

import { SelectOption } from './types'
import { useThemeConfig } from './useThemeConfig'

export const ReactSelect = <
  OptionType extends OptionTypeBase = SelectOption<string>,
  IsMulti extends boolean = false,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>,
>(
  props: Props<OptionType, IsMulti, GroupType>,
) => {
  const { styles, theme } = useThemeConfig<OptionType, IsMulti, GroupType>()

  return (
    <Select styles={styles} theme={theme} menuPlacement="auto" {...props} />
  )
}
