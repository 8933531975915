import React, { Fragment, useMemo, useState } from 'react'
import get from 'lodash/get'
import { HStack, useDisclosure } from '@chakra-ui/react'

import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { EditLocationFormModal } from '@/features/locations/components'
import { Location } from '@/features/locations/types'
import { useDeleteLocation } from '@/features/locations/mutations'

import { t } from '@/lib/helpers'
import { QRCodeCategory } from '@/features/qrcode_categories/types'

type Props = {
  data: Location[]
  qrCategories: QRCodeCategory[]
}

export const LocationTable = ({ data, qrCategories }: Props) => {
  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const [actionData, setActionData] = useState<Location>()
  const { mutateAsync: deleteLocation } = useDeleteLocation()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.locations.table.name'),
        accessor: 'name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.locations.table.category'),
        accessor: 'qrcode_category.name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.locations.table.address'),
        accessor: 'address',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.locations.table.latitude'),
        accessor: 'latitude',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.locations.table.longitude'),
        accessor: 'longitude',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'action',
        disableSortBy: true,
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')

          return (
            <HStack spacing="2px">
              <TableEditButton
                ariaLabel={'edit'}
                onClick={() => {
                  setActionData(row)
                  onOpen()
                }}
              />
              <TableTrashButton
                ariaLabel={'delete'}
                onClick={() => {
                  if (
                    confirm(t('views.admin.challenges.table.confirm_delete'))
                  ) {
                    deleteLocation(row.id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      <EditLocationFormModal
        disclosure={disclosure}
        initialData={actionData}
        qrCategories={qrCategories}
      />
    </Fragment>
  )
}
