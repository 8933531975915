import { get } from 'lodash'

import { CollectionSite, CollectionSiteFormInput } from './types'

export const transformCollectionSiteInput = (form: CollectionSiteFormInput) => {
  const { name, order_number } = form

  return {
    name,
    order_number,
  }
}

export const transformCollectionSiteIntoForm = (
  data: CollectionSite,
): CollectionSiteFormInput => {
  return {
    name: get(data, 'name'),
    order_number: get(data, 'order_number'),
  }
}
