import React, { useMemo } from 'react'
import { get } from 'lodash'

import { Garbage } from '@/features/garbages/types'

import { DataTable, TableTrashButton } from '@/components/shared/Table'
import { useDeleteGarbage } from '@/features/garbages/mutations'
import { t } from '@/lib/helpers'

type Props = {
  data: Garbage[]
  isLoading: boolean
}

export const GarbageTable = ({ data, isLoading }: Props) => {
  const { mutateAsync: deleteGarbage } = useDeleteGarbage()

  const columns = useMemo(() => {
    return [
      {
        Header: t('views.admin.garbages.index.city'),
        accessor: 'city_name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.garbages.index.version'),
        accessor: 'version',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.garbages.index.item_name'),
        accessor: 'item_name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.garbages.index.item_name_kana'),
        accessor: 'item_name_kana',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.garbages.index.classification'),
        accessor: 'classification',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.garbages.index.how_classify_garbage'),
        accessor: 'how_classify_garbage',
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'action',
        Cell: (cellProps) => {
          const { id } = get(cellProps, 'row.original')

          return (
            <TableTrashButton
              ariaLabel={'delete garbage'}
              onClick={() => {
                if (confirm(t('views.admin.garbages.index.confirm_delete'))) {
                  deleteGarbage(id)
                }
              }}
            />
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <DataTable data={memoizedData} columns={columns} isLoading={isLoading} />
  )
}
