const layerStyles = {
  container: {
    pb: '60px',
  },
  content: {
    px: '40px',
  },
}

export default layerStyles
