import React from 'react'
import { isEmpty } from 'lodash'
import {
  Button,
  Grid,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import dayjs from 'dayjs'

import { FormControl, TimeSelect } from '@/features/forms/components'
import { useTags } from '@/features/tags/queries'

import { formatDateTime, t } from '@/lib/helpers'

import { CAMPAIGNS_TYPES } from '../constants'
import { CampaignFormInput, CampaignType } from '../types'

type Props = {
  defaultValues?: CampaignFormInput
  onSubmitForm: (data: CampaignFormInput) => void
  isLoading: boolean
}

export const CampaignForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, isLoading } = props

  const { data, status } = useTags()
  const tags = data?.data || []

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<CampaignFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })
  const onSubmit = (data: CampaignFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid sx={{ gridTemplateColumns: 'repeat(5, 1fr)', gap: '24px 16px' }}>
        <FormControl
          id="title"
          label={t('views.admin.campaigns.form.title')}
          isRequired
          sx={{ gridColumn: '1/-1' }}
          errorMsg={errors.title?.message}
        >
          <Input
            size="lg"
            placeholder={t('views.admin.campaigns.form.title')}
            ref={register}
            name="title"
            tabIndex={1}
          />
        </FormControl>

        <FormControl
          id="description"
          label={t('views.admin.campaigns.form.description')}
          sx={{ gridColumn: '1/-1' }}
        >
          <Textarea
            size="lg"
            name="description"
            placeholder={t(
              'views.admin.campaigns.form.description_placeholder',
            )}
            resize="none"
            sx={{ h: '96px' }}
            ref={register}
            tabIndex={2}
          />
        </FormControl>

        <FormControl
          id="type"
          label={t('views.admin.campaigns.form.campaign_type')}
          isRequired
          sx={{ gridColumn: '1/-1' }}
        >
          <Select size="lg" ref={register} name="campaign_type" tabIndex={3}>
            {CAMPAIGNS_TYPES.map(({ label, value }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl
          id="campaignCode"
          label={t('views.admin.campaigns.form.campaign_code')}
          sx={{ gridColumn: '1/-1' }}
        >
          <Input
            size="lg"
            placeholder={t('views.admin.campaigns.form.campaign_code')}
            ref={register}
            name="campaign_code"
            tabIndex={1}
          />
        </FormControl>

        <FormControl
          id="point"
          label={t('views.admin.campaigns.form.point')}
          isRequired
          sx={{ gridColumn: '1/-1' }}
        >
          <NumberInput
            size="lg"
            defaultValue={0}
            name="point"
            tabIndex={4}
            ref={register}
          >
            <NumberInputField ref={register} />
          </NumberInput>
        </FormControl>

        {status === 'success' && (
          <FormControl
            id="tag_id"
            label={t('views.admin.campaigns.form.tag')}
            isRequired
            sx={{ gridColumn: '1/-1' }}
          >
            <Select size="lg" name="tag_id" ref={register} tabIndex={5}>
              {tags.map(({ id, name }) => (
                <option value={`${id}`} key={id}>
                  {name}
                </option>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl
          id="releasedAt"
          label={t('views.admin.campaigns.form.released_at')}
          isRequired
          sx={{ gridColumn: '1/4' }}
          errorMsg={errors.releasedAt?.message}
        >
          <Input
            type="date"
            name="releasedAt"
            ref={register}
            defaultValue={formatDateTime(new Date().toString(), 'YYYY-MM-DD')}
            tabIndex={5}
            size="lg"
          />
        </FormControl>

        <FormControl
          id="releasedAtHr"
          label={t('views.admin.campaigns.form.time')}
          isRequired
        >
          <TimeSelect innerRef={register} name={'releasedAtHr'} tabIndex={6} />
        </FormControl>

        <FormControl id="releasedAtMin" sx={{ mt: 'auto' }}>
          <TimeSelect
            isMin={true}
            innerRef={register}
            name={'releasedAtMin'}
            tabIndex={7}
          />
        </FormControl>

        <FormControl
          id="endedAt"
          label={t('views.admin.campaigns.form.ended_at')}
          isRequired
          sx={{ gridColumn: '1/4' }}
          errorMsg={errors.endedAt?.message}
        >
          <Input
            type="date"
            name="endedAt"
            ref={register}
            defaultValue={formatDateTime(
              dayjs().add(7, 'day').toString(),
              'YYYY-MM-DD',
            )}
            tabIndex={8}
            size="lg"
          />
        </FormControl>

        <FormControl
          id="endedAtHr"
          label={t('views.admin.campaigns.form.time')}
          isRequired
        >
          <TimeSelect innerRef={register} name={'endedAtHr'} tabIndex={9} />
        </FormControl>

        <FormControl id="endedAtMin" sx={{ mt: 'auto' }}>
          <TimeSelect
            isMin={true}
            innerRef={register}
            name={'endedAtMin'}
            tabIndex={10}
          />
        </FormControl>
      </Grid>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        size="lg"
        isLoading={isSubmitting || isLoading}
        tabIndex={11}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.campaigns.form.create')
          : t('views.admin.campaigns.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  title: yup.string().required(t('views.admin.shared.required')),
  description: yup.string(),
  campaign_type: yup
    .string()
    .oneOf([
      CampaignType.first_campaign,
      CampaignType.second_campaign,
      CampaignType.third_campaign,
    ]),
  campaign_code: yup.string(),
  point: yup.number().integer(),
  releasedAt: yup.date().required(t('views.admin.shared.required')),
  releasedAtHr: yup.number().positive().integer().min(0).max(23),
  releasedAtMin: yup.number().positive().integer().min(0).max(59),
  endedAt: yup.date().required(t('views.admin.shared.required')),
  endedAtHr: yup.number().positive().integer().min(0).max(23),
  endedAtMin: yup.number().positive().integer().min(0).max(59),
  tag_id: yup.string(),
})
