import { get } from 'lodash'

import { Message, MessageFormInput } from './types'

import { formatDateTime } from '@/lib/helpers'

export const transformMessageInput = (form: MessageFormInput): FormData => {
  const {
    title,
    link,
    city_ids,
    content_type,
    startsAt,
    startsAtHr,
    startsAtMin,
    endsAt,
    endsAtHr,
    endsAtMin,
    image,
  } = form

  const formData = new FormData()

  formData.append('title', title)
  formData.append('link', link)
  formData.append('city_ids', city_ids.toString())
  formData.append('content_type', content_type)
  formData.append(
    'starts_at',
    `${formatDateTime(startsAt, 'YYYY-MM-DD')} ${startsAtHr}:${startsAtMin}`,
  )
  formData.append(
    'ends_at',
    `${formatDateTime(endsAt, 'YYYY-MM-DD')} ${endsAtHr}:${endsAtMin}`,
  )
  if (typeof image !== 'string') {
    formData.append('image', image || '')
  }

  return formData
}

export const transformMessageIntoForm = (data: Message): MessageFormInput => {
  const startsAt = get(data, 'starts_at')
  const endsAt = get(data, 'ends_at')

  return {
    title: get(data, 'title'),
    link: get(data, 'link'),
    city_ids: get(data, 'city_ids'),
    content_type: get(data, 'content_type'),
    startsAt: formatDateTime(startsAt, 'YYYY-MM-DD'),
    startsAtHr: formatDateTime(startsAt, 'HH') || '0',
    startsAtMin: formatDateTime(startsAt, 'mm') || '0',
    endsAt: formatDateTime(endsAt, 'YYYY-MM-DD'),
    endsAtHr: formatDateTime(endsAt, 'HH') || '0',
    endsAtMin: formatDateTime(endsAt, 'mm') || '0',
    image: get(data, 'image_url'),
  }
}
