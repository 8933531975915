import { useMutation, useQueryClient } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { formatDateTime, getErrorMsg, t } from '@/lib/helpers'
import { useToast } from '@chakra-ui/react'

import { VISIBLE_ECOS } from '../constants'
import { VisibleEcosFormInput } from '../types'

const updateVisibleEcos = async ({
  form,
  id,
}: {
  form: VisibleEcosFormInput
  id: number
}) => {
  await fetchAPI({
    method: 'put',
    path: `/visible_ecos/${id}`,
    data: {
      ...form,
      ends_at: formatDateTime(form.ends_at, 'YYYY-MM-DD'),
      starts_at: formatDateTime(form.starts_at, 'YYYY-MM-DD'),
    },
  })
}

export const useUpdateVisibleEcos = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateVisibleEcos, {
    onSuccess: async () => {
      await queryClient.refetchQueries([VISIBLE_ECOS])

      toast({
        description: t('views.admin.messages.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
