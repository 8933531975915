import { get } from 'lodash'

import { Prize, PrizeFormInput } from './types'

import { formatDateTime } from '@/lib/helpers'

export const transformPrizeInput = (form: PrizeFormInput) => {
  const {
    title,
    description,
    banner,
    note,
    cost,
    limit_items,
    points,
    startsAt,
    startsAtHr,
    startsAtMin,
    endsAt,
    endsAtHr,
    endsAtMin,
    first_win_prob,
    second_win_prob,
    third_win_prob,
    fourth_win_prob,
    order_number,
    age_restricted,
  } = form

  return {
    title,
    description,
    banner,
    note,
    cost,
    limit_items,
    points,
    starts_at: `${formatDateTime(
      startsAt,
      'YYYY-MM-DD',
    )} ${startsAtHr}:${startsAtMin}`,
    ends_at: `${formatDateTime(endsAt, 'YYYY-MM-DD')} ${endsAtHr}:${endsAtMin}`,
    first_win_prob,
    second_win_prob,
    third_win_prob,
    fourth_win_prob,
    order_number,
    age_restricted,
  }
}

export const transformPrizeIntoForm = (data: Prize): PrizeFormInput => {
  const startsAt = get(data, 'starts_at')
  const endsAt = get(data, 'ends_at')

  return {
    title: get(data, 'title'),
    description: get(data, 'description'),
    banner: get(data, 'banner'),
    note: get(data, 'note'),
    cost: String(get(data, 'cost', '3')) || '3',
    limit_items: String(get(data, 'limit_items', '0')) || '0',
    points: String(get(data, 'points', '0')) || '0',
    startsAt: formatDateTime(startsAt, 'YYYY-MM-DD'),
    startsAtHr: formatDateTime(startsAt, 'HH') || '0',
    startsAtMin: formatDateTime(startsAt, 'mm') || '0',
    endsAt: formatDateTime(endsAt, 'YYYY-MM-DD'),
    endsAtHr: formatDateTime(endsAt, 'HH') || '0',
    endsAtMin: formatDateTime(endsAt, 'mm') || '0',
    first_win_prob: String(get(data, 'first_win_prob', '10')) || '10',
    second_win_prob: String(get(data, 'second_win_prob', '15')) || '15',
    third_win_prob: String(get(data, 'third_win_prob', '5')) || '5',
    fourth_win_prob: String(get(data, 'fourth_win_prob', '8')) || '8',
    order_number: String(get(data, 'order_number', '0')) || '0',
    age_restricted: get(data, 'age_restricted', false),
  }
}
