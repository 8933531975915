import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { SUBMISSIONS } from '../constants'

const updateSubmissionApproval = async ({
  campaignId,
  id,
}: {
  campaignId: number
  id: number
}) => {
  await fetchAPI({
    method: 'put',
    path: `/campaigns/${campaignId}/submissions/${id}/approve`,
  })
}

export const useUpdateSubmissionApproval = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateSubmissionApproval, {
    onSuccess: async () => {
      await queryClient.refetchQueries([SUBMISSIONS])

      toast({
        description: t('views.admin.posts.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
