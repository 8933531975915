import React, { useMemo } from 'react'

import { Garbage } from '@/features/garbages/types'

import { DataTable } from '@/components/shared/Table'
import { t } from '@/lib/helpers'

type Props = {
  data: Garbage[]
}

export const CityGarbageTable = ({ data }: Props) => {
  const columns = useMemo(() => {
    return [
      {
        Header: t('views.admin.cities.garbages.city_name'),
        accessor: 'city_name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.cities.garbages.version'),
        accessor: 'version',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.cities.garbages.item_name'),
        accessor: 'item_name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.cities.garbages.item_name_kana'),
        accessor: 'item_name_kana',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.cities.garbages.classification'),
        accessor: 'classification',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.cities.garbages.how_classify'),
        accessor: 'how_classify_garbage',
        disableSortBy: true,
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return <DataTable data={memoizedData} columns={columns} />
}
