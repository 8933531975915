import React from 'react'
import { get } from 'lodash'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { CampaignForm } from './CampaignForm'

import { useUpdateCampaign } from '../mutations'
import { Campaign } from '../types'
import { transformCampaignIntoForm } from '../utils'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
  initialData?: Campaign
}

export const EditCampaignFormModal = (props: Props) => {
  const { disclosure, initialData } = props

  const { isOpen, onClose } = disclosure

  const { mutate: updateCampaign, isLoading } = useUpdateCampaign()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" sx={{ mb: '20px' }} isTruncated>
          {t('views.admin.campaigns.edit.title', {
            title: get(initialData, 'title'),
          })}
        </Text>

        <CampaignForm
          isLoading={isLoading}
          defaultValues={transformCampaignIntoForm(initialData)}
          onSubmitForm={(data) => {
            updateCampaign(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
