import { CampaignType } from './types'

export const CAMPAIGNS_TYPES = [
  {
    label: 'First Campaign',
    value: CampaignType.first_campaign,
  },
  {
    label: 'Second Campaign',
    value: CampaignType.second_campaign,
  },
  {
    label: 'Third Campaign',
    value: CampaignType.third_campaign,
  },
]

export const CAMPAIGNS = 'campaigns'

export const SUBMISSIONS = 'submissions'
