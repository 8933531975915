export const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      boxShadow: 'none !important',
    },
  },
  sizes: {
    lg: {
      field: {
        fontSize: '16px',
      },
      addon: {
        fontSize: '16px',
      },
    },
  },
}
