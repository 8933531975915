import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'
import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'
import { POST_RANKINGS } from '../constants'

const deletePostRanking = async (id: number) => {
  await fetchAPI({
    path: `/post_rankings/${id}`,
    method: 'delete',
  })
}

export const useDeletePostRanking = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(deletePostRanking, {
    async onSuccess() {
      await queryClient.refetchQueries([POST_RANKINGS])

      toast({
        description: t('views.admin.messages.services.delete_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
