import {
  DataTable,
  TableEditButton,
  TableTrashButton,
} from '@/components/shared/Table'
import { EditChallengeFormModal } from '@/features/challenges/components'
import { useDeleteChallenge } from '@/features/challenges/mutations'
import { Challenge } from '@/features/challenges/types'
import { challengeTypeLabels } from '@/features/challenges/utils'
import { LinkFormItem } from '@/features/link_forms/types'
import { formatDateTime, t } from '@/lib/helpers'
import { HStack, useDisclosure } from '@chakra-ui/react'
import { get } from 'lodash'
import React, { Fragment, useMemo, useState } from 'react'

type Props = {
  data: Challenge[]
  forms: LinkFormItem[]
}

export const ChallengeTable = ({ data, forms }: Props) => {
  const disclosure = useDisclosure()
  const { onOpen } = disclosure

  const [actionData, setActionData] = useState<Challenge>()
  const { mutateAsync: deleteChallenge } = useDeleteChallenge()

  const columns = useMemo(() => {
    return [
      {
        Header: '#',
        accessor: 'id',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.challenges.index.eco_action_name'),
        accessor: 'title',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.challenges.index.category'),
        accessor: 'challenge_type',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return challengeTypeLabels[value]
        },
      },
      {
        Header: t('views.admin.challenges.index.grant_point'),
        accessor: 'point',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.challenges.index.tag'),
        accessor: 'tag.name',
        disableSortBy: true,
      },
      {
        Header: t('views.admin.challenges.index.release_at'),
        accessor: 'released_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.challenges.index.release_end_at'),
        accessor: 'ended_at',
        disableSortBy: true,
        Cell: (cellProps) => {
          const value = get(cellProps, 'value')

          return formatDateTime(value)
        },
      },
      {
        Header: t('views.admin.challenges.index.action'),
        accessor: 'action',
        Cell: (cellProps) => {
          const row = get(cellProps, 'row.original')
          const { id } = row

          return (
            <HStack spacing="2px">
              <TableEditButton
                ariaLabel={'edit challenge'}
                onClick={() => {
                  setActionData(row)
                  onOpen()
                }}
              />
              <TableTrashButton
                ariaLabel={'delete challenge'}
                onClick={() => {
                  if (
                    confirm(t('views.admin.challenges.table.confirm_delete'))
                  ) {
                    deleteChallenge(id)
                  }
                }}
              />
            </HStack>
          )
        },
      },
    ]
  }, [])

  const memoizedData = useMemo(() => {
    return data
  }, [data])

  return (
    <Fragment>
      <DataTable data={memoizedData} columns={columns} />

      <EditChallengeFormModal
        disclosure={disclosure}
        initialData={actionData}
        forms={forms}
      />
    </Fragment>
  )
}
