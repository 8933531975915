import { useToast } from '@chakra-ui/react'
import { useMutation, useQueryClient } from 'react-query'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { PrizeFormInput } from '../types'

import { PRIZES } from '../constants'
import { transformPrizeInput } from '../utils'

const updatePrize = async ({
  form,
  id,
}: {
  form: PrizeFormInput
  id: number
}) => {
  const data = transformPrizeInput(form)

  const formData = new FormData()

  formData.append('title', data.title)
  formData.append('description', data.description)
  if (typeof data.banner !== 'string') {
    formData.append('banner', data.banner || '')
  }
  formData.append('note', data.note)
  formData.append('cost', data.cost)
  formData.append('limit_items', data.limit_items)
  formData.append('points', data.points)
  formData.append('first_win_prob', data.first_win_prob)
  formData.append('second_win_prob', data.second_win_prob)
  formData.append('third_win_prob', data.third_win_prob)
  formData.append('fourth_win_prob', data.fourth_win_prob)
  formData.append('starts_at', data.starts_at)
  formData.append('ends_at', data.ends_at)
  formData.append('order_number', data.order_number)
  formData.append('age_restricted', data.age_restricted.toString())

  await fetchAPI({
    path: `/prize_exchanges/${id}`,
    method: 'put',
    data: formData,
  })
}

export const useUpdatePrize = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updatePrize, {
    async onSuccess() {
      await queryClient.refetchQueries([PRIZES])

      toast({
        description: t('views.admin.prize_exchanges.services.update_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
