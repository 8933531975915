import React from 'react'
import { get } from 'lodash'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { OfficialLinkForm } from './OfficialLinkForm'

import { City } from '../types'

import { useUpdateOfficialLink } from '../mutations'

import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
  initialData: City
}

export const AddOfficialLinkModal = (props: Props) => {
  const { disclosure, initialData } = props
  const { isOpen, onClose } = disclosure

  const { mutate: updateOfficialLink, isLoading } = useUpdateOfficialLink()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.cities.index.add_url')}
        </Text>

        <OfficialLinkForm
          isLoading={isLoading}
          defaultValues={{
            official_link: get(initialData, 'official_link'),
            collection_sites_link: get(initialData, 'collection_sites_link'),
          }}
          onSubmitForm={(data) => {
            updateOfficialLink(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
        />
      </Box>
    </Modal>
  )
}
