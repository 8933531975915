import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { CalendarBySiteItem } from '../types'

import { COLLECTION_SITE_CALENDARS } from '../constants'

type CalendarBySiteResponse = {
  data: CalendarBySiteItem[]
}

const getCalendarBySite = async (params) => {
  const { city_id, collection_site_id, start_date } = params

  const response = await fetchAPI({
    path: `/cities/${city_id}/collection_sites/${collection_site_id}/calendars`,
    params: {
      start_date,
    },
  })

  const data = get(response, 'data', [])

  return { data }
}

export const useCalendarBySite = ({
  params,
  initialData,
}: {
  params?: { start_date: string; city_id: string; collection_site_id: string }
  initialData?: CalendarBySiteResponse
}) => {
  return useQuery<CalendarBySiteResponse, AxiosError>({
    queryKey: [COLLECTION_SITE_CALENDARS, params],
    queryFn: () => getCalendarBySite(params),
    keepPreviousData: true,
    initialData,
  })
}
