import React, { forwardRef, useEffect, useRef } from 'react'
import { Checkbox } from '@chakra-ui/react'
import { useRowSelect } from 'react-table'

export const useSelection = () => {
  return [
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }: any) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }: any) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    },
  ]
}

const IndeterminateCheckbox = forwardRef((props: any, ref: any) => {
  const { indeterminate, checked, ...rest } = props

  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return <Checkbox size="lg" ref={resolvedRef} isChecked={checked} {...rest} />
})
