import { get } from 'lodash'

import { BonusPoint, BonusPointInput } from './types'

import { formatDateTime } from '@/lib/helpers'

const transformBonusPointInput = (form: BonusPointInput): FormData => {
  const {
    title,
    description,
    image_url,
    point,
    starts_at,
    starts_at_hr,
    starts_at_min,
    ends_at,
    ends_at_hr,
    ends_at_min,
    bonus_type,
    quiz_answer,
    quiz_answer_description,
    quiz_answer_image,
    header_text,
    cover_image,
    quiz_choices,
  } = form

  const formData = new FormData()

  formData.append('title', title)
  formData.append('description', description)
  formData.append('image_url', image_url)
  formData.append('point', point)
  formData.append('bonus_type', bonus_type)
  formData.append('header_text', header_text)

  if (bonus_type === 'quiz') {
    formData.append('quiz_answer', quiz_answer ?? '')
    formData.append('quiz_answer_description', quiz_answer_description ?? '')
    formData.append('quiz_answer_image', quiz_answer_image ?? '')
  } else if (bonus_type === 'multi_selections_quiz') {
    formData.append('quiz_answer_description', quiz_answer_description ?? '')
    formData.append('quiz_choices', JSON.stringify(quiz_choices))
  }

  formData.append(
    'starts_at',
    `${formatDateTime(
      starts_at,
      'YYYY-MM-DD',
    )} ${starts_at_hr}:${starts_at_min}`,
  )
  formData.append(
    'ends_at',
    `${formatDateTime(ends_at, 'YYYY-MM-DD')} ${ends_at_hr}:${ends_at_min}`,
  )

  if (typeof image_url !== 'string') {
    formData.append('image', image_url ?? '')
  }

  if (typeof quiz_answer_image !== 'string') {
    formData.append('quiz_answer_image', quiz_answer_image ?? '')
  }

  if (typeof cover_image !== 'string') {
    formData.append('cover_image', cover_image ?? '')
  }

  return formData
}

const transformBonusPointIntoForm = (data: BonusPoint): BonusPointInput => {
  const startsAt = get(data, 'starts_at')
  const endsAt = get(data, 'ends_at')

  return {
    title: get(data, 'title'),
    description: get(data, 'description'),
    image_url: get(data, 'image_url'),
    point: get(data, 'point'),
    starts_at: formatDateTime(startsAt, 'YYYY-MM-DD'),
    starts_at_hr: formatDateTime(startsAt, 'HH'),
    starts_at_min: formatDateTime(startsAt, 'mm'),
    ends_at: formatDateTime(endsAt, 'YYYY-MM-DD'),
    ends_at_hr: formatDateTime(endsAt, 'HH'),
    ends_at_min: formatDateTime(endsAt, 'mm'),
    bonus_type: get(data, 'bonus_type'),
    quiz_answer: get(data, 'quiz_answer'),
    quiz_answer_description: get(data, 'quiz_answer_description'),
    quiz_answer_image: get(data, 'quiz_answer_image_url'),
    header_text: get(data, 'header_text'),
    cover_image: get(data, 'cover_image_url'),
    quiz_choices: get(data, 'quiz_choices'),
  }
}

export { transformBonusPointIntoForm, transformBonusPointInput }
