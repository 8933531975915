import React from 'react'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { Modal } from '@/components/shared/Modal'
import { ArticleForm } from './ArticleForm'

import { useCreateArticle } from '../mutations'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
}

export const CreateArticleModal = (props: Props) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure

  const { mutate: createArticle, isLoading } = useCreateArticle()

  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="1280px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.articles.form.new_title')}
        </Text>
        <ArticleForm
          isLoading={isLoading}
          onSubmitForm={(data) => {
            createArticle(data, { onSuccess: onClose })
          }}
        />
      </Box>
    </Modal>
  )
}
