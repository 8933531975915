import { Box, Flex, Text } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

type Props = {
  title: string
  addons?: ReactNode
  breadcrumb?: ReactNode
}

export const DashboardHeader = (props: Props) => {
  const { title, addons, breadcrumb } = props

  return (
    <Flex
      layerStyle="content"
      sx={{
        h: '115px',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Box sx={{ pos: 'absolute', left: '40px', top: '18px' }}>
        {breadcrumb}
      </Box>

      <Text as="h1" sx={{ fontWeight: 600, fontSize: '32px' }}>
        {title}
      </Text>

      <Box>{addons}</Box>
    </Flex>
  )
}
