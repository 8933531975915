import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import {
  Box,
  Button,
  Center,
  Grid,
  Icon,
  IconButton,
  Select,
  Text,
} from '@chakra-ui/react'
import { get } from 'lodash'
import { useDropzone } from 'react-dropzone'
import { FiTrash2 } from 'react-icons/fi'

import { Modal } from '@/components/shared/Modal'

import { dashedBorder } from '../styles'

import XlsxIcon from '@/icons/xlsx.svg'
import { getFileSize, t } from '@/lib/helpers'

type Props = {
  isOpen: boolean
  onClose: () => void
  cities?: {
    id: number
    name: string
  }[]
  onSubmit: ({ file: File, city_id: number }) => void
  isLoading: boolean
}

export const UploadXlsxModal = (props: Props) => {
  const { isOpen, onClose, cities, onSubmit, isLoading } = props

  const [file, setFile] = useState<File>()
  const [city, setCity] = useState<number | undefined>()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Grid sx={{ gap: '24px', p: '32px' }}>
        <Text textStyle="modalHeader">
          {t('views.admin.shared.misc.upload')}
        </Text>

        {cities && (
          <Select
            size="lg"
            tabIndex={1}
            placeholder={t('views.admin.cities.form.select_city')}
            onChange={(e) => setCity(Number(e.target.value))}
          >
            {cities.map((c) => (
              <option key={c.name} value={c.id}>
                {c.name}
              </option>
            ))}
          </Select>
        )}

        <Box>
          {file ? (
            <FilePreview file={file} setFile={setFile} />
          ) : (
            <UploadComponent setFile={setFile} />
          )}
        </Box>

        <Button
          size="lg"
          sx={{ w: '141px', h: '54px', justifySelf: 'center' }}
          onClick={() => onSubmit({ file, ...(cities && { city_id: city }) })}
          isDisabled={cities ? !city || !file : !file}
          isLoading={isLoading}
        >
          {t('views.admin.shared.misc.upload')}
        </Button>
      </Grid>
    </Modal>
  )
}

type UploadComponentProps = {
  setFile: Dispatch<SetStateAction<File>>
}

const UploadComponent = (props: UploadComponentProps) => {
  const { setFile } = props

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['.xlsx'],
    multiple: false,
  })

  return (
    <Box {...getRootProps()}>
      <input {...getInputProps()} />
      <Center
        sx={{
          flexDir: 'column',
          backgroundImage: dashedBorder,
          h: '164px',
          cursor: 'pointer',
        }}
      >
        <Text sx={{ mb: '12px', color: 'gray.200', fontStyle: 'italic' }}>
          {'No File Chosen'}
        </Text>
        <Button variant="outline" colorScheme="brand" sx={{ fontSize: '14px' }}>
          {'Choose File (support .xlsx)'}
        </Button>
      </Center>
    </Box>
  )
}

const FilePreview = ({
  file,
  setFile,
}: {
  file: File
  setFile: Dispatch<SetStateAction<File>>
}) => {
  return (
    <Grid
      sx={{
        border: '1px solid',
        borderColor: 'green.500',
        alignItems: 'center',
        px: '24px',
        gridTemplateColumns: '48px 1fr 40px',
        gap: '20px',
        h: '96px',
      }}
    >
      <Box
        sx={{
          background: 'no-repeat center/contain',
          backgroundImage: `url(${XlsxIcon})`,
          boxSize: '48px',
        }}
      />
      <Box>
        <Text>{get(file, 'name')}</Text>
        <Text sx={{ color: 'gray.200' }}>{getFileSize(get(file, 'size'))}</Text>
      </Box>

      <IconButton
        icon={<Icon as={FiTrash2} sx={{ fontSize: '20px' }} />}
        aria-label="remove selected file"
        variant="ghost"
        colorScheme="red"
        onClick={() => setFile(undefined)}
        title={'remove selected file'}
      />
    </Grid>
  )
}
