import React, { ReactNode } from 'react'
import {
  FormControl as ChakraFormControl,
  FormLabel,
  FormErrorMessage,
  SystemStyleObject,
} from '@chakra-ui/react'

type Props = {
  id: string
  label?: string
  errorMsg?: string
  isRequired?: boolean
  children: ReactNode
  sx?: SystemStyleObject
}

export const FormControl = (props: Props) => {
  const { label, id, errorMsg, isRequired = false, children, sx } = props

  return (
    <ChakraFormControl
      id={id}
      isRequired={isRequired}
      sx={sx}
      isInvalid={Boolean(errorMsg)}
    >
      {label && <FormLabel sx={{ fontWeight: 500 }}>{label}</FormLabel>}
      {children}
      <FormErrorMessage>{errorMsg}</FormErrorMessage>
    </ChakraFormControl>
  )
}
