import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { get, orderBy } from 'lodash'

import { fetchAPI } from '@/lib/api'

import { CALENDAR_SETTINGS } from '../constants'
import { CalendarSettingsT } from '../types'

type CalendarSettingParams = { city_id: number; collection_site_id: number }

const getCalendarSettings = async (
  params: CalendarSettingParams,
): Promise<CalendarSettingsT[]> => {
  const response = await fetchAPI({
    path: `/cities/${params.city_id}/collection_sites/${params.collection_site_id}/calendars/settings`,
  })

  const data = get(response, 'data.calendars', [])

  const orderData = data.filter((item) => item[1] !== 0)
  const noOrderData = data.filter((item) => item[1] === 0)
  const newData = orderData.concat(noOrderData)

  const formatData = newData.map((res, index) => {
    return {
      ordering: !!res[1] ? res[1] : index + 1,
      garbage_name: res[0],
    }
  })

  return orderBy(formatData, 'ordering')
}

export const useCalendarSettings = (params: CalendarSettingParams) => {
  return useQuery<CalendarSettingsT[], AxiosError>({
    queryKey: [CALENDAR_SETTINGS],
    queryFn: () => getCalendarSettings(params),
  })
}
