import React from 'react'

import { Modal } from '@/components/shared/Modal'
import { AdminForm } from './AdminForm'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'

import { useCreateAdmin } from '../mutations'
import { t } from '@/lib/helpers'

type Props = {
  disclosure: UseDisclosureProps
}

export const CreateAdminModal = (props: Props) => {
  const { disclosure } = props
  const { isOpen, onClose } = disclosure

  const { mutate: createAdmin, isLoading } = useCreateAdmin()

  return (
    <Modal isOpen={isOpen} onClose={onClose} withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.admins.new.title')}
        </Text>
        <AdminForm
          isLoading={isLoading}
          onSubmitForm={(data) => {
            createAdmin(data, { onSuccess: onClose })
          }}
        />
      </Box>
    </Modal>
  )
}
