import { get } from 'lodash'
import { useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { fetchAPI } from '@/lib/api'

import { Admin } from '../types'
import { Meta } from '@/features/filters/types'

import { ADMINS } from '../constants'

type AdminResponse = {
  data: Admin[]
  meta: Meta
}

const getAdmins = async (params: Meta): Promise<AdminResponse> => {
  const response = await fetchAPI({
    path: `/admins`,
    params,
  })

  const data = get(response, 'data.admins', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useAdmins = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: AdminResponse
}) => {
  return useQuery<AdminResponse, AxiosError>({
    queryKey: [ADMINS, params],
    queryFn: () => getAdmins(params),
    keepPreviousData: true,
    initialData,
  })
}
