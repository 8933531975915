export enum AdminLevel {
  Pending = 'pending',
  Admin = 'admin',
  SystemAdmin = 'system_admin',
}

export type Admin = {
  id: number
  email: string
  level: AdminLevel
  created_at: string
  updated_at: string
}

export type AdminFormInput = {
  email: string
  password: string
  confirmPassword: string
  level: AdminLevel
}
