import React, { Fragment, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Select,
  Text,
} from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'

import { Shipping } from '@/features/shippings/types'
import { Meta } from '@/features/filters/types'

import { Pagination } from '@/components/shared/Pagination'
import { ShippingTable } from './ShippingTable'

import { useMeta } from '@/features/filters'
import { useShippings } from '@/features/shippings/queries'
import { SHIPPING_STATUS } from '@/features/shippings/constants'
import { t } from '@/lib/helpers'

type Props = {
  shippings: Shipping[]
  meta: Meta
  total_cost_spent: number
}

function ShippingsPage(props: Props) {
  const {
    shippings: initialShippings,
    meta: initialMeta,
    total_cost_spent,
  } = props

  const { meta, setMeta, setMetas } = useMeta(initialMeta)
  const { data: shippings } = useShippings({
    initialData: { data: initialShippings, meta: initialMeta },
    params: meta,
  })
  const [filter, setFilter] = useState({ search: '', status: '' })

  const handleSearch = () => {
    setMetas(filter)
  }

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.shippings.index.title')}
          addons={
            <Fragment>
              <Text
                sx={{
                  pos: 'absolute',
                  left: '40px',
                  top: '18px',
                  color: 'gray.600',
                }}
              >
                {t('views.admin.shippings.index.total_cost', {
                  cost: total_cost_spent,
                })}
              </Text>
              <HStack>
                <Button
                  sx={{ h: '48px', minW: 'auto' }}
                  as="a"
                  variant="outline"
                  href={`/admin/shippings/export.csv`}
                  download
                >
                  Download CSV
                </Button>
                <Input
                  size="lg"
                  placeholder={t('views.admin.shippings.index.search_by_email')}
                  sx={{ w: '275px' }}
                  value={filter.search}
                  onChange={(e) =>
                    setFilter((s) => ({ ...s, search: e.target.value }))
                  }
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch()
                    }
                  }}
                />
                <Box>
                  <Select
                    size="lg"
                    sx={{ w: 'auto', textTransform: 'capitalize' }}
                    onChange={(e) =>
                      setFilter((s) => ({ ...s, status: e.target.value }))
                    }
                  >
                    {['all status', ...SHIPPING_STATUS].map((status) => (
                      <option value={status} key={status}>
                        {status}
                      </option>
                    ))}
                  </Select>
                </Box>
                <Button
                  onClick={handleSearch}
                  sx={{ h: '48px', minW: '100px' }}
                >
                  {t('views.admin.shared.misc.search')}
                </Button>
              </HStack>
            </Fragment>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <ShippingTable data={shippings.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>
    </Fragment>
  )
}

export default withApp(ShippingsPage)
