import React from 'react'
import { Modal } from '@/components/shared/Modal'

import { t } from '@/lib/helpers'
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react'
import { RankingResponse } from '../types'
import { PostRankingForm } from './PostRankingForm'
import { transformDataIntoForm } from '../utils'

import { get } from 'lodash'
import { useUpdatePostRanking } from '../mutations'

type Props = {
  disclosure: UseDisclosureProps
  initialData: RankingResponse
  isLoading: boolean
}

export const EditRankingModal = (props: Props) => {
  const { disclosure, initialData } = props
  const { isOpen, onClose } = disclosure
  const { mutate: updatePostRanking } = useUpdatePostRanking()
  return (
    <Modal isOpen={isOpen} onClose={onClose} maxW="480px" withCloseButton>
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.forms.form.edit')}
        </Text>
        <PostRankingForm
          defaultValues={initialData && transformDataIntoForm(initialData)}
          onSubmitForm={(data) => {
            updatePostRanking(
              { form: data, id: get(initialData, 'id') },
              { onSuccess: onClose },
            )
          }}
          isEditMode={true}
        />
      </Box>
    </Modal>
  )
}
