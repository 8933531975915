import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { ADMINS } from '../constants'

const deleteAdmin = async (id: number) => {
  await fetchAPI({
    path: `/admins/${id}`,
    method: 'delete',
  })
}

export const useDeleteAdmin = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(deleteAdmin, {
    async onSuccess() {
      await queryClient.refetchQueries([ADMINS])

      toast({
        description: t('views.admin.admins.services.delete_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
