import React from 'react'
import { Select } from '@chakra-ui/react'
import debounce from 'lodash/debounce'

import { useUpdateAppConfig } from '../mutations'
import { AppConfig } from '../types'
import { t } from '@/lib/helpers'

type Props = {
  data: AppConfig
  options: string[]
}

export const AppConfigSelect = (props: Props) => {
  const { data, options } = props
  const { mutate: updateAppConfig, isLoading } = useUpdateAppConfig()

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateAppConfig({
      id: data.id,
      value: e.target.value,
    })
  }

  return (
    <Select
      isDisabled={isLoading}
      onChange={debounce(onChange, 600)}
      defaultValue={data.value as string}
    >
      {options.map((option: string, idx: number) => (
        <option key={idx} value={option}>
          {t(`views.admin.app_configs.table.options.${data.var}.${option}`)}
        </option>
      ))}
    </Select>
  )
}
