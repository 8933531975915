import { Pagination } from '@/components/shared/Pagination'
import { useMeta } from '@/features/filters'
import { Meta } from '@/features/filters/types'
import { DashboardHeader, withApp } from '@/features/Layout'
import { CreateLinkFormModal } from '@/features/link_forms/components'
import { useLinkForms } from '@/features/link_forms/queries'
import { t } from '@/lib/helpers'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import { FormTable } from './FormTable'

type Props = {
  forms: any[]
  meta: Meta
}

function LinksPage(props: Props) {
  const { forms: initialForms, meta: initialMeta } = props

  const disclosure = useDisclosure()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: forms } = useLinkForms({
    initialData: { data: initialForms, meta: initialMeta },
    params: meta,
  })

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.forms.index.title')}
          addons={
            <Button
              onClick={disclosure.onOpen}
              sx={{ h: '48px', minW: '100px' }}
            >
              {t('views.admin.shared.form.create')}
            </Button>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <FormTable data={forms.data} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={forms.meta.page}
            per={forms.meta.per}
            total={forms.meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <CreateLinkFormModal disclosure={disclosure} />
    </Fragment>
  )
}

export default withApp(LinksPage)
