import { Meta } from '@/features/filters/types'
import { fetchAPI } from '@/lib/api'
import { AxiosError } from 'axios'
import { get } from 'lodash'
import { useQuery } from 'react-query'
import { HOLIDAYS } from '../constants'
import { Holiday } from '../types'

type HolidayResponse = {
  data: Holiday[]
  meta: Meta
}

const getHolidays = async (params: Meta): Promise<HolidayResponse> => {
  const response = await fetchAPI({
    path: `/holidays`,
    params,
  })

  const data = get(response, 'data.holidays', [])
  const meta = get(response, 'data.meta', {})

  return { data, meta }
}

export const useHolidays = ({
  params = {},
  initialData,
}: {
  params?: Meta
  initialData?: HolidayResponse
}) => {
  return useQuery<HolidayResponse, AxiosError>({
    queryKey: [HOLIDAYS, params],
    queryFn: () => getHolidays(params),
    keepPreviousData: true,
    initialData,
  })
}
