import { Pagination } from '@/components/shared/Pagination'
import { CreateChallengeFormModal } from '@/features/challenges/components'
import { useChallenges } from '@/features/challenges/queries'
import { Challenge } from '@/features/challenges/types'
import { useMeta } from '@/features/filters'
import { Meta } from '@/features/filters/types'
import { DashboardHeader, withApp } from '@/features/Layout'
import { LinkFormItem } from '@/features/link_forms/types'
import { t } from '@/lib/helpers'
import { Box, Button, Flex, Spinner, useDisclosure } from '@chakra-ui/react'
import React, { Fragment, useState } from 'react'
import { ChallengeTable } from './ChallengeTable'

type Props = {
  challenges: Challenge[]
  forms: LinkFormItem[]
  meta: Meta
}

function ChallengesPage(props: Props) {
  const { challenges: initialChallenges, forms, meta: initialMeta } = props
  const [loading, setLoading] = useState(false)

  const { onOpen, isOpen, onClose } = useDisclosure()

  const { meta, setMeta } = useMeta(initialMeta)
  const { data: challenges } = useChallenges({
    initialData: { data: initialChallenges, meta: initialMeta },
    params: meta,
  })

  const handleExport = async () => {
    setLoading(true)
    const res = await fetch('/admin/challenges/export')
    const filename =  res.headers.get('Content-Disposition').split(';')[1].split('=')[1].replace(/"/g, '')
    const blob = await res.blob()
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    setLoading(false)
  }

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.challenges.index.title')}
          addons={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {
                loading ? (
                  <Spinner sx={{ mr: '64px' }} />
                ) : (
                  <Button
                    onClick={handleExport}
                    sx={{ h: '48px', w: '148px' }}
                  >
                    {t('views.admin.challenges.export.title')}
                  </Button>
                )
              }
              &nbsp;&nbsp;
              <Button onClick={onOpen} sx={{ h: '48px', w: '148px' }}>
                {t('views.admin.challenges.index.new')}
              </Button>
            </div>
          }
        />

        <Box sx={{ mb: '24px' }}>
          <ChallengeTable data={challenges.data} forms={forms} />
        </Box>

        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta.page}
            per={meta.per}
            total={meta.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>

      <CreateChallengeFormModal
        isOpen={isOpen}
        onClose={onClose}
        forms={forms}
      />
    </Fragment>
  )
}

export default withApp(ChallengesPage)
