import { formatDateTime } from '@/lib/helpers'
import { Holiday, HolidayFormInput } from './types'

export const transformHolidayInput = (
  data: HolidayFormInput,
): HolidayFormInput => {
  const isReverse =
    new Date(data.starts_at).getTime() > new Date(data.ends_at).getTime()

  return {
    ...data,
    starts_at: formatDateTime(
      isReverse ? data.ends_at : data.starts_at,
      'YYYY-MM-DD',
    ),
    ends_at: formatDateTime(
      isReverse ? data.starts_at : data.ends_at,
      'YYYY-MM-DD',
    ),
  }
}

export const transformHolidayIntoForm = (
  data: Holiday,
): Partial<HolidayFormInput> =>
  data
    ? {
        title: data.title,
        city_ids: data.city_ids,
        collection_site_ids: data.collection_site_ids,
        starts_at: formatDateTime(data.starts_at, 'YYYY-MM-DD'),
        ends_at: formatDateTime(data.ends_at, 'YYYY-MM-DD'),
      }
    : {}
