import React, { useMemo } from 'react'
import { Button, Grid, Input, Select } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { formatDateTime, t } from '@/lib/helpers'
import { FormControl } from '@/features/forms/components'

import { VisibleEcosResponse } from '../types'

type Props = {
  defaultValues?: VisibleEcosResponse
  onSubmitForm: (data: VisibleEcosResponse) => void
  isEditMode?: boolean
}
export const VisibleEcosForm = ({
  defaultValues,
  isEditMode,
  onSubmitForm,
}: Props) => {
  const { register, handleSubmit, errors } = useForm<any>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const buttomText = useMemo(() => {
    return !isEditMode
      ? 'views.admin.visible_ecos.index.form.create'
      : 'views.admin.forms.form.update'
  }, [isEditMode, t])

  const levelOptions = new Array(6).fill('').map((_v, i) => {
    return { id: i + 1, name: i + 1 }
  })
  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Grid sx={{ gridTemplateColumns: 'repeat(1, 1fr)', gap: '32px' }}>
        <Grid sx={{ gridTemplateColumns: 'repeat(1, 1fr)', gap: '24px' }}>
          <FormControl
            id="starts_at"
            label={t('views.admin.visible_ecos.index.form.starts_at')}
            isRequired
            errorMsg={errors.starts_at?.message}
          >
            <Input
              type="date"
              name="starts_at"
              ref={register}
              defaultValue={
                defaultValues &&
                formatDateTime(defaultValues.starts_at, 'YYYY-MM-DD')
              }
              size="lg"
            />
          </FormControl>

          <FormControl
            id="ends_at"
            label={t('views.admin.visible_ecos.index.form.ends_at')}
            isRequired
            errorMsg={errors.ends_at?.message}
          >
            <Input
              type="date"
              name="ends_at"
              ref={register}
              defaultValue={
                defaultValues &&
                formatDateTime(defaultValues.ends_at, 'YYYY-MM-DD')
              }
              tabIndex={5}
              size="lg"
            />
          </FormControl>

          <FormControl
            id="hint"
            label={t('views.admin.visible_ecos.index.form.hint')}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.visible_ecos.index.form.hint')}
              ref={register}
              defaultValue={defaultValues?.hint}
              name="hint"
            />
          </FormControl>

          <FormControl
            id="level"
            label={t('views.admin.visible_ecos.index.form.level')}
            isRequired
            errorMsg={errors.level?.message}
          >
            <Select
              name="level"
              size="lg"
              defaultValue={defaultValues?.level}
              placeholder={t('views.admin.visible_ecos.index.form.level')}
              ref={register}
              onChange={(value) => console.log(value.target.value)}
            >
              {levelOptions.map((c) => (
                <option key={c.name} value={c.id}>
                  {c.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl
            id="co2_reduction"
            label={t('views.admin.visible_ecos.index.form.co2_reduction')}
            isRequired
            errorMsg={errors.co2_reduction?.message}
          >
            <Input
              name="co2_reduction"
              size="lg"
              defaultValue={defaultValues?.co2_reduction}
              placeholder={t(
                'views.admin.visible_ecos.index.form.co2_reduction',
              )}
              ref={register}
            />
          </FormControl>

          <FormControl
            id="tree_count"
            label={t('views.admin.visible_ecos.index.form.tree_count')}
            isRequired
            errorMsg={errors.tree_count?.message}
          >
            <Input
              name="tree_count"
              size="lg"
              defaultValue={defaultValues?.tree_count}
              placeholder={t('views.admin.visible_ecos.index.form.tree_count')}
              ref={register}
            />
          </FormControl>

          <FormControl
            id="message"
            label={t('views.admin.visible_ecos.index.form.message')}
          >
            <Input
              size="lg"
              placeholder={t('views.admin.visible_ecos.index.form.message')}
              ref={register}
              defaultValue={defaultValues?.message}
              name="message"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Button type="submit" sx={{ w: '100%', mt: '32px', h: '54px' }}>
        {t(`${buttomText}`)}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  starts_at: yup.date().required(t('views.shared.form.required')),
  ends_at: yup.date().required(t('views.shared.form.required')),
  level: yup.string().required(t('views.shared.form.required')),
  co2_reduction: yup.string().required(t('views.shared.form.required')),
  tree_count: yup.string().required(t('views.shared.form.required')),
})
