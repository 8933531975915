import React from 'react'
import { isEmpty } from 'lodash'
import { Button, Input, Text, VStack } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { t } from '@/lib/helpers'

import { OfficialLinkFormInput } from '../types'
import { FormControl } from '@/features/forms/components'

type Props = {
  defaultValues?: OfficialLinkFormInput
  onSubmitForm: (data: OfficialLinkFormInput) => void
  isLoading: boolean
}

export const OfficialLinkForm = (props: Props) => {
  const { defaultValues = {}, onSubmitForm, isLoading } = props

  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<OfficialLinkFormInput>({
    defaultValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = (data: OfficialLinkFormInput) => {
    if (isSubmitting) return

    onSubmitForm(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        id="official_link"
        sx={{ gridColumn: '1/-1' }}
        errorMsg={errors.official_link?.message}
      >
        <VStack sx={{ mb: '16px' }}>
          <Text sx={{ fontWeight: 500, alignSelf: 'baseline' }}>
            {t('views.admin.cities.index.direct_url')}
          </Text>
          <Input
            size="lg"
            placeholder={t('views.admin.cities.form.url')}
            ref={register}
            name="official_link"
            tabIndex={1}
          />
        </VStack>
        <VStack>
          <Text sx={{ fontWeight: 500, alignSelf: 'baseline' }}>
            {t('views.admin.cities.index.collection_sites_url')}
          </Text>
          <Input
            size="lg"
            placeholder={t('views.admin.cities.form.url')}
            ref={register}
            name="collection_sites_link"
            tabIndex={1}
          />
        </VStack>
      </FormControl>

      <Button
        type="submit"
        sx={{ w: '100%', mt: '32px', h: '54px' }}
        size="lg"
        isLoading={isSubmitting || isLoading}
        tabIndex={2}
      >
        {isEmpty(defaultValues)
          ? t('views.admin.cities.form.add')
          : t('views.admin.cities.form.edit')}
      </Button>
    </form>
  )
}

const schema = yup.object().shape({
  official_link: yup
    .string()
    .url(t('views.admin.shared.form.invalid_url'))
    .nullable(),
})
