import { Tag } from '@/features/tags/types'

export type Campaign = {
  id: number
  title: string
  description: string
  campaign_type: CampaignType
  campaign_code: string
  point: number
  enabled: boolean
  created_at: string
  updated_at: string
  released_at: string
  ended_at: string
  tag: Tag
}

export enum CampaignType {
  first_campaign = 'first_campaign',
  second_campaign = 'second_campaign',
  third_campaign = 'third_campaign',
}

export type CampaignFormInput = {
  title: string
  description?: string
  campaign_type: CampaignType
  campaign_code: string
  point: string
  releasedAt: string
  releasedAtHr: string
  releasedAtMin: string
  endedAt: string
  endedAtHr: string
  endedAtMin: string
  tag_id: number
}
