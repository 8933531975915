import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg, t } from '@/lib/helpers'

import { QR_CATEGORIES, API_PATH } from '../constants'
import { QRCodeCategoryFormInput } from '../types'

const createQRCodeCategory = async (data: QRCodeCategoryFormInput) => {
  await fetchAPI({
    method: 'post',
    path: API_PATH,
    data,
  })
}

export const useCreateQRCodeCategory = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(createQRCodeCategory, {
    onSuccess: async () => {
      await queryClient.refetchQueries([QR_CATEGORIES])

      toast({
        description: t('views.admin.campaigns.services.create_success'),
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
