import { useMutation, useQueryClient } from 'react-query'
import { useToast } from '@chakra-ui/react'

import { fetchAPI } from '@/lib/api'
import { getErrorMsg } from '@/lib/helpers'

import { ARTICLES } from '../constants'
import { ArticleStatus } from '../types'

const updateArticleStatus = async ({
  status,
  id,
}: {
  status: ArticleStatus
  id: number
}) => {
  await fetchAPI({
    method: 'put',
    path: `/articles/${id}`,
    data: {
      status,
    },
  })
}

export const useUpdateArticleStatus = () => {
  const toast = useToast()
  const queryClient = useQueryClient()

  return useMutation(updateArticleStatus, {
    onSuccess: async () => {
      await queryClient.refetchQueries([ARTICLES])

      toast({
        description: 'Article Status was successfully updated.',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      })
    },
    onError(error) {
      toast({
        description: getErrorMsg(error),
        status: 'error',
        position: 'top-right',
        variant: 'left-accent',
        isClosable: true,
      })
    },
  })
}
