import { Modal } from '@/components/shared/Modal'
import { CityCollection, CityCollectionSiteCollection } from '@/features/types'
import { formatDateTime, t } from '@/lib/helpers'
import { UseDisclosureProps } from '@chakra-ui/hooks'
import { Box, Grid, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import React from 'react'
import { Holiday } from '../types'

type Props = {
  holiday: Holiday
  disclosure: UseDisclosureProps
  cities: CityCollection
  sites: CityCollectionSiteCollection
  onEdit: () => void
}

export const HolidayShowModal = (props: Props) => {
  const { disclosure, cities, sites, holiday, onEdit } = props
  const { isOpen, onClose } = disclosure

  const citySite: (CityCollection[number] & {
    sites: CityCollectionSiteCollection
  })[] = React.useMemo(() => {
    if (!holiday) return []

    const selectedCity = cities.filter((c) => holiday.city_ids.includes(c.id))
    const selectedSite = sites.filter((s) =>
      holiday.collection_site_ids.includes(s.id),
    )

    const result = selectedCity.map((c) => ({
      ...c,
      sites: selectedSite.filter((s) => s.city_id === c.id),
    }))

    return result
  }, [holiday])

  if (!holiday) return null
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      withCloseButton
      closeOnOverlayClick
      maxW="480px"
    >
      <Box sx={{ p: '32px', pb: '40px' }}>
        <Text textStyle="modalHeader" mb="20px">
          {t('views.admin.holidays.show.title')}
        </Text>

        <Grid
          sx={{
            gridTemplateColumns: 'max-content 1fr',
            gap: '16px',
            fontSize: '16px',
          }}
        >
          <Text fontWeight={600}>
            {t('views.admin.holidays.index.holiday_title')}
          </Text>
          <Text>{holiday.title}</Text>

          <Text fontWeight={600}>{t('views.admin.holidays.index.city')}</Text>
          <Box sx={{ ul: { ml: '1.5rem' } }}>
            <ul>
              {citySite.map((c) => (
                <li key={c.id}>
                  <Text>{c.name}</Text>
                  <ul>
                    {c.sites.map((s) => (
                      <li key={s.id}>{s.name}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </Box>

          <Text fontWeight={600}>
            {t('views.admin.holidays.index.release_date')}
          </Text>
          <Text>{formatDateTime(holiday.starts_at, 'YYYY-MM-DD')}</Text>

          <Text fontWeight={600}>
            {t('views.admin.holidays.index.release_end_date')}
          </Text>
          <Text>{formatDateTime(holiday.ends_at, 'YYYY-MM-DD')}</Text>

          <Button
            onClick={onEdit}
            sx={{ w: '100%', mt: '32px', h: '54px', gridColumn: '1/-1' }}
          >
            {t('views.admin.holidays.form.edit')}
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}
