import React, { Fragment, useEffect } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/react'

import { DashboardHeader, withApp } from '@/features/Layout'
import { t } from '@/lib/helpers'
import { Meta } from '@/features/filters/types'
import { useMeta } from '@/features/filters'
import { useGetVisibleEcos } from '@/features/visible_ecos/queries/getVisibleEcos'
import { Pagination } from '@/components/shared/Pagination'
import { CreateVisibleEcosModal } from '@/features/visible_ecos/components/CreateVisibleEcosModal'

import { VisibleEcosTable } from './VisibleTable'
import { VisibleEcosResponse } from '@/features/visible_ecos/types'

type Props = {
  visible_ecos: VisibleEcosResponse[]
  meta: Meta
}
const VisibleEcosPage = ({
  visible_ecos: initialData,
  meta: initialMeta,
}: Props) => {
  const disclosure = useDisclosure()
  const { meta, setMeta, setMetas } = useMeta(initialMeta)
  const { data: visibleEcosData } = useGetVisibleEcos({
    initialData: { data: initialData, meta: initialMeta },
    params: meta,
  })

  useEffect(() => {
    setMetas(visibleEcosData.meta)
  }, [visibleEcosData])

  return (
    <Fragment>
      <Box layerStyle="container">
        <DashboardHeader
          title={t('views.admin.visible_ecos.index.title')}
          addons={
            <Button
              onClick={disclosure.onOpen}
              sx={{ h: '48px', minW: '100px' }}
            >
              {t('views.admin.shared.form.create')}
            </Button>
          }
        />
        <Box sx={{ mb: '24px' }}>
          <VisibleEcosTable
            data={visibleEcosData ? visibleEcosData.data : []}
          />
        </Box>
        <Flex layerStyle="content" sx={{ justifyContent: 'flex-end' }}>
          <Pagination
            page={meta?.page}
            per={meta?.per}
            total={meta?.total}
            onChangePage={(page) => {
              setMeta('page', page)
            }}
          />
        </Flex>
      </Box>
      <CreateVisibleEcosModal disclosure={disclosure} />
    </Fragment>
  )
}

export default withApp(VisibleEcosPage)
